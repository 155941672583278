const fetchCommonData = async (store, route) => {
  const commonKeyToUrlsMap = store.getters['common/commonKeyToUrlMap'];
  const commonDataPromises = Object.entries(commonKeyToUrlsMap).reduce((promises, entry) => {
    const [commonKey, commonUrl] = entry || [];
    if (route.path.startsWith(commonUrl)) {
      const commonData = store.getters['common/commonData'] || {};
      const currentCommonData = commonData[commonKey];
      const arrayCommonDataIsEmpty = Array.isArray(currentCommonData) && currentCommonData.length < 1;
      const objectCommonDataIsEmpty = !Array.isArray(currentCommonData) && !currentCommonData?.published_at;
      if (arrayCommonDataIsEmpty || objectCommonDataIsEmpty) {
        return [...promises, store.dispatch('common/fetchCommonData', commonKey)];
      }
    }
    return promises;
  }, []);

  try {
    await Promise.all(commonDataPromises);
  } catch (error) {
    if (error?.response?.status === 404) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default function ({ store, route }) {
  return fetchCommonData(store, route);
}
