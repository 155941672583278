import PLAN from '~/assets/constants/PLAN.js';

export const state = () => ({
  token: null,
  version: null,
  commitHash: null,
  refresh: false,
  reauth: false,
  user: {},
  userFlags: {},
  locale: 'en-US',
  acceptCookies: false,
  appName: null,
  userSubscription: null,
  isPaidSubscriber: false,
  hasSkippedSurveyModal: false,
});

export const mutations = {
  setToken (state, token) {
    state.token = token;
    this.$cookies.set('post_token', state.token);
  },
  unSetToken (state) {
    state.token = null;
    this.$cookies.remove('post_token');
  },
  setVersion (state, version) {
    state.version = version;
  },
  unSetVersion (state) {
    state.version = null;
  },
  setCommitHash (state, commitHash) {
    state.commitHash = commitHash;
    this.$cookies.set('commitHash', state.commitHash);
  },
  unSetCommitHash (state) {
    state.commitHash = null;
  },
  setAppName (state) {
    state.appName = 'Post';
    this.$cookies.set('post_appName', state.appName);
  },
  unSetAppName (state) {
    state.appName = null;
    this.$cookies.remove('post_appName');
  },
  setUser (state, profile) {
    delete profile.featureFlags;
    state.user = profile;
  },
  setUserSubscription (state, subscription) {
    state.userSubscription = subscription;
    const { planCode } = subscription || {};
    state.isPaidSubscriber = Boolean(planCode) && planCode !== PLAN.BASIC.code;
  },
  updateUser (state, profile) {
    Object.keys(profile).forEach((val) => {
      state.user[val] = profile[val];
    });
  },
  resetUser (state) {
    state.user = {};
  },
  setUserFlags (state, flags) {
    state.userFlags = flags;
  },
  updateUserFlags (state, flags) {
    Object.keys(flags).forEach((val) => {
      state.userFlags[val] = flags[val];
    });
  },
  resetUserFlags (state) {
    state.userFlags = {};
  },
  setLocale (state, locale) {
    state.locale = locale;
    this.$cookies.set('locale', state.locale);
  },
  unSetLocale (state) {
    state.locale = null;
    this.$cookies.remove('locale');
  },
  setAcceptCookies (state, status) {
    state.acceptCookies = status;
    this.$cookies.set('acceptCookies', state.acceptCookies);
  },
  unSetAcceptCookies (state) {
    state.acceptCookies = false;
    this.$cookies.remove('acceptCookies');
  },
  resetAuth (state) {
    state.token = null;
    state.user = {};
    state.userFlags = {};
    state.locale = null;
    state.acceptCookies = false;
    state.appName = null;
    state.version = null;
    state.reauth = false;
    this.$cookies.remove('post_token');
    this.$cookies.remove('locale');
    this.$cookies.remove('acceptCookies');
    this.$cookies.remove('post_appName');
    this.$cookies.remove('version');
    this.$cookies.remove('hasSkippedSurveyModal');
  },
  setReauth (state, status) {
    state.reauth = status;
  },
  setRefresh (state, refresh) {
    state.refresh = refresh;
  },
  setHasSkippedSurveyModal (state, status) {
    state.hasSkippedSurveyModal = status;
    this.$cookies.set('hasSkippedSurveyModal', state.hasSkippedSurveyModal, {
      maxAge: 60 * 60 * 24 * 30
    });
  }
};

export const getters = {
  authToken: (state) => {
    return state.token;
  },
  isAuthenticated: (state) => {
    return !!state.token;
  },
  appVersion: (state) => {
    return state.version;
  },
  loggedInUser: (state) => {
    return state.user;
  },
  isPaidSubscriber: (state) => {
    return state.isPaidSubscriber;
  },
  userSubscription: (state) => {
    return state.userSubscription;
  },
  getUserFlags: (state) => {
    return state.userFlags;
  },
  refresh: (state) => {
    return state.refresh;
  },
  hasSkippedSurveyModal: (state) => {
    return state.hasSkippedSurveyModal;
  }
};

export const actions = {
  setFeatureFlagsState ({ commit }, data) {
    commit('setFeatureFlagsState', data);
  },
  resetFeatureFlagsState ({ commit }) {
    commit('resetFeatureFlagsState');
  },
  setUserSubscription ({ commit }, data) {
    commit('setUserSubscription', data);
  },

  /**
   * Set User details
   * @param commit
   * @param data
   */
  setUser ({ commit, dispatch }, data) {
    commit('setUser', data);
    const accountPreferences = data?.customerProfile?.accountPreferences;
    if (accountPreferences) {
      dispatch('updateAccountPreferences', accountPreferences);
    }
  },

  /**
   * Set User flags
   * @param commit
   * @param data
   */
  setUserFlags ({ commit }, data) {
    commit('setFlags', data);
  },

  /**
   * Fetch User details
   * @param commit
   * @param dispatch
   * @param state
   * @returns {*}
   */
  fetchUserProfile ({ commit, dispatch, state }) {
    const headers = {};

    return this.$api.iam.accounts
      .me({ headers })
      .then((response) => {
        const { account } = response?.data?.payload || {};
        commit('setUser', account);
        const { featureFlags, accountPreferences, subscriptionInfo } = account?.customerProfile || {};
        if (featureFlags) {
          commit('setUserFlags', featureFlags);
        }
        if (accountPreferences) {
          dispatch('updateAccountPreferences', accountPreferences);
        }
        if (subscriptionInfo) {
          commit('setUserSubscription', subscriptionInfo);
        }
        return response;
      })
      .catch((error) => {
        commit('resetUser');
        return Promise.reject(error.response);
      });
  },

  /**
   * Set token and Project Configs
   * @param commit
   * @param token
   */
  setAppConfigs ({ commit }, token) {
    commit('setToken', token);
    commit('setReauth', false);
    commit('setVersion', this.$config.DeploymentVersion);
    commit('setAppName', 'Post');
  },

  /**
   * Prepare app after user login
   * @param commit
   * @param token
   */
  afterLoginSuccess ({ commit }, token) {
    commit('resetUser');
    this.dispatch('auth/setAppConfigs', token);
    this.dispatch('cart/clearCart');
  },

  /**
   * Log in user
   * @param commit
   * @param data
   * @returns {*}
   */
  login ({ commit }, data) {
    return this.$api.iam.accounts
      .login({ data })
      .then((response) => {
        this.dispatch('auth/afterLoginSuccess', response.data.payload.sessionToken);
        return response;
      })
      .catch((error) => {
        if (data && data.elevated_session) {
          return Promise.reject(error.response);
        }
        commit('unSetToken');
        commit('resetUser');
        return Promise.reject(error.response);
      });
  },

  /**
   * Register User
   * @param commit
   * @param data
   * @returns {*}
   */
  signup ({ commit }, data) {
    return this.$api.iam.accounts
      .signup({ data })
      .then((response) => {
        this.dispatch('auth/setAppConfigs', response.data.payload.sessionToken);
        return response;
      })
      .catch((error) => {
        commit('unSetToken');
        commit('resetUser');
        return Promise.reject(error.response);
      });
  },

  /**
   * Log out user
   * @param commit
   * @returns {Promise<void>}
   */
  logout ({ commit }) {
    commit('unSetToken');
    commit('resetUser');
    commit('unSetLocale');
    commit('unSetAcceptCookies');
    commit('unSetAppName');
    commit('unSetVersion');
    commit('setReauth', false);
    this.dispatch('cart/clearCart');
    return Promise.resolve();
  },
  /**
   * Perform google login
   * @param commit
   * @param data
   * @returns {*}
   */
  loginWithGoogle ({ commit }, data) {
    return this.$api.iam.accounts
      .socialLoginGoogle(data)
      .then((response) => {
        this.dispatch('auth/afterLoginSuccess', response.data.payload.sessionToken);
        return response;
      })
      .catch((error) => {
        if (data && data.elevated_session) {
          return Promise.reject(error.response);
        }
        commit('unSetToken');
        commit('resetUser');
        return Promise.reject(error.response);
      });
  },

  /**
   * Perform google signup
   * @param commit
   * @param data
   * @returns {*}
   */
  signupWithGoogle ({ commit }, data) {
    return this.$api.iam.accounts
      .socialSignupGoogle(data)
      .then((response) => {
        this.dispatch('auth/setAppConfigs', response.data.payload.sessionToken);
        return response;
      })
      .catch((error) => {
        commit('unSetToken');
        commit('resetUser');
        return Promise.reject(error.response);
      });
  },

  /**
   * Update user account preferences
   * @param commit
   * @param accountPreferences
   */
  updateAccountPreferences ({ commit }, accountPreferences) {
    if (accountPreferences?.currency) {
      this.commit('page/setPreferredCurrency', accountPreferences?.currency);
    }

    if (accountPreferences?.preferredTimezone) {
      this.commit('page/setPreferredTimezone', accountPreferences?.preferredTimezone);
    }

    if (accountPreferences?.betaTester) {
      this.commit('page/setAllowBetaFeatures', accountPreferences?.betaTester);
    }
  },

  /**
   * Simulate account impersonation
   * @param commit
   * @param token
   */
  impersonate ({ commit }, token) {
    this.dispatch('auth/afterLoginSuccess', token);
  },
  /**
   * Save commit hash
   * @param commit
   * @param commitHash
   */
  setCommitHash ({ commit }, commitHash) {
    commit('setCommitHash', commitHash);
  },

  /**
   * Validate commit hash with env
   * @param commit
   * @param hash
   */
  validateAppVersion ({ commit }, hash) {
    if (hash && this.$config.BuildHash !== hash) {
      commit('setRefresh', true);
    } else {
      this.dispatch('auth/setCommitHash', this.$config.BuildHash);
    }
  },

  setHasSkippedSurveyModal ({ commit }, status) {
    commit('setHasSkippedSurveyModal', status);
  }
};
