import { customAlphabet } from 'nanoid';

export const generatePaymentReferencePart = () => {
  const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const referenceCharLimit = 16;
  // customAlphabet returns a function that needs to be called to generate the reference
  return customAlphabet(possibleChars, referenceCharLimit)();
};

const payStackMixin = {
  methods: {
    generatePaymentReferencePart () {
      return generatePaymentReferencePart();
    },
    generateWalletReference () {
      return `WLT_${generatePaymentReferencePart()}`;
    },
  },
};

export default payStackMixin;
