const DELIVERY_CHOICE = {
  HOME: {
    name: 'Home Delivery',
    value: 'HOME',
    subtypes: {
      HOME_LAGOS: {
        name: 'Lagos Home Delivery',
        value: 'HOME_LAGOS',
      },
      HOME_NON_LAGOS: {
        name: 'Outside Lagos Home Delivery',
        value: 'HOME_NON_LAGOS',
      },
    },
  },
  PICKUP: {
    name: 'Pick Up',
    value: 'PICKUP',
  },
  GROUP_ORDER: {
    name: 'Group Order',
    value: 'GROUP_ORDER',
  },
};

export default DELIVERY_CHOICE;
