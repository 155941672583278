import KYC_STATUS from '~/assets/constants/KYC_STATUS.js';

const onboardingMixin = {
  data () {
    return {
      totalOnboardingTasks: 3,
    };
  },
  computed: {
    hasVerifiedEmail () {
      return Boolean(this.loggedInUser?.customerProfile?.emailVerified);
    },
    hasCompleteProfile () {
      const { firstName, lastName, sex, dob, phone } = this.loggedInUser;
      return Boolean(firstName && lastName && sex && dob && phone);
    },
    hasCompletedKyc () {
      return this.loggedInUser?.customerProfile?.identityVerificationStatus === KYC_STATUS.APPROVED;
    },
    sumOfCriteriaForCompletedOnboarding () {
      return 1 + Number(this.hasVerifiedEmail) + Number(this.hasCompleteProfile);
    },
    onboardingIsIncomplete () {
      return this.sumOfCriteriaForCompletedOnboarding < this.totalOnboardingTasks;
    },
    profileProgressPercent () {
      return Math.ceil((this.sumOfCriteriaForCompletedOnboarding / this.totalOnboardingTasks) * 100);
    },
  },
};

export default onboardingMixin;
