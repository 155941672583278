const getServiceBasePath = version => `/shipping/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  shipments: {
    list: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/shipments`;
      return $axios.get(urlPath, { headers, params });
    },
    get: ({ idOrTrackingNumber, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/shipments/${idOrTrackingNumber}`;
      return $axios.get(urlPath, { headers });
    },
    changeDeliveryChoice: ({ data, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/shipments/delivery-choice`;
      return $axios.put(urlPath, data, { headers });
    },
    listShipmentStatuses: () => $axios.get(`${getServiceBasePath($apiVersion)}/shipments/status`),
    generateFlagMediaUrl: ({ idOrTrackingNumber, headers, params }) => {
      return $axios.get(`${getServiceBasePath($apiVersion)}/shipments/${idOrTrackingNumber}/flags/url`, {
        headers,
        params,
      });
    },
    uploadFileToFlag: ({ idOrTrackingNumber, data, headers }) => {
      return $axios.put(`${getServiceBasePath($apiVersion)}/shipments/${idOrTrackingNumber}/flags/upload`, data, {
        headers,
      });
    },
    takeActionOnFlag: ({ idOrTrackingNumber, data, headers }) => {
      return $axios.put(`${getServiceBasePath($apiVersion)}/shipments/${idOrTrackingNumber}/flags/action`, data, {
        headers,
      });
    },
    quoteFlagResolutionFee: ({ flagAction, sizeClass }) => {
      return $axios.get(`${getServiceBasePath($apiVersion)}/shipments/flags/${flagAction}/${sizeClass}/resolution-fee`);
    },
    listPickupReady: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/shipments/pickup-ready`;
      return $axios.get(urlPath, { headers, params });
    },
    trackExpectedShipment: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/shipments/expect`;
      return $axios.post(urlPath, data, { headers });
    },
    delete: ({ data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/shipments`;
      return $axios.delete(urlPath, { data });
    },
    warehouses: () => {
      return $axios.get(`${getServiceBasePath($apiVersion)}/shipments/warehouses`);
    },
  },
  manifest: {
    getPalletLocation: ({ idOrBarcode, houseAirWaybill, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/manifests/${idOrBarcode}/pallets/${houseAirWaybill}/location`;
      return $axios.get(urlPath, { headers });
    },
  },
  customShippingLabel: {
    get: ({ headers } = {}) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/label-header/mine`;
      return $axios.get(urlPath, { headers });
    },
    update: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/label-header`;
      return $axios.put(urlPath, data, { headers });
    },
  },
});
