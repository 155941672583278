import CURRENCY from '~/assets/constants/CURRENCY.js';

export const state = () => ({
  pageTitle: '',
  showPageSidebar: false,
  preferredCurrency: CURRENCY.USD.value,
  preferredTimezone: 'Africa/Lagos',
  allowBetaFeatures: false,
  darkMode: false,
  currentTheme: 'system',
});

export const mutations = {
  setPageTitle (state, pageTitle) {
    state.pageTitle = pageTitle;
  },
  setShowPageSidebar (state, showPageSidebar) {
    state.showPageSidebar = showPageSidebar;
  },
  setPreferredCurrency (state, currency) {
    state.preferredCurrency = currency;
    this.$cookies.set('preferredCurrency', state.preferredCurrency);
  },
  setPreferredTimezone (state, timezone) {
    state.preferredTimezone = timezone;
    this.$cookies.set('preferredTimezone', state.preferredTimezone);
  },
  setAllowBetaFeatures (state, trueOrFalse) {
    state.allowBetaFeatures = trueOrFalse;
    this.$cookies.set('allowBetaFeatures', state.allowBetaFeatures);
  },
  setDarkMode (state, trueOrFalse) {
    state.darkMode = trueOrFalse;
    this.$cookies.set('darkMode', state.darkMode);
  },
  setCurrentTheme (state, selectedTheme) {
    state.currentTheme = selectedTheme;
    this.$cookies.set('currentTheme', state.currentTheme);
  },
};

export const actions = {
  setPageTitleAction ({ commit }, pageTitle) {
    if (typeof pageTitle === 'string') {
      commit('setPageTitle', pageTitle);
    }
  },
  openPageSidebar ({ commit }) {
    commit('setShowPageSidebar', true);
  },
  closePageSidebar ({ commit }) {
    commit('setShowPageSidebar', false);
  },
  togglePageSidebar ({ commit, rootState }) {
    const { page } = rootState;
    const { showPageSidebar } = page;
    commit('setShowPageSidebar', !showPageSidebar);
  },
  setPreferredCurrency ({ commit }, currency) {
    if (typeof currency === 'string') {
      commit('setPreferredCurrency', currency || CURRENCY.DEFAULT.value);
    }
  },
  setPreferredTimezone ({ commit }, timezone) {
    if (typeof timezone === 'string') {
      commit('setPreferredTimezone', timezone);
    }
  },
  setAllowBetaFeatures ({ commit }, trueOrFalse) {
    commit('setAllowBetaFeatures', trueOrFalse);
  },
  setDarkMode ({ commit }, trueOrFalse) {
    commit('setDarkMode', trueOrFalse);
  },
  setCurrentTheme ({ commit }, selectedTheme) {
    commit('setCurrentTheme', selectedTheme);
  },
};

export const getters = {
  pageTitle: (state) => {
    return state.pageTitle;
  },
  showPageSidebar: (state) => {
    return state.showPageSidebar;
  },
  preferredCurrency: (state) => {
    return state.preferredCurrency;
  },
  preferredTimezone: (state) => {
    return state.preferredTimezone;
  },
  allowBetaFeatures: (state) => {
    return Boolean(JSON.parse(state.allowBetaFeatures));
  },
  darkMode: (state) => {
    return Boolean(JSON.parse(state.darkMode));
  },
  currentTheme: (state) => {
    return state.currentTheme;
  },
};
