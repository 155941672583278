import { mapGetters, mapActions } from 'vuex';
import CURRENCY from '~/assets/constants/CURRENCY.js';

const cartMixin = {
  data () {
    return {
      removeLimit: -1,
      defaultLimit: 3,
      limit: 3,
    };
  },
  computed: {
    ...mapGetters({
      cartOrder: 'cart/cartOrder',
      cartShipments: 'cart/cartShipments',
    }),
    totalCartWeight () {
      return this.cartShipments.reduce((acc, shipment) => {
        return acc + shipment.weight;
      }, 0);
    },
    shippingFeeAmountInDollarWithSymbol () {
      const { shippingFeeAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: shippingFeeAmount,
        currency: CURRENCY.USD.value,
      });
    },
    storageFeeAmountInDollarWithSymbol () {
      const { storageFeeAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: storageFeeAmount,
        currency: CURRENCY.USD.value,
      });
    },
    storageFeeAmountInDollar () {
      const { storageFeeAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: storageFeeAmount,
        currency: CURRENCY.USD.value,
        withSymbol: false,
      });
    },
    showStorageFeeAmount () {
      return this.getCharge(this.storageFeeAmountInDollar) > 0;
    },
    savingAmountWithSymbol () {
      const { savings } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: savings,
        currency: CURRENCY.USD.value,
      });
    },
    savingAmount () {
      const { savings } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: savings,
        currency: CURRENCY.USD.value,
        withSymbol: false,
      });
    },
    deliveryFeeAmountInDollarWithSymbol () {
      const { deliveryFeeAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: deliveryFeeAmount,
        currency: CURRENCY.USD.value,
      });
    },
    deliveryFeeAmountInDollar () {
      const { deliveryFeeAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: deliveryFeeAmount,
        currency: CURRENCY.USD.value,
        withSymbol: false,
      });
    },
    shippingFeeAirAmountInDollar () {
      const { shippingFeeAirAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: shippingFeeAirAmount,
        currency: CURRENCY.USD.value,
        withSymbol: false,
      });
    },
    shippingFeeOceanAmountInDollar () {
      const { shippingFeeOceanAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: shippingFeeOceanAmount,
        currency: CURRENCY.USD.value,
        withSymbol: false,
      });
    },
    shippingFeeAirAmountInDollarWithSymbol () {
      const { shippingFeeAirAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: shippingFeeAirAmount,
        currency: CURRENCY.USD.value,
      });
    },
    shippingFeeOceanAmountInDollarWithSymbol () {
      const { shippingFeeOceanAmount } = this.cartOrder;
      return this.getAmountInCurrency({
        amountObj: shippingFeeOceanAmount,
        currency: CURRENCY.USD.value,
      });
    },
    cartOrderTotalAmountObj () {
      const { shippingFeeAmount, deliveryFeeAmount, storageFeeAmount } = this.cartOrder;
      const { insuranceCost } = this.insurance;

      const shippingFeeChargeTotal = shippingFeeAmount?.charge || 0;
      const deliveryFeeChargeTotal = deliveryFeeAmount?.charge || 0;
      const storageFeeAmountTotal = storageFeeAmount?.charge || 0;
      const insuranceCostTotal = insuranceCost?.charge || 0;

      let chargeUSD =
        shippingFeeChargeTotal + deliveryFeeChargeTotal + storageFeeAmountTotal;
      chargeUSD += insuranceCostTotal;

      return this.createAmountObj(chargeUSD);
    },
    totalAmountInDollar () {
      return this.getAmountInCurrency({
        amountObj: this.cartOrderTotalAmountObj,
        currency: CURRENCY.USD.value,
        withSymbol: false,
      });
    },
  },
  methods: {
    ...mapActions({
      setCart: 'cart/setCart',
      clearCart: 'cart/clearCart',
      setCartOrder: 'cart/setCartOrder',
      setActiveOrder: 'order/setActiveOrder',
    }),
    resetCartOrder () {
      this.setCartOrder();
    },
    changeShipmentLimit (value) {
      this.limit = value;
    },
    limitCartShipments (isGroupOrder) {
      if (isGroupOrder || this.$route.path.includes('/shipments/checkout/pro')) {
        return this.cartShipments;
      }
      const items = [...this.cartShipments];
      if (this.limit > this.removeLimit) {
        return items.slice(0, this.limit);
      }
      return items;
    },
    isAlreadyInCart (shipment) {
      return this.cartShipments.some(s => s.id === shipment.id);
    },
    addShipmentsToCart (shipments = []) {
      this.$eventBus.$emit('add-shipments-to-cart', shipments);
    },
    removeShipmentsFromCart (shipments = []) {
      this.$eventBus.$emit('remove-shipments-from-cart', shipments);
    },
  },
};

export default cartMixin;
