const CART_STORAGE_KEY = 'cart';
const CART_SAVED_AT_STORAGE_KEY = 'cart_saved_at';

const persistCartShipmentsInStorage = (state) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state.cartShipments));
  window.localStorage.setItem(CART_SAVED_AT_STORAGE_KEY, new Date().toISOString());
};

const getCartShipmentsInStorage = () => {
  if (typeof window === 'undefined') {
    return;
  }
  if (window.localStorage.getItem(CART_STORAGE_KEY)) {
    const cartShipments = JSON.parse(window.localStorage.getItem(CART_STORAGE_KEY));
    return cartShipments;
  }
  return [];
};

const getCartSavedAt = () => {
  if (typeof window === 'undefined') {
    return;
  }
  if (window.localStorage.getItem(CART_SAVED_AT_STORAGE_KEY)) {
    const cartSavedAt = window.localStorage.getItem(CART_SAVED_AT_STORAGE_KEY);
    return cartSavedAt;
  }
  return null;
};

const desistCartShipmentsInStorage = () => {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.removeItem(CART_STORAGE_KEY);
  window.localStorage.removeItem(CART_SAVED_AT_STORAGE_KEY);
};

export const state = () => ({
  cartOrder: {},
  cartShipments: [],
});

export const mutations = {
  setCartOrder (state, value) {
    state.cartOrder = value || {};
  },
  setCartShipments (state, value) {
    state.cartShipments = value || [];
    persistCartShipmentsInStorage(state);
  },
  // set cart order and shipments based on data from API
  setCart (state, data) {
    if (data?.payload?.order) {
      state.cartOrder = data.payload.order;
    }
    if (data?.payload?.shipments?.shipments) {
      const sortCartShipments = (a, b) => a.id.localeCompare(b.id);
      const shipments = data.payload.shipments?.shipments || [];
      state.cartShipments = shipments.sort(sortCartShipments);
      persistCartShipmentsInStorage(state);
    }
  },
};

export const getters = {
  cartOrder: (state) => {
    return state.cartOrder;
  },
  cartShipments: (state) => {
    return state.cartShipments;
  },
};

export const actions = {
  addShipmentsToCart ({ commit, state }, shipments = []) {
    const distinctShipments = [...new Set([...state.cartShipments, ...shipments])];
    commit('setCartShipments', distinctShipments);
    shipments.forEach(async (shipment) => {
      await this.$segment.tracks.addShipmentToCart({
        status: shipment.status,
        weight: shipment.weight,
        item_description: shipment.infoRaw,
      });
    });
    this.dispatch('toast/addToastNotification', {
      message: 'Added to cart',
      context: 'SHIPMENT',
      content: shipments,
    });
  },
  removeShipmentsFromCart ({ commit, state }, shipments = []) {
    const filteredShipments = state.cartShipments.filter((s) => {
      return !shipments.some(shipment => shipment.id === s.id);
    });
    commit('setCartShipments', filteredShipments);
    shipments.forEach(async (shipment) => {
      await this.$segment.tracks.removeShipmentFromCart({
        weight: shipment.weight,
        item_description: shipment.infoRaw,
      });
    });
    this.dispatch('toast/addToastNotification', {
      message: 'Removed from cart',
      context: 'SHIPMENT',
      content: shipments,
    });
  },
  setCart ({ commit }, data) {
    commit('setCart', data);
  },
  clearCart ({ commit }) {
    commit('setCartShipments');
    desistCartShipmentsInStorage();
  },
  restoreCartFromStorage () {
    const cartSavedAt = getCartSavedAt();
    if (cartSavedAt) {
      // if cart is saved more than 2 days ago, clear cart
      const cartSavedAtDate = new Date(cartSavedAt);
      const now = new Date();
      const diffInDays = Math.floor((now - cartSavedAtDate) / (1000 * 60 * 60 * 24));
      if (diffInDays > 2) {
        this.dispatch('cart/clearCart');
        return;
      }
    }
    const cartShipments = getCartShipmentsInStorage();
    if (cartShipments.length > 0) {
      this.commit('cart/setCartShipments', cartShipments);
    }
  },
  setCartOrder ({ commit }, data) {
    commit('setCartOrder', data);
  },
};
