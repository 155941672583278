import { mapGetters, mapActions } from 'vuex';

const paymentMixin = {
  data () {
    return {
      idOrReference: '',
      fetchingPayment: false,
      payment: {},
      paymentProcessorIcons: {
        STRIPE: '/svg/payments/icon-debit-card.svg',
        PAYSTACK: '/svg/payments/icon-debit-card.svg',
        VOUCHER: '/svg/payments/icon-discount-voucher.svg',
        WALLET: '/svg/payments/icon-wallet.svg',
        PAYPAL: '/svg/payments/icon-paypal.png',
      },
    };
  },
  computed: {
    ...mapGetters({
      activePayment: 'payment/activePayment',
    }),
  },
  created () {
    if (this.activePayment) {
      this.payment = this.activePayment;
    }
  },
  watch: {
    activePayment () {
      if (this.activePayment) {
        this.payment = this.activePayment;
      }
    },
  },
  methods: {
    ...mapActions({
      setActivePayment: 'payment/setActivePayment',
    }),
    async fetchPayment (idOrReference) {
      if (!this.activePayment) {
        this.fetchingPayment = true;
      }
      try {
        const { data } = await this.$api.payment.payments.get({ idOrReference });
        this.payment = data?.payload?.payment || {};
      } catch (error) {
        this.handleServerError(error);
        this.$router.push('/payments');
      } finally {
        this.fetchingPayment = false;
      }
    },
  },
};

export default paymentMixin;
