const defaultTraits = {
  email: '',
  firstName: '',
  lastName: '',
  country: '',
  phone: '',
  birthday: '',
  gender: '',
  userId: '',
};
export default () => ({
  pages: {
    signIn: category => window?.analytics?.page('Sign In', { category }),
    signUp: category => window?.analytics?.page('Sign Up', { category }),
    forgotPassword: category => window?.analytics?.page('Forgot Password', { category }),
    startHere: category => window?.analytics?.page('Start Here', { category }),
    faqs: category => window?.analytics?.page('Frequently Asked Questions', { category }),
    track: category => window?.analytics?.page('Track', { category }),
    usAddress: category => window?.analytics?.page('Your US Address', { category }),
    shippingCalculator: category => window?.analytics?.page('Shipping Calculator', { category }),
    indexFundWallet: category => window?.analytics?.page('Fund Wallet', { category }),
    userWallet: ({ category, currency }) => window?.analytics?.page('Your Wallet', { category, currency }),
    fundWallet: category => window?.analytics?.page('Fund Wallet', { category }),
    shipmentOverview: category => window?.analytics?.page('Shipments Overview', { category }),
    shipmentDetails: params => window?.analytics?.page('Shipments Details', { ...params }),
    checkout: category => window?.analytics?.page('Checkout', { category }),
    homeDelivery: category => window?.analytics?.page('Home Delivery', { category }),
    newDeliveryAddress: category => window?.analytics?.page('Add New Address', { category }),
    pickupAddress: category => window?.analytics?.page('Pickup Address', { category }),
    buyForMe: category => window?.analytics?.page('Buy For Me', { category }),
    ordersOverview: category => window?.analytics?.page('Orders Overview', { category }),
    orderDetails: params => window?.analytics?.page('Order Details', { ...params }),
    splitPayContributors: category => window?.analytics?.page('Split Pay Contributors', { category }),
    newSplitPayContributors: category => window?.analytics?.page('Add New Contributor', { category }),
    paymentsOverview: category => window?.analytics?.page('Payments Overview', { category }),
    paymentDetails: category => window?.analytics?.page('Payment Details', { category }),
    scheduledPickups: category => window?.analytics?.page('Scheduled Pickups', { category }),
    pickupHistory: category => window?.analytics?.page('Pickup History', { category }),
    referrals: category => window?.analytics?.page('Referrals', { category }),
    voucherHistory: category => window?.analytics?.page('Voucher History', { category }),
    addressBooks: category => window?.analytics?.page('Address Book Overview', { category }),
    newAddress: category => window?.analytics?.page('Add New Address', { category }),
    editAddress: category => window?.analytics?.page('Edit Address', { category }),
    branding: category => window?.analytics?.page('Branding', { category }),
    personalInfo: category => window?.analytics?.page('Personal Info', { category }),
    manageSubscription: category => window?.analytics?.page('Manage Subscription', { category }),
    plans: category => window?.analytics?.page('Plans & Pricing', { category }),
    cart: category => window?.analytics?.page('Cart', { category }),
    whatsNew: category => window?.analytics?.page("What's New?", { category }),
  },
  identify: {
    signedIn: ({ id, traits = { ...defaultTraits } }) => {
      window?.analytics?.identify(id, traits);
    },
    signedUp: ({ id, traits = { ...defaultTraits } }) => {
      window?.analytics?.identify(id, traits);
    },
    profileUpdated: ({ id, traits = { ...defaultTraits } }) => {
      window?.analytics?.identify(id, traits);
    },
    planUpgraded: ({ id, traits = { ...defaultTraits } }) => {
      window?.analytics?.identify(id, traits);
    },
    planUpgradedAfterSignup: ({ id, traits = { ...defaultTraits } }) => {
      window?.analytics?.identify(id, traits);
    },
    planDowngraded: ({ id, traits = { ...defaultTraits } }) => {
      window?.analytics?.identify(id, traits);
    },
  },
  tracks: {
    signedIn: (props = {}) => {
      window?.analytics?.track('Signed In', props);
    },
    signedUp: (props = {}) => {
      window?.analytics?.track('Signed Up', props);
    },
    signedOut: (props = {}) => {
      window?.analytics?.track('Signed Out', props);
    },
    inviteSent: (props = {}) => {
      window?.analytics?.track('Invite Sent', props);
    },
    resendingVerificationLink: (props = {}) => {
      window?.analytics?.track('Verification Link Resent', props);
    },
    shipmentTracked: (props = {}) => {
      window?.analytics?.track('Shipment Tracked', props);
    },
    shipmentMissingNotificationCreated: (props = {}) => {
      window?.analytics?.track('Missing Shipment Notification Created', props);
    },
    shipmentMissingNotificationIgnored: (props = {}) => {
      window?.analytics?.track('Missing Shipment Notification Ignored', props);
    },
    addressInfoCopied: (props = {}) => {
      window?.analytics?.track('Address Info Copied', props);
    },
    shipmentCalculated: (props = {}) => {
      window?.analytics?.track('Shipment Calculated', props);
    },
    addShipmentToCart: (props = {}) => {
      window?.analytics?.track('Shipment Added To Cart', props);
    },
    removeShipmentFromCart: (props = {}) => {
      window?.analytics?.track('Shipment Removed From Cart', props);
    },
    clearCart: (props = {}) => {
      window?.analytics?.track('Cart Cleared', props);
    },
    copyShipmentTrackingNo: (props = {}) => {
      window?.analytics?.track('Shipment Tracking Number Copied', props);
    },
    walletFunded: (props = {}) => {
      window?.analytics?.track('Wallet Funded', props);
    },
    walletBalanceViewed: (props = {}) => {
      window?.analytics?.track('Balance Viewed', props);
    },
    walletBalanceHidden: (props = {}) => {
      window?.analytics?.track('Balance Hidden', props);
    },
    startShipmentCheckout: (props = {}) => {
      window?.analytics?.track('Shipment Checkout Started', props);
    },
    selectShipmentDeliveryChoice: (props = {}) => {
      window?.analytics?.track('Shipment Delivery Method Chosen', props);
    },
    buyForMeStarted: (props = {}) => {
      window?.analytics?.track('Buy For Me Started', props);
    },
    buyForMeCompleted: (props = {}) => {
      window?.analytics?.track('Buy For Me Completed', props);
    },
    startShipmentPayment: (props = {}) => {
      window?.analytics?.track('Shipment Payment Started', props);
    },
    shipmentOrderPaid: (props = {}) => {
      window?.analytics?.track('Shipment Order Paid', props);
    },
    shipmentOrderStorageFees: (props = {}) => {
      window?.analytics?.track('Storage Fee For Shipment Order Paid', props);
    },
    pickupStorageFees: (props = {}) => {
      window?.analytics?.track('Storage Fee At Pickup', props);
    },
    cancelShipmentOrder: (props = {}) => {
      window?.analytics?.track('Shipment Order Canceled', props);
    },
    generateSplitPaymentLink: (props = {}) => {
      window?.analytics?.track('Split Payment Link Generated', props);
    },
    updateUserProfile: (props = {}) => {
      window?.analytics?.track('User Profile Updated', props);
    },
    upgradePlan: (props = {}) => {
      window?.analytics?.track('Plan Upgraded', props);
    },
    upgradePlanAfterSignup: (props = {}) => {
      window?.analytics?.track('Plan Upgraded After Signup', props);
    },
    downgradePlan: (props = {}) => {
      window?.analytics?.track('Plan Downgraded', props);
    },
    newAddressAdded: (props = {}) => {
      window?.analytics?.track('New Address Added', props);
    },
    addressUpdated: (props = {}) => {
      window?.analytics?.track('Address Updated', props);
    },
    addressDeleted: (props = {}) => {
      window?.analytics?.track('Address Deleted', props);
    },
    kycVerificationStarted: (props = {}) => {
      window?.analytics?.track('KYC Verification Started', props);
    },
    kycVerificationSubmitted: (props = {}) => {
      window?.analytics?.track('KYC Verification Submitted', props);
    },
  },
});
