export const surveyJan2023Object = {
  'iam.customerProfile.accountClassification': {
    question: 'How would you describe your shipping needs?',
    answerKey: 'account_classification',
    namespace: 'iam.customerProfile.accountClassification',
    options: [
      {
        label: 'Business',
        value: 'BUSINESS',
      },
      {
        label: 'Personal',
        value: 'PERSONAL',
      },
    ],
  },
  'iam.customerProfile.extraOriginCountry': {
    question: 'Which other country would you like to ship from? ',
    answerKey: 'origin_country',
    namespace: 'iam.customerProfile.extraOriginCountry',
    options: [
      {
        label: 'Turkey',
        value: 'TR',
      },
      {
        label: 'China',
        value: 'CN',
      },
      {
        label: 'UAE(Dubai)',
        value: 'AE',
      },
    ],
  },
  'iam.customerProfile.shippingExperience': {
    question: 'How experienced are you at international shipping?',
    answerKey: 'shipping_experience',
    namespace: 'iam.customerProfile.shippingExperience',
    options: [
      {
        label: 'Beginner',
        value: 'BEGINNER',
      },
      {
        label: 'Intermediate',
        value: 'INTERMEDIATE',
      },
      {
        label: 'Proficient',
        value: 'PROFICIENT',
      },
    ],
  },
  'iam.customerProfile.internationalShipment': {
    question: 'How often do you make international shipments?',
    namespace: 'iam.customerProfile.internationalShipment',
    answerKey: 'international_shipment',
    options: [
      {
        label: 'Once a month',
        value: 'ONCE',
      },
      {
        label: 'Twice a month',
        value: 'TWICE',
      },
      {
        label: 'Multiple times a month',
        value: 'MULTIPLE',
      },
      {
        label: 'Occasionally',
        value: 'OCCASIONALLY',
      },
      {
        label: 'Seasonally',
        value: 'SEASONALLY',
      },
    ],
  },
  'iam.customerProfile.hearAbout': {
    question: 'How did you find out about Heroshe?',
    namespace: 'iam.customerProfile.hearAbout',
    answerKey: 'hear_about',
    options: [
      {
        label: 'Google',
        value: 'GOOGLE',
      },
      {
        label: 'Someone Referred Me',
        value: 'REFERRED',
      },
      {
        label: 'Blog post',
        value: 'BLOG',
      },
      {
        label: 'Social media',
        value: 'SOCIAL',
      },
      {
        label: 'Billboard',
        value: 'BILLBOARD',
      },
      {
        label: 'B.R.T. Bus',
        value: 'BUS'
      },
      {
        label: 'Radio',
        value: 'RADIO',
      },
      {
        label: 'Others',
        value: 'OTHERS',
      },
    ],
  },
};

const surveyJan2023Array = Object.values(surveyJan2023Object);
export default surveyJan2023Array;
