export const HERO_TOUR = {
  address: {
    key: 'address',
    order: 1,
    title: 'Find Your Shipping Address!',
    description:
      'Access multiple warehouses from your dashboard. Choose from the list and dispatch your packages to our Warehouse for timely shipping.',
    list: [],
  },
  payment: {
    key: 'payment',
    order: 2,
    title: 'Spend from Your Wallet.',
    description:
      'Fund your wallet for a smooth checkout process when shipping. Top up your account in your desired currency using a Credit/Debit card or Bank Transfer. ',
    list: [],
  },
  shipment: {
    key: 'shipment',
    order: 3,
    title: 'Track your shipment in real-time',
    description:
      "Visit the 'Shipments' tab, click on the specific shipment for detailed information, and scroll to the real-time tracking section at the bottom of the page to receive updates.",
    list: [],
  },
  pickup: {
    key: 'pickup',
    order: 4,
    title: 'Delivery Options',
    description:
      'Select your delivery choice [Home delivery or Pick-up] at checkout. Schedule a Pick-up appointment when your package arrives and receive your Pick-up Code for in-person or proxy retrieval.',
    list: [],
  },
};
