export const state = () => ({
  commonDataError: '',
  commonDataIsLoading: {
    'notification-banner': false,
    'business-locations': false,
    'contact-numbers': false,
    'faq-page': false,
    timezones: false,
  },
  commonData: {
    'notification-banner': null,
    'business-locations': [],
    'contact-numbers': [],
    'faq-page': null,
    timezones: [],
  },
  commonKeyToUrlMap: {
    'business-locations': '/',
    'contact-numbers': '/',
    'faq-page': '/',
    timezones: '/',
  },
});

export const mutations = {
  setCommonDataError (state, errorMessage) {
    state.commonDataError = errorMessage;
  },
  setCommonDataIsLoading (state, { commonKey, loadingState }) {
    state.commonDataIsLoading = {
      ...state.commonDataIsLoading,
      [commonKey]: loadingState,
    };
  },
  setCommonData (state, { commonKey, data }) {
    state.commonData = {
      ...state.commonData,
      [commonKey]: data,
    };
  },
};

export const getters = {
  commonDataError: (state) => {
    return state.commonDataError;
  },
  commonDataIsLoading: (state) => {
    return state.commonDataIsLoading;
  },
  commonData: (state) => {
    return state.commonData;
  },
  commonKeyToUrlMap: (state) => {
    return state.commonKeyToUrlMap;
  },
  timezonesData: (state) => {
    return state.commonData?.timezones || [];
  },
  notificationBannerData: (state) => {
    return state.commonData?.['notification-banner'];
  },
  businessLocationsData: (state) => {
    return state.commonData?.['business-locations'] || [];
  },
  faqPageData: (state) => {
    return state.commonData?.['faq-page'];
  },
  usWarehouseAddressData: (state) => {
    return state.commonData?.['business-locations']?.filter(x => x.country_code === 'US');
  },
  ngWarehouseAddressData: (state) => {
    return state.commonData?.['business-locations']?.filter(x => x.country_code === 'NG');
  },
  gbWarehouseAddressData: (state) => {
    return state.commonData?.['business-locations']?.filter(x => x.country_code === 'GB');
  },
  cnWarehouseAddressData: (state) => {
    return state.commonData?.['business-locations']?.filter(x => x.country_code === 'CN');
  },
  usContactNumbersData: (state) => {
    return state.commonData?.['contact-numbers']?.filter(x => x.country_code === 'US');
  },
  ngContactNumbersData: (state) => {
    return state.commonData?.['contact-numbers']?.filter(x => x.country_code === 'NG');
  },
  gbContactNumbersData: (state) => {
    return state.commonData?.['contact-numbers']?.filter(x => x.country_code === 'GB');
  },
  cnContactNumbersData: (state) => {
    return state.commonData?.['contact-numbers']?.filter(x => x.country_code === 'CN');
  },
};

export const actions = {
  fetchCommonData ({ commit }, commonKey) {
    this.commit('common/setCommonDataIsLoading', { commonKey, loadingState: true });
    return new Promise((resolve, reject) => {
      return this.$tera.commons[commonKey]()
        .then((data) => {
          this.commit('common/setCommonData', { commonKey, data });
          resolve(data);
        })
        .catch((error) => {
          this.commit('common/setCommonDataError', 'Error loading data');
          reject(error);
        })
        .finally(() => {
          this.commit('common/setCommonDataError', '');
          this.commit('common/setCommonDataIsLoading', { commonKey, loadingState: false });
        });
    });
  },
  fetchNotificationBanner () {
    this.dispatch('common/fetchCommonData', 'notification-banner');
  },
  fetchBusinessLocations () {
    this.dispatch('common/fetchCommonData', 'business-locations');
  },
  fetchContactNumbers () {
    this.dispatch('common/fetchCommonData', 'contact-numbers');
  },
  fetchFaqPage () {
    this.dispatch('common/fetchCommonData', 'faq-page');
  },
  fetchTimezones () {
    this.dispatch('common/fetchCommonData', 'timezones');
  },
};
