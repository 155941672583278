export const state = () => ({
  notifications: {},
  broadcasts: {},
  recentNotifications: [],
  selectedNotifications: [],
  fetchingRecentNotifications: false,
  fetchingBroadcasts: false,
  fetchingNotifications: false,
});

export const mutations = {
  setBroadcasts (state, broadcasts) {
    state.broadcasts = broadcasts;
  },
  setFetchingBroadcasts (state, isLoading) {
    state.fetchingBroadcasts = isLoading;
  },
  setNotifications (state, notifications) {
    state.notifications = notifications;
  },
  setFetchingNotifications (state, isLoading) {
    state.fetchingNotifications = isLoading;
  },
  setRecentNotifications (state, recentNotifications) {
    state.recentNotifications = recentNotifications;
  },
  setFetchingRecentNotifications (state, isLoading) {
    state.fetchingRecentNotifications = isLoading;
  },
  setSelectedNotifications (state, selectedNotifications) {
    state.selectedNotifications = selectedNotifications;
  },
};

export const getters = {
  selectedNotifications: (state) => {
    return state.selectedNotifications;
  },
  broadcasts: (state) => {
    return state.broadcasts;
  },
  fetchingBroadcasts: (state) => {
    return state.fetchingBroadcasts;
  },
  notifications: (state) => {
    return state.notifications;
  },
  fetchingNotifications: (state) => {
    return state.fetchingNotifications;
  },
  recentNotifications: (state) => {
    return state.recentNotifications;
  },
  fetchingRecentNotifications: (state) => {
    return state.fetchingRecentNotifications;
  },
};

export const actions = {
  setSelectedNotifications ({ commit }, notifications) {
    commit('setSelectedNotifications', notifications);
  },
  async fetchRecentNotifications ({ commit }, params = {}) {
    const { silentLoading = false } = params;

    if (!silentLoading) {
      commit('setFetchingRecentNotifications', true);
    }
    try {
      const {
        data: { payload: broadcasts },
      } = await this.$api.notification.broadcasts.broadcasts({});
      const {
        data: { payload: notifications },
      } = await this.$api.notification.notifications.notifications({});
      const broadcastData = broadcasts.broadcasts || [];
      const notificationData = notifications.notifications || [];
      commit('setRecentNotifications', [...broadcastData, ...notificationData]);
      if (!silentLoading) {
        commit('setNotifications', notifications);
        commit('setBroadcasts', broadcasts);
      }
    } catch (error) {
      if (error) {
        const { data = {}, status } = error;
        const { msg } = data;
        if (msg && status && this._vm) {
          this._vm.handleStoreServerError({ msg, status }, this);
        }
      }
    } finally {
      commit('setFetchingRecentNotifications', false);
    }
  },
  async fetchBroadcasts ({ commit }, params = {}) {
    const { silentLoading = false } = params;
    if (silentLoading === false) {
      commit('setFetchingBroadcasts', true);
    }

    try {
      const { data } = await this.$api.notification.broadcasts.broadcasts({ params });
      if (data) {
        commit('setBroadcasts', data.payload || {});
      }
    } catch (error) {
      if (error) {
        const { data = {}, status } = error;
        const { msg } = data;
        if (msg && status && this._vm) {
          this._vm.handleStoreServerError({ msg, status }, this);
        }
      }
    } finally {
      commit('setFetchingBroadcasts', false);
    }
  },
  async fetchNotifications ({ commit }, params = {}) {
    const { silentLoading = false } = params;
    if (silentLoading === false) {
      commit('setFetchingNotifications', true);
    }

    try {
      const { data } = await this.$api.notification.notifications.notifications({ params });
      if (data) {
        commit('setNotifications', data.payload || {});
      }
    } catch (error) {
      if (error) {
        const { data = {}, status } = error;
        const { msg } = data;
        if (msg && status && this._vm) {
          this._vm.handleStoreServerError({ msg, status }, this);
        }
      }
    } finally {
      commit('setFetchingNotifications', false);
    }
  },
};
