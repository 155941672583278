import { mapGetters, mapActions } from 'vuex';

export const pickupMixin = {
  data () {
    return {
      idOrReference: '',
      fetchingPickup: false,
      pickupShipments: [],
      pickupShipmentsPaginationData: {},
      fetchingPickupShipments: false,
      pickup: {},
      confirmToChangeToHomeDeliveryModalIsOpen: false,
      confirmToCancelPickupModalIsOpen: false,
      cancelPickupReference: '',
      cancelPickupSuccessModalIsOpen: false,
      cancelingPickup: false,
      icons: {
        changePickupToHomeConfirm: '/svg/pickups/icon-change-pickup-to-home-confirm.svg',
        cancelPickupConfirm: '/svg/pickups/icon-cancel-pickup-confirm.svg',
        cancelPickupSuccess: '/svg/pickups/icon-cancel-pickup-success.svg',
      },
      // NOTE: for estimate next available pickup slot
      estimatingNextAvailablePickup: false,
      unavailableTimeSlots: [],
      nextAvailablePickupDate: '',
      nextAvailablePickupTime: null,
    };
  },
  computed: {
    ...mapGetters({
      activePickup: 'delivery/activePickup',
      isPaidSubscriber: 'auth/isPaidSubscriber',
      pickupAddresses: 'addressbook/pickupAddresses',
      fetchingPickupAddresses: 'addressbook/fetchingPickupAddresses',
    }),
    canChangeDeliveryChoice () {
      const changeableStatus = this.pickup.status !== this.PICKUP_STATUS_COMPLETED;
      const shipmentsMoreThanZero = this.pickupShipments.length > 0;
      return changeableStatus && shipmentsMoreThanZero;
      // const isPickup = this.pickupShipments?.[0]?.delivery_choice === this.DELIVERY_CHOICE_PICKUP;
      // return changeableStatus && shipmentsMoreThanZero && isPickup;
    },
    // NOTE: for estimate next available pickup slot
    allowSameDayPickup () {
      return !!this.isPaidSubscriber;
    },
    nextAvailablePickupDateTimeFormatted () {
      if (!this.nextAvailablePickupDate || !this.nextAvailablePickupTime) {
        return '';
      }
      // return format "August 20, 2023. 10:00am"
      const { startTime } = this.nextAvailablePickupTime;
      const formattedDate = this.$dayjs(this.nextAvailablePickupDate).format('MMMM D, YYYY');
      const formattedTime = this.$dayjs(startTime).format('hh:mma');
      return `${formattedDate}. ${formattedTime}`;
    },
    pickupLocation () {
      return this.pickupAddresses.find((address) => {
        return address.code === this.pickup.pickupLocationCode;
      });
    },
  },
  created () {
    this.fetchPickupAddresses();
    if (this.activePickup) {
      this.pickup = this.activePickup;
    }
    this.estimateNextAvailablePickupSlot();
  },
  methods: {
    ...mapActions({
      setActivePickup: 'delivery/setActivePickup',
      fetchPickupAddresses: 'addressbook/fetchPickupAddresses',
    }),
    async fetchPickup (idOrReference) {
      if (!this.activePickup) {
        this.fetchingPickup = true;
      }
      try {
        const { data } = await this.$api.delivery.pickups.get({ idOrReference });
        this.pickup = data?.payload?.appointment || {};
        // NOTE: moved here to ensure that we fetch the exact number of pickups for the order
        this.fetchPickupShipments(idOrReference);
      } catch (error) {
        this.handleServerError(error);
        this.$router.push('/pickups');
      } finally {
        this.fetchingPickup = false;
      }
    },
    async fetchPickupShipments (idOrReference) {
      const shipmentsCount = this.pickup?.shipments?.length;
      const query = { pickup_reference: idOrReference, per_page: shipmentsCount };
      this.fetchingPickupShipments = true;
      try {
        const { data } = await this.$api.shipping.shipments.list({ params: query });
        this.pickupShipments = data?.payload?.shipments || [];
        this.pickupShipmentsPaginationData = data?.payload?.meta || {};
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.fetchingPickupShipments = false;
      }
    },
    confirmToChangeToHomeDelivery () {
      this.confirmToChangeToHomeDeliveryModalIsOpen = true;
    },
    closeChangeToHomeDeliveryConfirmModal () {
      this.confirmToChangeToHomeDeliveryModalIsOpen = false;
    },
    confirmToCancelPickup (pickup) {
      const { reference = '' } = pickup || {};
      this.cancelPickupReference = reference;
      this.confirmToCancelPickupModalIsOpen = true;
    },
    closeCancelPickupConfirmModal () {
      this.confirmToCancelPickupModalIsOpen = false;
      this.cancelPickupReference = '';
    },
    async cancelPickup () {
      this.cancelingPickup = true;
      try {
        await this.$api.delivery.pickups.cancel({
          idOrReference: this.cancelPickupReference,
        });
        this.cancelPickupSuccessModalIsOpen = true;
        this.$router.push('/pickups');
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.cancelingPickup = false;
        this.closeCancelPickupConfirmModal();
      }
    },
    // NOTE: for estimate next available pickup slot
    getGeneratedTimeSlots (thisSelectedDate) {
      if (!thisSelectedDate) {
        return [];
      }

      const START_HOUR = 10;
      // Limit Saturday Pickups from 4pm to 2pm
      const isSelectedDateSaturday = this.$dayjs(thisSelectedDate).day() === 6;
      const END_HOUR = isSelectedDateSaturday ? 14 : 16;
      const TOTAL_HOURS = END_HOUR - START_HOUR;
      // PICKUP_DURATION_IN_MINUTES is set in config service. Default is 5 minutes
      const durationInMinutes = this.PICKUP_DURATION_IN_MINUTES;
      const minutesPerHour = 60 / durationInMinutes;
      const totalMinutesPerHours = TOTAL_HOURS * minutesPerHour;
      const selectedDate = this.$dayjs(thisSelectedDate).utc().hour(START_HOUR).minute(0).second(0).millisecond(0);

      const timeSlots = [];
      for (let i = 0; i < totalMinutesPerHours; i++) {
        const startTime = selectedDate.add(i * durationInMinutes, 'minute');
        const endTime = startTime.add(durationInMinutes - 1, 'minute');

        if (this.allowSameDayPickup) {
          // PICKUP_SAME_DAY_GRACE_PERIOD_IN_HOURS is set in config service. Default is 3 hours
          const gracePeriodInHours = this.PICKUP_SAME_DAY_GRACE_PERIOD_IN_HOURS;
          const today = this.$dayjs();
          const hoursAway = today.add(gracePeriodInHours, 'hour');
          const isNotHoursAway = startTime.isBefore(hoursAway);
          if (isNotHoursAway) {
            continue;
          }
        }

        timeSlots.push({
          startTime: this.$dayjs(startTime).format(this.PICKUP_TIME_SLOT_FORMAT),
          endTime: this.$dayjs(endTime).format(this.PICKUP_TIME_SLOT_FORMAT),
        });
      }

      return timeSlots;
    },
    getAvailableTimeSlots (selectedDate) {
      return this.getGeneratedTimeSlots(selectedDate).filter((ts) => {
        return this.isTimeSlotAvailable(ts);
      });
    },
    async estimateNextAvailablePickupSlot (date) {
      this.estimatingNextAvailablePickup = true;
      await this.fetchUnavailableTimeSlots(date);
      date = date || new Date().toISOString();
      const selectedDate = this.$dayjs(date).format('YYYY-MM-DD');
      const availableTimeSlots = this.getAvailableTimeSlots(selectedDate);
      if (this.isDateDisabled(date) || availableTimeSlots.length === 0) {
        const nextDate = this.$dayjs(date).add(1, 'day').toISOString();
        this.estimateNextAvailablePickupSlot(nextDate);
        return;
      }
      if (availableTimeSlots.length > 0) {
        this.nextAvailablePickupDate = selectedDate;
        this.nextAvailablePickupTime = availableTimeSlots[0];
      }
      this.estimatingNextAvailablePickup = false;
    },
    isTimeSlotUnavailable (timeSlot) {
      const isUnavailable = this.unavailableTimeSlots.some((ts) => {
        return this.checkUnavailability(ts, timeSlot);
      });
      return isUnavailable;
    },
    isTimeSlotAvailable (timeSlot) {
      return !this.isTimeSlotUnavailable(timeSlot);
    },
    checkUnavailability (timeSlot, generatedTimeSlot) {
      const { startTime: startTimeInUtc, endTime: endTimeInUtc } = timeSlot;
      const { startTime: generatedStartTime } = generatedTimeSlot;
      const startTime = startTimeInUtc.replace(':00Z', '');
      const endTime = endTimeInUtc.replace(':00Z', '');
      const isSameOrAfterStartTime = this.$dayjs(generatedStartTime).isSameOrAfter(startTime);
      const isSameOrBeforeEndTime = this.$dayjs(generatedStartTime).isSameOrBefore(endTime);
      return isSameOrAfterStartTime && isSameOrBeforeEndTime;
    },
    isDateDisabled (dateObject) {
      const today = this.$dayjs();
      const date = this.$dayjs(dateObject);
      const isPastDate = date.isSameOrBefore(today);
      const PICKUP_SCHEDULE_PERIOD_OF_DAYS = 7;
      const maxAllowedDay = today.add(PICKUP_SCHEDULE_PERIOD_OF_DAYS, 'day');
      const isDateFarIntoTheFuture = date.isSameOrAfter(maxAllowedDay);
      const dateIsWeekend = date.day() === 0;
      if (this.allowSameDayPickup) {
        const isToday = date.isToday(today);
        return (isPastDate && !isToday) || isDateFarIntoTheFuture || dateIsWeekend;
      }
      return isPastDate || isDateFarIntoTheFuture || dateIsWeekend;
    },
    getDayAndNext7Days (date) {
      date = date || new Date().toISOString();
      return {
        day: this.$dayjs(date).format('YYYY-MM-DD'),
        next7Days: date ? this.$dayjs(date).add(7, 'day').format('YYYY-MM-DD') : '',
      };
    },
    async fetchUnavailableTimeSlots (date) {
      const { day, next7Days } = this.getDayAndNext7Days(date);
      const pickupAddress = this.pickupAddresses.find((address) => {
        return address.timezoneId === this.preferredTimezone || address.timezoneId === this.PICKUP_TIMEZONE;
      });

      try {
        const { data } = await this.$api.delivery.pickups.listUnavailableTimeSlots({
          params: {
            pickup_location_code: pickupAddress?.code,
            start_time: this.$dayjs(day).format(this.PICKUP_TIME_SLOT_FORMAT),
            end_time: this.$dayjs(next7Days).format(this.PICKUP_TIME_SLOT_FORMAT),
            per_page: 100,
          },
        });
        this.unavailableTimeSlots = data?.payload?.timeslots || [];
      } catch (error) {
        // do nothing
      }
    },
  },
};
