import SHIPMENT_STATUSES from '~/assets/constants/SHIPMENT_STATUSES.js';

export const state = () => ({
  activeShipment: null,
  customShippingLabel: null,
  fetchingCustomShippingLabel: false,
  inboundTrackingNo: null,
  receivedTrackingNo: null,
  totalUnpaidShipmentsIsLoading: false,
  totalShipmentsIsLoading: false,
  totalUnpaidShipments: 0,
  totalShipments: 0,
  shipmentStatuses: [],
  fetchingShipmentStatuses: false,
});

export const mutations = {
  setActiveShipment (state, shipment) {
    state.activeShipment = shipment || null;
  },
  setInboundTrackingNo (state, trackingNo) {
    state.inboundTrackingNo = trackingNo;
  },
  setReceivedTrackingNo (state, trackingNo) {
    state.receivedTrackingNo = trackingNo;
  },
  setTotalShipments (state, shipments) {
    state.totalShipments = shipments;
  },
  setTotalUnpaidShipments (state, shipments) {
    state.totalUnpaidShipments = shipments;
  },
  setTotalShipmentsIsLoading (state, isLoading) {
    state.totalShipmentsIsLoading = isLoading;
  },
  setTotalUnpaidShipmentsIsLoading (state, isLoading) {
    state.totalUnpaidShipmentsIsLoading = isLoading;
  },
  setShipmentStatuses (state, statuses = []) {
    if (Array.isArray(statuses) && statuses.length === 0) {
      state.shipmentStatuses = SHIPMENT_STATUSES;
      return;
    }
    state.shipmentStatuses = statuses;
  },
  setFetchingShipmentStatuses: (state, isLoading) => {
    state.fetchingShipmentStatuses = isLoading;
  },
  setFetchingCustomShippingLabel: (state, isLoading) => {
    state.fetchingCustomShippingLabel = isLoading;
  },
  setCustomShippingLabel: (state, label) => {
    state.customShippingLabel = label;
  },
};

export const getters = {
  activeShipment: (state) => {
    return state.activeShipment;
  },
  inboundTrackingNo (state) {
    return state.inboundTrackingNo;
  },
  receivedTrackingNo (state) {
    return state.receivedTrackingNo;
  },
  totalUnpaidShipment: (state) => {
    return state.totalUnpaidShipments;
  },
  totalShipments: (state) => {
    return state.totalShipments;
  },
  totalShipmentsIsLoading: (state) => {
    return state.totalShipmentsIsLoading;
  },
  totalUnpaidShipmentsIsLoading: (state) => {
    return state.totalUnpaidShipmentsIsLoading;
  },
  shipmentStatuses: (state) => {
    return state.shipmentStatuses;
  },
  fetchingShipmentStatuses: (state) => {
    return state.fetchingShipmentStatuses;
  },
  fetchingCustomShippingLabel: (state) => {
    return state.fetchingCustomShippingLabel;
  },
  customShippingLabel: (state) => {
    return state.customShippingLabel;
  },
};

export const actions = {
  initializeShipmentStatuses: ({ commit }) => {
    commit('setShipmentStatuses', SHIPMENT_STATUSES);
  },
  async fetchShipmentStatuses () {
    this.commit('shipment/setFetchingShipmentStatuses', true);

    try {
      const { data } = await this.$api.shipping.shipments.listShipmentStatuses();
      this.commit('shipment/setShipmentStatuses', data.payload.statuses || []);
    } catch (error) {
      const { data = {}, status } = error || {};
      const { msg } = data;
      if (msg && status && this._vm) {
        this._vm.handleStoreServerError({ msg, status }, this);
      }
    } finally {
      this.commit('shipment/setFetchingShipmentStatuses', false);
    }
  },
  async fetchCustomShippingLabel () {
    this.commit('shipment/setFetchingCustomShippingLabel', true);
    this.commit('shipment/setCustomShippingLabel', null);

    try {
      const { data } = await this.$api.shipping.customShippingLabel.get();
      this.commit('shipment/setCustomShippingLabel', data.payload.label);
    } catch (error) {
      const { data = {}, status } = error || {};
      const { msg } = data;
      if (msg && status && this._vm) {
        this._vm.handleStoreServerError({ msg, status }, this);
      }
    } finally {
      this.commit('shipment/setFetchingCustomShippingLabel', false);
    }
  },
  setActiveShipment ({ commit }, shipment) {
    commit('setActiveShipment', shipment);
  },
  setInboundTrackingNoAction ({ commit }, trackingNo) {
    commit('setInboundTrackingNo', trackingNo || null);
  },
  setReceivedTrackingNoAction ({ commit }, trackingNo) {
    commit('setReceivedTrackingNo', trackingNo || null);
  },
  inboundShipment ({ rootState }, params) {
    return new Promise((resolve, reject) => {
      const headers = {};

      return this.$repository.shipments
        .inbound(headers, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  async fetchTotalShipments ({ commit }) {
    this.commit('shipment/setTotalShipmentsIsLoading', true);
    try {
      const { data } = await this.$api.shipping.shipments.list({});
      this.commit('shipment/setTotalShipments', data?.payload?.meta?.totalRows || 0);
    } catch (error) {
      this._vm.handleServerError(error);
    } finally {
      this.commit('shipment/setTotalShipmentsIsLoading', false);
    }
  },
  async fetchUnpaidShipments ({ commit }) {
    this.commit('shipment/setTotalUnpaidShipmentsIsLoading', true);
    const queryParams = {
      payment_status: this._vm.SHIPMENT_PAYMENT_STATUS_UNPAID,
    };
    try {
      const { data } = await this.$api.shipping.shipments.list({ params: queryParams });
      this.commit('shipment/setTotalUnpaidShipments', data?.payload?.meta?.totalRows || 0);
    } catch (error) {
      this._vm.handleServerError(error);
    } finally {
      this.commit('shipment/setTotalUnpaidShipmentsIsLoading', false);
    }
  },
};
