const CURRENCY = {
  DEFAULT: {},
  USD: {
    title: 'US Dollar',
    shortText: 'Dollar',
    value: 'USD',
    symbol: '$',
    flagEmoji: '🇺🇸',
  },
  NGN: {
    title: 'Nigerian Naira',
    shortText: 'Naira',
    value: 'NGN',
    symbol: '₦',
    flagEmoji: '🇳🇬',
  },
};

CURRENCY.DEFAULT = CURRENCY.USD;

export default CURRENCY;
