const componentsMixin = {
  data () {
    return {
      uiComponents: [
        'Alert',
        'OutlineAlert',
        'Breadcrumbs',
        'CallToAction',
        'ConfirmationModal',
        'DashboardTabs',
        'Divider',
        'Drawer',
        'Dropdown',
        'EmptyState',
        'Faqs',
        'Icon',
        'Loader',
        'NewModal',
        'NotificationModal',
        'OptionButton',
        'PasswordStrength',
        'ProgressBar',
        'QuickNote',
        'SimpleTooltip',
        'StatusLabel',
        'Tabs',
        'Toast',
      ],
      inputsComponents: [
        'InputCheckboxMultiple',
        'InputCheckboxSingle',
        'InputFile',
        'InputPhoneNumber',
        'InputRadio',
        'InputSearch',
        'InputSelectDropdown',
        'Toggle',
        'OtpInput',
      ],
      dataComponents: ['CopyButton', 'DataFlex', 'DataTable', 'Pagination'],
      shipmentsComponents: [
        'CartShipment',
        'CheckoutCartShipment',
        'DeliveryTimeline',
        'ShipmentImages',
        'ShipmentStatusLabel',
      ],
      ordersComponents: ['OrderStatusLabel'],
      paymentsComponents: ['CurrencySwitcher', 'PaymentOption', 'WalletSwitcher'],
    };
  },
  computed: {
    navSectionsArr () {
      return [
        {
          title: 'Ui',
          components: this.uiComponents,
        },
        {
          title: 'Inputs',
          components: this.inputsComponents,
        },
        {
          title: 'Data',
          components: this.dataComponents,
        },
        {
          title: 'Shipments',
          components: this.shipmentsComponents,
        },
        {
          title: 'Orders',
          components: this.ordersComponents,
        },
        {
          title: 'Payments',
          components: this.paymentsComponents,
        },
      ];
    },
  },
};

export default componentsMixin;
