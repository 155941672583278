const facebookPixelMixin = {
  data () {
    return {
      fbq: {
        track: {
          signup: () => this.getFbq()('track', 'Signup'),
          checkout: () => this.getFbq()('track', 'Checkout'),
          orderPayment: (value, currency) => {
            this.getFbq()('track', 'OrderPayment', { value, currency });
          },
          subscription: (plan) => {
            this.getFbq()('track', 'Subscribe', { plan });
          },
        },
      },
    };
  },
  methods: {
    getFbq () {
      if (window.fbq) {
        return window.fbq;
      }
      return () => {};
    },
  },
};

export default facebookPixelMixin;
