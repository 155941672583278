export const formValidationMessages = {
  required: () => 'This is required',
  email: () => 'Invalid email address',
  numeric: () => 'This field expects only numbers',
  length: ({ $params }) => `${$params.length.len} characters expected`,
  password: () => 'At least 6 characters long, with one uppercase letter or one number',
  sameAsNewPassword: () => 'Does not match new password',
  minLength: ({ $params }) => `Must be at least ${$params.minLength.min} characters.`,
  maxLength: ({ $params }) => `Must be at most ${$params.maxLength.max} characters.`,
  minValue: ({ $params }) => `Must be greater than or equal to ${$params.minValue.min}`,
  maxValue: ({ $params }) => `Must be less than or equal to ${$params.maxValue.max}`,
  phone: () => 'Invalid phone number',
  country: () => 'Selected country must match Phone number',
  shippingLabelText: () => 'Heroshe is not allowed to be a custom shipping label',
  existInKey: () => 'Select and item from the listed options',
  checked: () => 'This is required',
};
