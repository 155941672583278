export const state = () => ({
  subscriptionDataIsLoading: false,
  subscriptionData: [],
  activeInvoice: {},
});

export const mutations = {
  setSubscriptionDataIsLoading (state, isLoadingVal) {
    state.subscriptionDataIsLoading = isLoadingVal;
  },
  setSubscriptionData (state, subscriptions) {
    state.subscriptionData = subscriptions;
  },
  setActiveInvoice (state, invoice) {
    state.activeInvoice = invoice;
  },
};

export const getters = {
  subscriptionDataIsLoading: (state) => {
    return state.subscriptionDataIsLoading;
  },
  subscriptionData: (state) => {
    return state.subscriptionData;
  },
  activeInvoice: (state) => {
    return state.activeInvoice;
  },
};

export const actions = {
  async fetchUserSubscription ({ commit }, params = {}) {
    this.commit('subscription/setSubscriptionDataIsLoading', true);
    this.dispatch('notification/clearNotification');

    try {
      const { data } = await this.$api.subscription.subscriptions.get({ id: 'me', params });
      this.commit('subscription/setSubscriptionData', data.payload.subscription);
    } catch (error) {
      const { data = {}, status } = error.response || error || {};
      const { msg } = data;
      if (msg && status && this._vm) {
        this._vm.handleStoreServerError({ msg, status }, this);
      }
    } finally {
      this.commit('subscription/setSubscriptionDataIsLoading', false);
    }
  },
  setActiveInvoice ({ commit }, invoice) {
    commit('setActiveInvoice', invoice);
  },
};
