import PAYMENT_TAG from '~/assets/constants/PAYMENT_TAG.js';

const reapplyPaymentMixin = {
  data () {
    return {
      reapplyingPaymentRef: '',
      reappliedPayment: null,
    };
  },
  methods: {
    getReapplyPaymentText (payment) {
      switch (payment.tag) {
        case PAYMENT_TAG.ORDER:
          return "'Re-apply payment' for failed order payment";
        case PAYMENT_TAG.FUND_WALLET:
          return "'Re-apply payment' for failed fund wallet transaction";
        case PAYMENT_TAG.SUBSCRIPTION:
          return "'Re-apply payment' for failed subscription payment";
      }
    },
    reapplyPayment (payment) {
      switch (payment.tag) {
        case PAYMENT_TAG.ORDER:
          this.reapplyPaymentForOrder(payment);
          break;
        case PAYMENT_TAG.FUND_WALLET:
          this.reapplyPaymentForFundWallet(payment);
          break;
        case PAYMENT_TAG.SUBSCRIPTION:
          this.reapplyPaymentForSubscription(payment);
          break;
      }
    },
    async reapplyPaymentForOrder (payment) {
      const requestData = {
        channel: payment.channel,
        processor: payment.processor,
        payment_reference: payment.reference,
        currency: payment.currency,
        amount: payment.amount.charge,
        payment_meta: payment.meta,
      };
      const idOrReference = payment.meta?.order_id;
      if (!idOrReference) {
        return;
      }

      try {
        this.reapplyingPaymentRef = payment.reference;
        const { data } = await this.$api.ordering.orders.applyPayment({
          idOrReference,
          data: requestData,
        });
        // Update order on order details page
        if (this.order && data?.payload?.order && this.setActiveOrder && this.fetchOrderPayments) {
          this.order = data.payload.order;
          this.setActiveOrder(this.order);
          this.fetchOrderPayments(idOrReference);
        }
        this.$emit('reapply-payment-success');
        this.reappliedPayment = payment;
      } catch (error) {
        this.handleServerError(error);
        this.$emit('reapply-payment-error');
      } finally {
        this.reapplyingPaymentRef = '';
      }
    },
    async reapplyPaymentForFundWallet (payment) {
      const requestData = {
        channel: payment.channel,
        processor: payment.processor,
        currency: payment.currency,
        payment_reference: payment.reference,
        amount: payment.amount.charge,
        payment_meta: payment.meta,
      };

      try {
        this.reapplyingPaymentRef = payment.reference;
        await this.$api.payment.wallet.fund({ data: requestData });
        this.$emit('reapply-payment-success');
        this.reappliedPayment = payment;
      } catch (error) {
        this.handleServerError(error);
        this.$emit('reapply-payment-error');
      } finally {
        this.reapplyingPaymentRef = '';
      }
    },
    // TODO: Reapply payment for SUBSCRIPTION
    async reapplyPaymentForSubscription () {},
  },
};

export default reapplyPaymentMixin;
