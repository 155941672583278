
import InputCheckboxMultiple from '~/components/inputs/InputCheckboxMultiple.vue';
import Icon from '~/components/ui/Icon.vue';

export default {
  name: 'NotificationRow',
  components: {
    InputCheckboxMultiple,
    Icon,
  },
  props: {
    showCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
    notification: {
      type: Object,
      default: () => ({ title: '', body: '', createdAt: '' }),
      required: true,
    },
    selectedNotifications: {
      type: Array,
      default: () => [],
      required: false,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
  },
  data () {
    return {};
  },
};
