
export default {
  name: 'Loader',
  props: {
    size: {
      type: String,
      default: 'small',
      validator: value => ['small', 'large'].includes(value),
    },
  },
};
