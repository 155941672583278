const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['fetch-common-data'] = require('../middleware/fetch-common-data.js')
middleware['fetch-common-data'] = middleware['fetch-common-data'].default || middleware['fetch-common-data']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

export default middleware
