
import DashboardLayout from '~/components/layout/DashboardLayout.vue';
import { pageMixin } from '~/mixins/index.js';

export default {
  components: {
    DashboardLayout,
  },
  mixins: [pageMixin],
  middleware: 'auth',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      WebUrl: this.$config.WebUrl,
      showPage: false,
    };
  },
  head: {
    title: 'Heroshe - Error 404',
  },
  mounted () {
    // delay page render to avoid flickering
    setTimeout(() => {
      this.showPage = true;
    }, 1);
  },
  methods: {},
};
