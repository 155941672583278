const CHECKOUT_STORAGE_KEY = 'checkout';
const CHECKOUT_SAVED_AT_STORAGE_KEY = 'checkout_saved_at';

const persistCheckoutShipmentsInStorage = (state) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.setItem(
    CHECKOUT_STORAGE_KEY,
    JSON.stringify(state.checkoutShipments)
  );
  window.localStorage.setItem(
    CHECKOUT_SAVED_AT_STORAGE_KEY,
    new Date().toISOString()
  );
};

const getCheckoutShipmentsInStorage = () => {
  if (typeof window === 'undefined') {
    return;
  }
  if (window.localStorage.getItem(CHECKOUT_STORAGE_KEY)) {
    const checkoutShipments = JSON.parse(
      window.localStorage.getItem(CHECKOUT_STORAGE_KEY)
    );
    return checkoutShipments;
  }
  return [];
};

const getCheckoutSavedAt = () => {
  if (typeof window === 'undefined') {
    return;
  }
  if (window.localStorage.getItem(CHECKOUT_SAVED_AT_STORAGE_KEY)) {
    const checkoutSavedAt = window.localStorage.getItem(
      CHECKOUT_SAVED_AT_STORAGE_KEY
    );
    return checkoutSavedAt;
  }
  return null;
};

const desistCheckoutShipmentsInStorage = () => {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.removeItem(CHECKOUT_STORAGE_KEY);
  window.localStorage.removeItem(CHECKOUT_SAVED_AT_STORAGE_KEY);
};

export const state = () => ({
  checkoutOrder: {},
  checkoutShipments: [],
  deliveryAddress: null,
  deliveryChoice: null,
  shippingMethod: null,
  paymentChoice: null
});

export const mutations = {
  setCheckoutOrder (state, value) {
    state.checkoutOrder = value || {};
  },
  setDeliveryAddress (state, value) {
    state.deliveryAddress = value;
  },
  setDeliveryChoice (state, value) {
    state.deliveryChoice = value;
  },
  setShippingMethod (state, value) {
    state.shippingMethod = value;
  },
  setCheckoutShipments (state, value) {
    state.checkoutShipments = value || [];
    persistCheckoutShipmentsInStorage(state);
  },
  setPaymentChoice (state, value) {
    state.paymentChoice = value;
  },
  // set checkout order and shipments based on data from API
  setCheckout (state, data) {
    if (data?.payload?.order) {
      state.checkoutOrder = data.payload.order;
    }
    if (data?.payload?.shipments?.shipments) {
      const sortCheckoutShipments = (a, b) => a.id.localeCompare(b.id);
      const shipments = data.payload.shipments?.shipments || [];
      state.checkoutShipments = shipments.sort(sortCheckoutShipments);
      persistCheckoutShipmentsInStorage(state);
    }
  },
};

export const getters = {
  checkoutOrder: (state) => {
    return state.checkoutOrder;
  },
  checkoutShipments: (state) => {
    return state.checkoutShipments;
  },
  deliveryAddress: (state) => {
    return state.deliveryAddress;
  },
  deliveryChoice: (state) => {
    return state.deliveryChoice;
  },
  shippingMethod: (state) => {
    return state.shippingMethod;
  },
  paymentChoice: (state) => {
    return state.paymentChoice;
  }
};

export const actions = {
  setPaymentChoice ({ commit }, data) {
    commit('setPaymentChoice', data);
  },
  setCheckoutShipments ({ commit, state }, shipments = []) {
    commit('setCheckoutShipments', shipments);
  },
  addShipmentsToCheckout ({ commit, state }, shipments = []) {
    const distinctShipments = [
      ...new Set([...state.checkoutShipments, ...shipments]),
    ];
    commit('setCheckoutShipments', distinctShipments);
  },
  removeShipmentsFromCheckout ({ commit, state }, shipments = []) {
    const filteredShipments = state.checkoutShipments.filter((s) => {
      return !shipments.some(shipment => shipment.id === s.id);
    });
    commit('setCheckoutShipments', filteredShipments);
    shipments.forEach(async (shipment) => {
      await this.$segment.tracks.removeShipmentFromCart({
        weight: shipment.weight,
        item_description: shipment.infoRaw,
      });
    });
  },
  setCheckout ({ commit }, data) {
    commit('setCheckout', data);
  },
  clearCheckout ({ commit }) {
    commit('setCheckoutShipments');
    desistCheckoutShipmentsInStorage();
  },
  restoreCheckoutFromStorage () {
    const checkoutSavedAt = getCheckoutSavedAt();
    if (checkoutSavedAt) {
      // if checkout is saved more than 2 days ago, clear checkout
      const checkoutSavedAtDate = new Date(checkoutSavedAt);
      const now = new Date();
      const diffInDays = Math.floor(
        (now - checkoutSavedAtDate) / (1000 * 60 * 60 * 24)
      );
      if (diffInDays > 2) {
        this.dispatch('checkout/clearCheckout');
        return;
      }
    }
    const checkoutShipments = getCheckoutShipmentsInStorage();
    if (checkoutShipments.length > 0) {
      this.commit('checkout/setCheckoutShipments', checkoutShipments);
    }
  },
  setCheckoutOrder ({ commit }, data) {
    commit('setCheckoutOrder', data);
  },
  updateDeliveryAddress ({ commit }, data) {
    commit('setDeliveryAddress', data);
  },
  updateDeliveryChoice ({ commit }, data) {
    commit('setDeliveryChoice', data);
  },
  updateShippingMethod ({ commit }, data) {
    commit('setShippingMethod', data);
  }
};
