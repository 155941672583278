const FEATURE_FLAG = {
  AUTO_PAY: 'AUTO-PAY',
  CASH_BACK: 'CASH-BACK',
  REAL_TIME_TRACKING_20231118: 'REAL-TIME-TRACKING-20231118',
  REQUEST_NAME_CHANGE: 'REQUEST-NAME-CHANGE',
  VALIDATE_VOUCHER: 'VALIDATE-VOUCHER',
  HERO_TOUR_20231201: 'HERO_TOUR_20231201',
  CHANGE_SHIPPING_METHODS_2024_0730: 'CHANGE_SHIPPING_METHODS_2024_0730'
};

export default FEATURE_FLAG;
