const SHIPPING_METHODS = {
  AIR_FREIGHT: {
    key: 'AIR_FREIGHT',
    name: 'Air Freight',
    title: 'Air Shipping ($11/kg)',
    icon: '/svg/checkout/icon-air-shipping.svg',
    price: '8',
    description: '',
    perks: [
      {
        title: '7 - 10 days',
        icon: '/svg/checkout/icon-timeline.svg',
      },
      {
        title: 'Faster shipping',
        icon: '/svg/checkout/icon-affordable-and-clean-energy.svg',
      },
      {
        title: 'Standard shipping',
        icon: '/svg/checkout/icon-ship-cruise-travel-boat-transportation-transport-ocean-liner-ship-sea-water.svg',
      },
    ],
  },
  OCEAN_FREIGHT: {
    key: 'OCEAN_FREIGHT',
    name: 'Ocean Freight',
    title: 'Ocean Shipping ($5/kg)',
    icon: '/svg/checkout/icon-ocean-shipping.svg',
    price: '2',
    description:
      '<p>Save cost on non-urgent shipments by choosing ocean shipment.  It’s equally secured, and you have guaranteed value for your money</p>',
    perks: [
      {
        title: '60 - 90 days',
        icon: '/svg/checkout/icon-timeline.svg',
      },
      {
        title: 'Cheaper shipping',
        icon: '/svg/checkout/icon-affordable-and-clean-energy.svg',
      },
      {
        title: 'Standard shipping',
        icon: '/svg/checkout/icon-ship-cruise-travel-boat-transportation-transport-ocean-liner-ship-sea-water.svg',
      },
    ],
  },
};
export const SHIPPING_METHODS_ARRAY = Object.values(SHIPPING_METHODS);
export default SHIPPING_METHODS;
