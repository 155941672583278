const logoMixin = {
  data () {
    return {
      defaultLogo: '/svg/logo/heroshe-logo.svg',
      christmasLogo: '/svg/logo/Heroshe-Christmas-Logo-cap.gif',
      valentineLogo: '/svg/logo/new-heroshe-valentine-logo.svg',
    };
  },
  computed: {
    logo () {
      if (
        this.isChristmasTime()
      ) {
        return this.christmasLogo;
      }
      if (
        this.isValentinesTime()
      ) {
        return this.valentineLogo;
      }
      return this.defaultLogo;
    },
  },
  methods: {
    isChristmasTime () {
      const currentDate = new Date();
      const christmasStart = new Date(currentDate.getFullYear(), 11, 1);
      const christmasEnd = new Date(currentDate.getFullYear(), 0, 3);

      return currentDate >= christmasStart && currentDate <= christmasEnd;
    },

    isValentinesTime () {
      const currentDate = new Date();
      const valentineStart = new Date(currentDate.getFullYear(), 1, 1);
      const valentineEnd = new Date(currentDate.getFullYear(), 1, 23);

      return currentDate >= valentineStart && currentDate <= valentineEnd;
    },
    marketingEventTime () {
      const currentDate = new Date();
      const twitterStart = new Date(currentDate.getFullYear(), 5, 5);
      const twitterEnd = new Date(currentDate.getFullYear(), 5, 15);

      return currentDate >= twitterStart && currentDate <= twitterEnd;
    },
    marketingTwitterTime () {
      const currentDate = new Date();
      const valentineStart = new Date(currentDate.getFullYear(), 5, 17);
      const valentineEnd = new Date(currentDate.getFullYear(), 5, 28);

      return currentDate >= valentineStart && currentDate <= valentineEnd;
    },
    blackFridayTimer () {
      const currentDate = new Date();
      const blackFridayStart = new Date(currentDate.getFullYear(), 10, 20);
      const blackFridayEnd = new Date(currentDate.getFullYear(), 10, 28);

      return currentDate >= blackFridayStart && currentDate <= blackFridayEnd;
    },
  },
};

export default logoMixin;
