
export default {
  name: 'Drawer',
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
  },
  data () {
    return {
      icons: {
        closeDrawer: '/svg/icon-close.svg',
      },
    };
  },
  methods: {
    closeDrawer () {
      this.$emit('close');
    },
  },
};
