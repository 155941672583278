import createConfigService from './api/config.js';
import createDeliveryService from './api/delivery.js';
import createIamService from './api/iam.js';
import createOrderingService from './api/ordering.js';
import createPaymentService from './api/payment.js';
import createShippingService from './api/shipping.js';
import createNotificationService from './api/notification.js';
import createSubscriptionService from './api/subscription.js';
import createSubscriptionServiceV2 from './api/subscriptionv2.js';

export default (ctx, inject) => {
  // inject service apis as this.$api
  inject('api', {
    config: createConfigService(ctx.$axios),
    delivery: createDeliveryService(ctx.$axios),
    iam: createIamService(ctx.$axios),
    ordering: createOrderingService(ctx.$axios),
    payment: createPaymentService(ctx.$axios),
    shipping: createShippingService(ctx.$axios),
    notification: createNotificationService(ctx.$axios),
    subscription: createSubscriptionService(ctx.$axios),
    subscriptionv2: createSubscriptionServiceV2(ctx.$axios),
  });
};
