// https://stackoverflow.com/questions/3969475/javascript-pause-settimeout

export default function Timer (callbackFn, duration) {
  let id;
  let totalTimeRun = 0;
  let complete = false;
  const startTime = new Date().getTime();

  function timeDiff (date1, date2) {
    return date2 ? date2 - date1 : startTime - date1;
  }

  function cancel () {
    clearTimeout(id);
  }

  function pause () {
    clearTimeout(id);
    totalTimeRun = timeDiff(startTime);
    complete = totalTimeRun >= duration;
  }

  function resume () {
    id = complete ? -1 : setTimeout(callbackFn, duration - totalTimeRun);
  }

  id = setTimeout(callbackFn, duration);

  return { id, cancel, pause, resume };
}
