import { axiosOnce } from '~/plugins/axiosOnce.js';

const getServiceBasePath = version => `/delivery/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  addressBook: {
    list: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/addresses`;
      return axiosOnce($axios).get(urlPath, { headers, params });
    },
    get: ({ id, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/addresses/${id}`;
      return $axios.get(urlPath, { headers });
    },
    create: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/addresses`;
      return $axios.post(urlPath, data, { headers });
    },
    update: ({ id, headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/addresses/${id}`;
      return $axios.put(urlPath, data, { headers });
    },
    delete: ({ id, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/addresses/${id}`;
      return $axios.delete(urlPath, { headers });
    },
    getStatesCities: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/addresses/states-cities`;
      return $axios.get(urlPath, { headers, params });
    },
    estimateDeliveryFee: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/public/addresses/estimate-delivery-fee`;
      return $axios.post(urlPath, data, { headers });
    },
  },
  pickups: {
    list: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/pickups/appointments`;
      return $axios.get(urlPath, { headers, params });
    },
    get: ({ idOrReference, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/pickups/appointments/${idOrReference}`;
      return $axios.get(urlPath, { headers });
    },
    create: ({ data, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/pickups/appointments`;
      return $axios.post(urlPath, data, { headers });
    },
    // cancel: ({ idOrReference, headers }) => {
    //   const urlPath = `${getServiceBasePath($apiVersion)}/pickups/appointments/${idOrReference}`;
    //   return $axios.delete(urlPath, { headers });
    // },
    cancel: ({ idOrReference, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/pickups/appointments/${idOrReference}/cancel`;
      return $axios.put(urlPath, { headers });
    },
    listUnavailableTimeSlots: ({ headers, params }) => {
      // Sample Params // params = { start_date = "", end_date = "" }
      const urlPath = `${getServiceBasePath($apiVersion)}/pickups/unavailabilities/timeslots`;
      return $axios.get(urlPath, { headers, params });
    },
    estimateStorageFee: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/pickups/appointments/estimate-storage`;
      return $axios.post(urlPath, data, { headers });
    },
    listPickupLocations: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/pickups/locations`;
      return $axios.get(urlPath, { headers, params });
    },
  },
});
