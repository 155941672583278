import { nanoid } from 'nanoid';
import Timer from '~/assets/js/Timer.js';

const DEFAULT_NOTIFICATION_TIMEOUT = 2000;
const pauseNotification = (n) => {
  if (n.timer && n.timer.pause) {
    n.timer.pause();
  }
};
const resumeNotification = (n) => {
  if (n.timer && n.timer.resume) {
    n.timer.resume();
  }
};

export const state = () => ({
  notifications: [],
});

export const mutations = {
  addToNotifications (state, notification) {
    const { id, heading, message, context, content, timer } = notification;
    state.notifications = [
      {
        id,
        heading,
        message,
        context,
        content,
        timer,
      },
      ...state.notifications,
    ];
  },
  removeFromNotifications (state, notificationId) {
    state.notifications = state.notifications.filter((n) => {
      return n.id !== notificationId;
    });
  },
  clearToastNotifications (state) {
    state.notifications = [];
  },
};

export const getters = {
  notifications: (state) => {
    return state.notifications;
  },
};

export const actions = {
  addToastNotification ({ commit }, notification) {
    const notificationId = `NID_${nanoid().toUpperCase()}`;

    // Auto dismiss notification after a certain amount of time
    const { pause, resume } = new Timer(() => {
      commit('removeFromNotifications', notificationId);
    }, DEFAULT_NOTIFICATION_TIMEOUT);

    commit('addToNotifications', {
      ...notification,
      id: notificationId,
      timer: {
        pause,
        resume,
      },
    });
  },
  dismissToastNotification ({ commit }, notificationId) {
    commit('removeFromNotifications', notificationId);
  },
  clearToastNotification ({ commit }) {
    commit('clearToastNotifications');
  },
  pauseToastNotification (_, notification) {
    pauseNotification(notification);
  },
  resumeToastNotification (_, notification) {
    resumeNotification(notification);
  },
};
