export const state = () => ({
  search: null,
});

export const mutations = {
  setSearch (state, value) {
    state.search = value;
  },
};

export const getters = {
  getSearch: (state) => {
    return state.search;
  },
};

export const actions = {
  setSearch ({ commit }, value) {
    commit('setSearch', value);
  },
  resetSearch ({ commit }) {
    commit('setSearch', null);
  },
};
