export default function ({ $axios, store, route, redirect, error }) {
  $axios.onRequest((config) => {
    const token = store.state.auth.token;
    // ...
    // Refresh JWT token if needed
    // ...
    if (token) {
      config.headers.Authorization = `Session ${token}`;
    } else {
      delete config.headers.Authorization;
    }

    Object.entries(route.query).forEach(([paramKey, paramValue]) => {
      if (paramKey.includes('FF_')) {
        config.headers[`X-${paramKey}`] = paramValue;
      }
    });

    return config;
  });

  $axios.onError((err) => {
    const { status } = err.request || {};

    // Handling 401 UnAuthorized Error
    if (status === 401) {
      store.commit('auth/resetAuth');
      redirect(`/auth/signin?prevPath=${route.fullPath}`);
    }

    // // Handling 500 Server Error
    // if (store._vm.isServerError(status)) {
    //   error({ statusCode: status });
    // }
  });
}
