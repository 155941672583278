export const state = () => ({
  activePayment: null,
});

export const mutations = {
  setActivePayment (state, payment) {
    state.activePayment = payment || null;
  },
};

export const getters = {
  activePayment: (state) => {
    return state.activePayment;
  },
};

export const actions = {
  setActivePayment ({ commit }, payment) {
    commit('setActivePayment', payment);
  },
};
