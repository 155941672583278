
import { mapGetters, mapActions } from 'vuex';
import Dropdown from '~/components/ui/Dropdown.vue';
import CartDrawer from '~/components/cart/CartDrawer.vue';
import NotificationsDrawer from '~/components/notifications/NotificationsDrawer.vue';
import Icon from '~/components/ui/Icon.vue';
import { pageMixin, logoMixin } from '~/mixins/index.js';

export default {
  components: {
    Dropdown,
    CartDrawer,
    NotificationsDrawer,
    Icon,
  },
  mixins: [pageMixin, logoMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    showSidebar: {
      type: Boolean,
      default: true,
    },
    isPublicPage: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      themeImages: {
        light: '/svg/bg/icon-sun.svg',
        dark: '/svg/bg/icon-moon.svg',
        system: '/svg/bg/icon-system.svg',
      },
      icons: {
        menu: '/svg/icon-menu.svg',
        notification: '/svg/dashboard-header/icon-notification.svg',
        cart: '/svg/dashboard-header/icon-cart.svg',
      },
      cartDrawerIsOpen: false,
      notificationsDrawerIsOpen: false,
    };
  },
  computed: {
    timedGreetings () {
      const date = new Date();
      const hours = date.getHours();
      const periodOfTime = hours > 18 ? 'evening' : hours > 12 ? 'afternoon' : 'morning';
      return `Good ${periodOfTime}`;
    },
    profileLinks () {
      return [
        {
          title: 'Profile',
          href: '/settings/#personal-information',
        },
        {
          title: 'Sign out',
          href: '/auth/signin',
          divider: true,
          click: this.logoutUser,
        },
        {
          title: 'Need help?',
          classes: 'color-dark-blue',
          imgSrc: '/svg/icon-info.svg',
          click: this.openHelp,
        },
      ];
    },
    getInitials () {
      const { firstName, lastName } = this.loggedInUser;
      const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
      const lastLetter = lastName ? lastName.charAt(0).toUpperCase() : '';
      return `${firstLetter}${lastLetter}`;
    },
    getProfilePic () {
      return this.loggedInUser.imgUrl || '';
    },
    getFirstName () {
      return this.loggedInUser.firstName || 'Username';
    },
    unreadNotifications () {
      return this.recentNotifications.filter(notification => notification.isRead === false);
    },
    ...mapGetters({
      loggedInUser: 'auth/loggedInUser',
      authToken: 'auth/authToken',
      cartShipments: 'cart/cartShipments',
      recentNotifications: 'broadcast/recentNotifications',
    }),
  },
  mounted () {},
  created () {
    if (!this.isPublicPage) {
      this.fetchRecentNotifications();
    }
  },
  methods: {
    trackWhatsNew () {
      this.$segment.pages.whatsNew('Dashboard');
    },
    async logoutUser () {
      await this.logout().then(async () => {
        await this.$segment.tracks.signedOut();
        await this.$router.push('/auth/signin');
      });
    },
    ...mapActions({
      logout: 'auth/logout',
      fetchRecentNotifications: 'broadcast/fetchRecentNotifications',
      openPageSidebar: 'page/openPageSidebar',
      setCurrentTheme: 'page/setCurrentTheme',
    }),
  },
};
