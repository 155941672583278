const getServiceBasePath = version => `/notification/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  broadcasts: {
    broadcasts: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/broadcasts`;
      return $axios.get(urlPath, { headers, params });
    },
    read: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/broadcasts/read`;
      return $axios.put(urlPath, data, { headers });
    },
  },
  notifications: {
    notifications: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/notifications`;
      return $axios.get(urlPath, { headers, params });
    },
    notification: ({ id, headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/notifications/${id}`;
      return $axios.get(urlPath, { headers, params });
    },
    read: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/notifications/read`;
      return $axios.put(urlPath, data, { headers });
    },
    archive: ({ id, headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/notifications/${id}`;
      return $axios.delete(urlPath, data, { headers });
    },
  },
});
