
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({ setCommitHash: 'auth/setCommitHash' }),
    refreshPage () {
      this.setCommitHash(this.$config.BuildHash);
      window.location.reload();
    },
  },
};
