
export default {
  name: 'InputCheckboxMultiple',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    checkedValue: {
      type: [String, Object],
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateInput (e) {
      const newValue = e.target.checked
        ? [...this.value, this.checkedValue]
        : this.value.filter(val => val !== this.checkedValue);
      this.$emit('input', newValue);
    },
  },
};
