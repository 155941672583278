import { mapGetters, mapActions } from 'vuex';
import KYC_COUNTRY_OPTIONS from '~/assets/constants/KYC_COUNTRY_OPTIONS.js';
import KYC_STATUS from '~/assets/constants/KYC_STATUS.js';

const SMILE_IDENTITY_HEAD_SCRIPTS = [
  {
    type: 'application/javascript',
    src: 'https://cdn.smileidentity.com/inline/v1/js/script.min.js',
  },
  {
    type: 'application/javascript',
    src: 'https://cdn.smileidentity.com/js/v1.0.0-beta.7/smart-camera-web.js',
  },
];

const kycMixin = (loadScripts = false) => ({
  data () {
    return {
      token: '',
      kycCompletedOnSmile: false,
      smileIdentityIsReady: false,
      intervalId: null,
    };
  },
  computed: {
    idSelection () {
      if (this.loggedInUser.country && KYC_COUNTRY_OPTIONS[this.loggedInUser.country]) {
        return {
          [this.loggedInUser.country]: KYC_COUNTRY_OPTIONS[this.loggedInUser.country],
        };
      }
      return {};
    },
    userKycStatus () {
      return this.loggedInUser?.customerProfile?.identityVerificationStatus;
    },
    userKycIsApproved () {
      return this.userKycStatus === KYC_STATUS.APPROVED;
    },
    userKycIsInProgress () {
      return this.userKycStatus === KYC_STATUS.PENDING || this.userKycStatus === KYC_STATUS.INITIATED;
    },
    userKycIsRejected () {
      return this.userKycStatus === KYC_STATUS.REJECTED;
    },
    ...mapGetters({
      loggedInUser: 'auth/loggedInUser',
    }),
  },
  created () {
    this.intervalId = setInterval(() => {
      if (!this.userKycIsInProgress) {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
      }
      this.checkVerificationStatus();
    }, 5000);
  },
  mounted () {
    this.getKycToken();
    if (loadScripts) {
      // loop through SMILE_IDENTITY_HEAD_SCRIPTS and append to head
      SMILE_IDENTITY_HEAD_SCRIPTS.forEach((script) => {
        const head = document.getElementsByTagName('head')[0];
        const scriptElement = document.createElement('script');
        scriptElement.type = script.type;
        scriptElement.src = script.src;
        head.appendChild(scriptElement);
        // if scripts are loaded set this.smileIdentityIsReady = true;
        scriptElement.onload = () => {
          this.smileIdentityIsReady = true;
        };
      });
    }
  },
  methods: {
    ...mapActions({
      fetchUserProfile: 'auth/fetchUserProfile',
    }),
    initSession () {
      this.$segment.tracks.kycVerificationStarted();
      this.configureSmileIdentityWebIntegration(this.token);
    },
    configureSmileIdentityWebIntegration (token) {
      if (!window.SmileIdentity) {
        // eslint-disable-next-line no-console
        console.error('SmileIdentity is not defined');
        this.setNotification({
          type: 'error',
          message: 'An error occurred',
          dismissible: true,
        });
        return;
      }
      if (!token) {
        // eslint-disable-next-line no-console
        console.error('Token is not defined');
        this.setNotification({
          type: 'error',
          message: 'An error occurred',
          dismissible: true,
        });
        return;
      }
      const options = {
        token,
        product: 'enhanced_kyc',
        callback_url: this.$config.ApiUrl + '/iam/v1/public/callbacks/kyc/verify',
        environment: this.$config.AppEnv === 'production' ? 'live' : 'sandbox',
        partner_details: {
          partner_id: this.$config.SmilePartnerID?.toString(),
          name: 'Heroshe',
          logo_url: (this.$config.CdnUrl || '') + '/static/svg/logo/heroshe.svg',
          policy_url: this.LEGAL_PRIVACY_POLICY_URL,
          theme_color: '#f1c34e',
        },
        id_selection: this.idSelection,
        onSuccess: async () => {
          await this.reportKycInitiated();
          this.checkVerificationStatus();
          this.kycCompletedOnSmile = true;
          this.$segment.tracks.kycVerificationSubmitted();
        },
        onClose: () => {},
        onError: (error) => {
          this.setNotification({
            type: 'error',
            message: error?.message || 'Verification failed',
            dismissible: true,
          });
        },
      };
      window.SmileIdentity(options);
    },
    async getKycToken () {
      if (this.userKycIsApproved || this.userKycIsInProgress) {
        return;
      }
      if (this.token) {
        return;
      }
      try {
        const response = await this.$api.iam.accounts.getKycToken();
        this.token = response.data.payload.token;
      } catch (error) {
        this.handleServerError(error);
      }
    },
    async checkVerificationStatus () {
      await this.fetchUserProfile();
      setTimeout(() => {
        this.getKycToken();
      }, 1000);
    },
    // reports to api that customer has successfully completed KYC on Smile Identity Portal
    async reportKycInitiated () {
      try {
        await this.$api.iam.accounts.kycInitiated();
      } catch (error) {
        this.handleServerError(error);
      }
    },
  },
});

export default kycMixin;
