
export default {
  name: 'NewModal',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
      validator (val) {
        return ['', 'small', 'large', 'full', 'page'].includes(val);
      },
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    overflow: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      icons: {
        closeModal: '/svg/icon-close.svg',
      },
      sizeMap: {
        small: 'sm',
        large: 'lg',
        full: 'full',
        page: 'page',
      },
    };
  },
  methods: {
    closeModal () {
      this.$emit('close');
    },
  },
};
