const getServiceBasePath = version => `/iam/${version}/accounts`;

export default ($axios, $apiVersion = 'v1') => ({
  accounts: {
    signup: ({ data, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/signup`;
      return $axios.post(urlPath, data, { headers });
    },
    login: ({ data, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/signin`;
      return $axios.post(urlPath, data, { headers });
    },
    socialSignupGoogle: (data) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/signup/social/google`;
      return $axios.post(urlPath, data);
    },
    socialLoginGoogle: (data) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/signin/social/google`;
      return $axios.post(urlPath, data);
    },
    changePassword: ({ data, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/security/password/change`;
      return $axios.put(urlPath, data, { headers });
    },
    passwordResetRequest: ({ data, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/security/password/reset-request`;
      return $axios.post(urlPath, data, { headers });
    },
    passwordResetConfirm: ({ data, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/security/password/reset-confirm`;
      return $axios.post(urlPath, data, { headers });
    },
    emailVerificationTrigger: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/email/verify/trigger`;
      return $axios.get(urlPath, { headers, params });
    },
    emailVerificationConfirm: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/email/verify/confirm`;
      return $axios.get(urlPath, { headers, params });
    },
    me: ({ headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/me`;
      return $axios.get(urlPath, { headers });
    },
    list: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}`;
      return $axios.get(urlPath, { headers, params });
    },
    get: ({ idOrEmail, headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/${idOrEmail}`;
      $axios.get(urlPath, { headers, params });
    },
    updateProfile: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/me`;
      return $axios.put(urlPath, data, { headers });
    },
    updateAccountPreferences: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/preferences`;
      return $axios.put(urlPath, data, { headers });
    },
    onboardCustomer: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/onboard`;
      return $axios.put(urlPath, data, { headers });
    },
    deleteAccountWithReason: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/delete-request`;
      return $axios.post(urlPath, data, { headers });
    },
    deleteAccountConfirmation: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/me`;
      return $axios.delete(urlPath, data, { headers });
    },
    emailChangeRequest: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/security/email/change-request`;
      return $axios.put(urlPath, data, { headers });
    },
    emailChangeConfirm: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/security/email/change-confirm`;
      return $axios.post(urlPath, data, { headers });
    },
    createBusinessProfile: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/business`;
      return $axios.post(urlPath, data, { headers });
    },
    getKycToken: () => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/kyc/verification-token`;
      return $axios.get(urlPath);
    },
    kycInitiated: () => {
      const urlPath = `${getServiceBasePath($apiVersion)}/customer/kyc/initiate`;
      return $axios.put(urlPath);
    },
  },
});
