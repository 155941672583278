const getServiceBasePath = version => `/subscription/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  plans: {
    /**
     * Fetch All Plans
     * @param params
     * @returns {*}
     */
    list: ({ params }) => $axios.get(`${getServiceBasePath($apiVersion)}/public/plans`, { params }),
    /**
     * Fetch Single Plan Details
     * @param idOrCode
     * @param params
     * @returns {*}
     */
    get: ({ idOrCode, params }) => $axios.get(`${getServiceBasePath($apiVersion)}/plans/${idOrCode}`, { params }),
    /**
     * Get Actual Plan Quote Billing
     * @param idOrCode
     * @param params
     * @returns {*}
     */
    quote: ({ idOrCode, params }) =>
      $axios.get(`${getServiceBasePath($apiVersion)}/plans/${idOrCode}/quote`, { params }),
  },
  subscriptions: {
    /**
     * Fetch All Subscriptions
     * @param params
     * @returns {*}
     */
    list: ({ params }) => $axios.get(`${getServiceBasePath($apiVersion)}/subscriptions`, { params }),
    /**
     * Fetch Subscription Details
     * @param id
     * @param params
     * @returns {*}
     */
    get: ({ id, params }) => $axios.get(`${getServiceBasePath($apiVersion)}/subscriptions/${id}`, { params }),
    /**
     * Subscribe to Plan
     * @param data
     * @returns {*}
     */
    subscribe: ({ data }) => $axios.post(`${getServiceBasePath($apiVersion)}/subscriptions`, data),
    /**
     * Update Subscription
     * @param id
     * @param data
     * @returns {*}
     */
    manageBilling: ({ data }) => $axios.post(`${getServiceBasePath($apiVersion)}/subscriptions/management-link`, data),
  },
});
