
import { mapGetters } from 'vuex';
import FRESH_CHAT_WIDGET_UUID from '~/assets/constants/FRESH_CHAT_WIDGET_UUID.js';

export default {
  name: 'FreshChat',
  computed: {
    planName () {
      return this.loggedInUser?.customerProfile?.subscriptionInfo?.planName;
    },
    ...mapGetters({
      loggedInUser: 'auth/loggedInUser',
    }),
  },
  watch: {
    planName: {
      immediate: true,
      handler () {
        setTimeout(() => {
          this.reInitFreshChat(this.loggedInUser?.id);
        }, 2000);
        this.setFreshChatUserProperties();
      },
    },
  },
  mounted () {
    window.addEventListener('fc_widget_is_loaded', () => {
      setTimeout(() => {
        this.reInitFreshChat();
      }, 2000);
      this.setFreshChatUserProperties();
    });
  },
  methods: {
    reInitFreshChat (id = '') {
      if (typeof window.fcWidget !== 'undefined' && window.fcWidget) {
        window.fcWidget.destroy();
        setTimeout(() => {
          window.fcWidget.init({
            token: '89d06af8-61b3-465e-b49c-d8b931d7e674',
            CustomerID: id,
            host: 'https://heroshe.freshchat.com',
            externalId: id,
            widgetUuid:
              FRESH_CHAT_WIDGET_UUID[this.planName] ||
              FRESH_CHAT_WIDGET_UUID.DEFAULT,
          });
        }, 1000);
      }
    },
  },
};
