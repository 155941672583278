
import { mapActions, mapGetters } from 'vuex';
import NewModal from '@/components/ui/NewModal.vue';
import InputRadio from '@/components/inputs/InputRadio.vue';
import { surveyJan2023Object } from '@/assets/data/surveyJan2023.js';

export default {
  name: 'SurveyModal',
  components: {
    NewModal,
    InputRadio,
  },
  data () {
    return {
      showModal: true,
      submitting: false,
      questionNo: 1,
      questions: surveyJan2023Object,
      answers: {
        account_classification: '',
        origin_country: '',
        shipping_experience: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'auth/loggedInUser',
    }),
    surveyObject () {
      return this.loggedInUser.survey || {};
    },
    filteredQuestions () {
      const pendingIDs = Object.entries(this.surveyObject).filter(([key, value]) => value === 'PENDING');
      const surveyQuestions = {};
      pendingIDs.forEach(([key, value]) => (surveyQuestions[key] = this.questions[key]));
      return Object.values(surveyQuestions);
    },
  },
  methods: {
    ...mapActions({
      fetchUserProfile: 'auth/fetchUserProfile',
      setHasSkippedSurveyModal: 'auth/setHasSkippedSurveyModal',
    }),
    closeModal () {
      if (this.questionNo > this.filteredQuestions.length) {
        this.showModal = false;
      } else {
        this.setHasSkippedSurveyModal(true);
        this.showModal = false;
      }
    },
    async submitSurvey () {
      if (this.questionNo < this.filteredQuestions.length) {
        this.questionNo += 1;
      } else {
        try {
          const surveyAnswers = { ...this.answers };
          Object.entries(this.answers).forEach(([key, value]) => {
            if (value === '') {
              delete surveyAnswers[key];
            }
          });
          this.submitting = true;
          await this.$api.iam.accounts.onboardCustomer({ data: surveyAnswers });
          this.questionNo += 1;
          this.fetchUserProfile();
          setTimeout(() => {
            this.closeModal();
          }, 3000);
        } catch (error) {
          this.handleServerError(error);
        } finally {
          this.submitting = false;
        }
      }
    },
  },
};
