
export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: 'info',
      validator: value => ['success', 'error', 'info'].includes(value),
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: '',
    },
    customClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    alertClass () {
      return `alert--${this.type}`;
    },
  },
};
