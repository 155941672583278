import { mapActions, mapGetters } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators/index.js';
import BigNumber from 'bignumber.js';
import { cartMixin, facebookPixelMixin } from '~/mixins/index.js';
import SHIPPING_METHODS from '~/assets/constants/SHIPPING_METHODS.js';
import supportedOriginCountries from '~/assets/data/supportedOriginCountries.js';

const checkoutMixin = {
  mixins: [cartMixin, facebookPixelMixin],
  data () {
    return {
      checkingOut: false,
      lbsTokg: 0.45359237,
      changingDeliveryChoice: false,
      specialOffers: {
        BASIC: {
          deal: {
            title: 'Upgrade to Pro',
            description:
              'Get even more value for your money when you Upgrade to Pro for as low as $14/month this November.',
            amountOff: 40,
          },
          bannerText: 'Extra',
          url: '/upgrade',
        },
        PRO: {
          deal: {
            title: 'Upgrade to Premium',
            description:
              'Get even more value for your money when you Upgrade to Premium for as low as $26/month this November.',
            amountOff: 50,
          },
          bannerText: 'Extra',
          url: '/upgrade',
        },
      },
      icons: {
        emptyCart: '/svg/shipments/icon-empty-box.svg',
        timeline: '/svg/checkout/icon-timeline.svg',
        delivery: '/svg/checkout/icon-delivery.svg',
        pickup: '/svg/checkout/icon-pickup.svg',
        isPaidFeature: '/svg/checkout/icon-is-paid-feature.svg',
        selectShipmentAddress: '/svg/checkout/icon-select-shipment-address.svg',
        changeToPickup: '/svg/checkout/icon-change-to-pickup.svg',
        removeFromCart: '/svg/shipments/icon-remove-from-cart-3.svg',
      },
      showSelectAddressModal: false,
      showSelectMethodModal: false,
      insurance: {
        declaredShipmentsValue: 0,
        insuranceCost: {}, // amountObj
      },
      groupOrders: [],
      showSelectAddressModalForShipment: null,
      groupDeliveryAddress: {},
      checkedShipments: [],
      shipmentsToMoveToAddress: [], // shipment[]
      SHIPPING_METHODS,
      selectedCountry: null,
      discounts: {
        BASIC: {
          shipping: '8',
          delivery: '20',
        },
        PRO: {
          shipping: '6',
          delivery: '30',
        },
        PREMIUM: {
          shipping: '5',
          delivery: '40',
        },
      },
    };
  },
  head: {
    title: 'Heroshe - Shipments - Checkout',
  },
  validations: {
    deliveryChoice: {
      required,
    },
    deliveryAddress: {
      required: requiredIf(function () {
        return [
          this.DELIVERY_CHOICE_HOME,
          this.DELIVERY_CHOICE_PICKUP,
        ].includes(this.deliveryChoice);
      }),
    },
    shippingMethod: {
      required: requiredIf(function () {
        return [
          this.DELIVERY_CHOICE_HOME,
          this.DELIVERY_CHOICE_PICKUP,
        ].includes(this.shippingMethod);
      }),
    },
  },
  computed: {
    totalCheckoutWeight () {
      const shipmentsWeight = this.checkoutShipments.reduce((acc, shipment) => {
        let shipmentWeight = shipment.weight;
        if (shipment.weightUnit === 'lb') {
          shipmentWeight = shipment.weight * this.lbsTokg;
        }
        return acc + shipmentWeight;
      }, 0);
      return new BigNumber(shipmentsWeight).toPrecision(3);
    },
    planName () {
      return this.loggedInUser?.customerProfile?.subscriptionInfo?.planName;
    },
    isAirDisabled () {
      return this.hasApprovedFlag(this.checkoutShipments, this.SHIPMENT_FLAGS.OCEAN_ONLY.KEY);
    },
    allowedWarehouses () {
      return [supportedOriginCountries.US.defaultWarehouseCode, supportedOriginCountries.GB.defaultWarehouseCode, supportedOriginCountries.CN.defaultWarehouseCode];
    },
    comingSoonWarehouses () {
      return [];
    },
    oldShippingPrice () {
      const { totalWeight } = this.cartOrder;
      const discount = this.discounts[this.planName];
      if (discount && totalWeight) {
        const oldPrice = totalWeight * discount.shipping * 100;
        return this.getAmountInCurrency({
          amountObj: this.createAmountObj(oldPrice, this.CURRENCY.USD.value),
          currency: this.CURRENCY.USD.value,
        });
      }
      return '';
    },
    oldDeliveryPrice () {
      const { deliveryFeeAmount } = this.cartOrder;
      const discount = this.discounts[this.planName];
      if (discount && deliveryFeeAmount) {
        const oldPrice = Math.ceil(
          (100 / discount.delivery) * deliveryFeeAmount?.subUnit
        );
        return this.getAmountInCurrency({
          amountObj: this.createAmountObj(oldPrice, this.CURRENCY.USD.value),
          currency: this.CURRENCY.USD.value,
        });
      }
      return '';
    },
    shipmentToMoveToAddress () {
      return this.shipmentsToMoveToAddress[0] || null;
    },
    showDeliveryFee () {
      return this.getCharge(this.deliveryFeeAmountInDollar) > 0;
    },
    checkoutShipmentsWithoutAddressIsZero () {
      return this.getCheckoutShipmentsWithoutAddress().length === 0;
    },
    isGroupOrder () {
      return this.deliveryChoice === this.DELIVERY_CHOICE_GROUP_ORDER;
    },
    showOrderSummary () {
      if (this.isGroupOrder) {
        return this.checkoutShipmentsWithoutAddressIsZero;
      }
      return !!this.deliveryAddress;
    },
    breadcrumbs () {
      return [
        {
          text: 'Shipments',
          click: () => this.$router.push('/shipments'),
        },
        {
          text: 'Checkout',
        },
      ];
    },
    loggedInPlan () {
      return this.plans.find(plan => plan.name === this.loggedInUser?.customerProfile?.subscriptionInfo?.planName);
    },
    freightTitles () {
      return {
        [this.SHIPPING_METHODS.OCEAN_FREIGHT.key]: `Ocean Shipping ($${this.loggedInPlan?.features?.oceanPricePerKilogramUSToNG?.value?.display || 11}/kg)`,
        [this.SHIPPING_METHODS.AIR_FREIGHT.key]: `Air Shipping ($${this.loggedInPlan?.features?.pricePerKilogramUSToNG?.value?.display || 11}/kg)`,
      };
    },
    ...mapGetters({
      plans: 'plan/plansData',
      loggedInUser: 'auth/loggedInUser',
      supportedDestinationCountriesData:
        'addressbook/supportedDestinationCountriesData',
      isPaidSubscriber: 'auth/isPaidSubscriber',
      deliveryAddress: 'checkout/deliveryAddress',
      deliveryChoice: 'checkout/deliveryChoice',
      shippingMethod: 'checkout/shippingMethod',
      checkoutShipments: 'checkout/checkoutShipments',
    }),
  },
  watch: {
    checkoutShipments (newVal) {
      // remove shipments removed from cart from this.groupOrders
      const shipmentsIds = newVal.map(s => s.id);
      this.groupOrders = this.groupOrders.filter((o) => {
        return shipmentsIds.includes(o.shipment.id);
      });
    },
  },
  methods: {
    /**
     * Check a shipment array for a specified flag
     * @param shipments
     * @param flag
     * @returns {boolean}
     */
    hasApprovedFlag (shipments, flag) {
      const firstOceanOnlyShipment = shipments.find(
        shipment =>
          shipment?.flags?.[flag]
            ?.status === 'APPROVED'
      );
      return firstOceanOnlyShipment?.id !== undefined;
    },
    /**
     * Check if shipment warehouse is allowed for ocean shipping
     * @param shipments
     * @returns {{allowed: *[], forbidden: *[]}}
     */
    canOceanShip (shipments) {
      const allowed = [];
      const forbidden = [];
      shipments.forEach((shipment) => {
        if (this.allowedWarehouses.includes(shipment.originWarehouse)) {
          allowed.push({ ...shipment, shippingMethodError: null });
        } else {
          forbidden.push({
            ...shipment,
            shippingMethodError:
              'Sorry, Ocean Shipping is not currently supported from this location!',
          });
        }
      });
      return { allowed, forbidden };
    },
    /**
     * Check if shipment warehouse is coming soon for ocean shipping
     * @param shipments
     * @returns {{comingSoon: *[]}}
     */
    isComingSoon (shipments) {
      const comingSoon = [];
      shipments.forEach((shipment) => {
        if (this.comingSoonWarehouses.includes(shipment.originWarehouse)) {
          comingSoon.push({ ...shipment, shippingMethodError: null });
        }
      });
      return { comingSoon };
    },
    getCheckoutData ({ dryRun, address, shipments = [] } = {}) {
      shipments = shipments.length > 0 ? shipments : this.checkoutShipments;
      address = address || this.deliveryAddress;
      if (!address) {
        this.setNotification({
          type: 'error',
          message: 'Invalid address',
        });
        return;
      }

      const deliveryChoice = address.id
        ? this.DELIVERY_CHOICE_HOME
        : this.DELIVERY_CHOICE_PICKUP;
      const deliveryAddressFormatted = this.getFormattedAddress(address);
      const alpha2Code = address.code
        ? address.country?.code?.alpha2
        : undefined;

      return {
        dry_run: dryRun,
        items: shipments.map(shipment => ({
          shipment_id: shipment.id,
          delivery_choice: deliveryChoice,
          pickup_location_code:
            deliveryChoice === this.DELIVERY_CHOICE_PICKUP
              ? address.code
              : undefined,
          pickup_country:
            deliveryChoice === this.DELIVERY_CHOICE_PICKUP && alpha2Code
              ? alpha2Code
              : undefined,
          delivery_address:
            deliveryChoice === this.DELIVERY_CHOICE_HOME
              ? deliveryAddressFormatted
              : undefined,
          freight_mode: this.shippingMethod,
        })),
      };
    },
    getGroupCheckoutData ({ dryRun } = {}) {
      return {
        dry_run: dryRun,
        items: this.checkoutShipments.map((shipment) => {
          const { groupCheckout } = shipment;
          const alpha2Code = groupCheckout?.deliveryAddress?.code
            ? groupCheckout?.deliveryAddress?.country?.code?.alpha2
            : undefined;
          return {
            shipment_id: shipment.id,
            delivery_choice: groupCheckout?.deliveryChoice,
            pickup_location_code: groupCheckout?.deliveryAddress?.code
              ? groupCheckout?.deliveryAddress?.code
              : undefined,
            pickup_country: groupCheckout?.deliveryAddress?.code
              ? alpha2Code
              : groupCheckout?.deliveryAddress?.country,
            delivery_address: groupCheckout?.deliveryAddress?.id
              ? this.getFormattedAddress(groupCheckout?.deliveryAddress)
              : undefined,
            freight_mode: groupCheckout?.freightMode,
          };
        }),
      };
    },
    async moveShipmentsToAddress ({ address, shipments }) {
      await shipments.forEach((shipment) => {
        this.removeShipmentAddressInGroupOrders(shipment);
      });
      this.addShipmentsToAddress({ address, shipments });
    },
    addShipmentsToAddress ({ shipments, address }) {
      const addressShipmentArr = shipments.map((shipment) => {
        return {
          address,
          shipment,
        };
      });
      this.groupOrders = [...this.groupOrders, ...addressShipmentArr];
    },
    removeShipmentAddressInGroupOrders (shipment) {
      this.groupOrders = this.groupOrders.filter(
        o => o.shipment.id !== shipment.id
      );
    },
    getShipmentsWithoutMethod () {
      return this.checkoutShipments.filter(
        shipment => !shipment?.groupCheckout?.freightMode
      );
    },
    getShipmentsWithMethod (shipmentMethod) {
      return this.checkoutShipments.filter(
        shipment => shipment?.groupCheckout?.freightMode === shipmentMethod
      );
    },
    getCheckoutShipmentsWithoutAddress () {
      return this.checkoutShipments.filter(
        shipment => !shipment.groupCheckout?.deliveryAddress
      );
    },
    getShipmentAddress (shipment) {
      return this.groupOrders.find(o => o.shipment.id === shipment.id)
        ?.address;
    },
    async setDeliveryAddress (address) {
      const deliveryChoice =
        address && address.id
          ? this.DELIVERY_CHOICE_HOME
          : this.DELIVERY_CHOICE_PICKUP;
      await this.updateDeliveryAddress(address);
      await this.updateDeliveryChoice(deliveryChoice);
    },
    closeSelectAddressModal () {
      this.showSelectAddressModal = false;
    },
    async changeCartShipmentsDeliveryChoice ({ address, shipments }) {
      if (!this.isGroupOrder && !address) {
        throw new Error('Address is required');
      }
      try {
        this.changingDeliveryChoice = true;
        const { data } = await this.$api.ordering.orders.checkout({
          data: this.isGroupOrder
            ? this.getGroupCheckoutData({ dryRun: true })
            : this.getCheckoutData({
              dryRun: true,
              address,
              shipments,
            }),
        });
        await this.setCart(data);
        const { failed = {} } = data.payload;
        const numOfFailed = Object.keys(failed).length;
        if (numOfFailed > 0) {
          this.setNotification({
            type: 'error',
            message: `Failed to update ${this.pluralize(
              'shipment',
              numOfFailed,
              true
            )}`,
            dismissible: true,
          });
          await this.updateDeliveryChoice(null);
          await this.updateDeliveryAddress(null);
          return false;
        }
      } catch (error) {
        this.handleServerError(error);
        if (error.response.data) {
          await this.setCart(error.response.data);
        }
      } finally {
        this.changingDeliveryChoice = false;
      }
    },
    async checkout () {
      this.validateFormOnSubmit();
      if (this.$v.$error || this.changingDeliveryChoice) {
        return;
      }

      try {
        this.checkingOut = true;
        const { data } = await this.$api.ordering.orders.checkout({
          data:
            this.deliveryChoice === this.DELIVERY_CHOICE_GROUP_ORDER
              ? this.getGroupCheckoutData()
              : this.getCheckoutData(),
        });
        const order = data.payload.order || {};
        await this.setActiveOrder(order);
        await this.$router.replace(`/shipments/checkout/payment/${order.id}`);
        await this.clearCart();
        await this.clearCheckout();
        this.fbq.track.checkout();
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.checkingOut = false;
      }
    },
    async selectGroupOrderChoice () {
      if (this.isPaidSubscriber) {
        await this.updateDeliveryChoice(this.DELIVERY_CHOICE_GROUP_ORDER);
        return;
      }
      await this.$router.push('/upgrade');
    },
    ...mapActions({
      updateDeliveryAddress: 'checkout/updateDeliveryAddress',
      updateDeliveryChoice: 'checkout/updateDeliveryChoice',
      addShipmentsToCheckout: 'checkout/addShipmentsToCheckout',
      setCheckoutOrder: 'checkout/setCheckoutOrder',
      removeShipmentsFromCheckout: 'checkout/removeShipmentsFromCheckout',
      setCheckoutShipments: 'checkout/setCheckoutShipments',
      restoreCheckoutFromStorage: 'checkout/restoreCheckoutFromStorage',
      clearCheckout: 'checkout/clearCheckout',
    }),
  },
};

export default checkoutMixin;
