
import { mapGetters, mapActions } from 'vuex';
// import FEATURE_FLAG from '~/assets/constants/FEATURE_FLAG.js';
import { pageMixin, logoMixin, onboardingMixin } from '~/mixins/index.js';
import TagNew from '~/components/ui/TagNew.vue';

export default {
  components: { TagNew },
  mixins: [pageMixin, logoMixin, onboardingMixin],
  data () {
    return {
      themeImages: {
        light: '/svg/bg/icon-sun.svg',
        dark: '/svg/bg/icon-moon.svg',
        system: '/svg/bg/icon-system.svg',
      },
      proFeatureIcon: '/svg/dashboard-sidebar/icon-is-paid-feature.svg',
    };
  },
  computed: {
    ...mapGetters({
      appVersion: 'auth/appVersion',
      loggedInUser: 'auth/loggedInUser',
      isPaidSubscriber: 'auth/isPaidSubscriber',
      preferredCurrency: 'page/preferredCurrency',
      darkMode: 'page/darkMode',
    }),
    copyright () {
      return `© Heroshe ${new Date().getFullYear()}`;
    },
    getUserName () {
      return this.loggedInUser.firstName;
    },
    mainNavLinks () {
      const mainNavLinks = [];
      if (this.onboardingIsIncomplete) {
        mainNavLinks.push({
          title: 'Start here',
          link: '/start-here',
          icon: 'icon-start-here.svg',
          isPaidFeature: false,
        });
      }
      mainNavLinks.push({
        title: 'Dashboard',
        link: '/dashboard',
        icon: 'icon-dashboard.svg',
        isPaidFeature: false,
      });
      mainNavLinks.push({
        title: 'Buy For Me',
        link: '/buyforme',
        icon: 'icon-basket-smile.svg',
        isPaidFeature: false,
        isNew: true
      });

      return [
        ...mainNavLinks,
        {
          title: 'Wallet',
          link: '/wallet',
          icon: 'icon-wallet.svg',
          isPaidFeature: false,
        },
        {
          title: 'Shipments',
          link: '/shipments',
          icon: 'icon-shipment.svg',
          isPaidFeature: false,
        },
        {
          title: 'Orders',
          link: '/orders',
          icon: 'icon-orders.svg',
          isPaidFeature: false,
        },
        {
          title: 'Payments',
          link: '/payments',
          icon: 'icon-payments.svg',
          isPaidFeature: false,
        },
        {
          title: 'Pickups',
          link: '/pickups',
          icon: 'icon-pickups.svg',
          isPaidFeature: false,
        },
        // {
        //   title: 'Delivery',
        //   link: '/delivery',
        //   icon: 'icon-delivery.svg',
        //   isFeatureFlagged: true,
        //   featureFlagEnabled: this.LD_FF_STATE[FEATURE_FLAG.DELIVERY],
        //   isPaidFeature: false,
        // },
        {
          title: 'Promotions',
          link: '/promotions',
          icon: 'icon-promotions.svg',
          isPaidFeature: false,
        },
        {
          title: 'Address Book',
          link: '/address-book',
          icon: 'icon-address-book.svg',
          isPaidFeature: true,
        },
        {
          title: 'Labelling',
          link: '/labelling',
          icon: 'icon-branding.svg',
          isPaidFeature: true,
        },
      ];
    },
    resourceLinks () {
      const secondaryNavLinks = [];
      if (this.$config.AppEnv !== 'production') {
        secondaryNavLinks.push({
          title: 'Components',
          href: '/components',
        });
      }

      return [
        {
          title: 'Settings',
          href: '/settings',
        },
        {
          title: 'Manage Subscription',
          href: '/subscription',
        },
        {
          title: 'Support',
          href: 'https://heroshe.com/faq',
          external: true,
          click: this.openHelp,
        },
        {
          title: 'Resources',
          href: this.HELP_CENTER_URL,
          external: true,
        },
        ...secondaryNavLinks,
      ];
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      setPreferredCurrency: 'page/setPreferredCurrency',
      closePageSidebar: 'page/closePageSidebar',
      setDarkMode: 'page/setDarkMode',
    }),
    isActiveMainNav (nav) {
      if (nav.matchLinks) {
        return nav.matchLinks.some(url => this.$route.path.includes(url));
      }
      return this.$route.path.includes(nav.link);
    },
    getIconStyle (iconFileName, reduceSize = false) {
      const iconPath = '/svg/dashboard-sidebar/';
      const iconUrl = iconPath + iconFileName;
      const style = `background-image: url('${
        this.parseStaticUrl(iconUrl) || ''
      }');`;
      if (reduceSize) {
        return `${style} background-size: 70%`;
      }
      return style;
    },
    async logoutUser () {
      await this.logout().then(() => {
        this.$router.push('/auth/signin');
      });
    },
  },
};
