import API_SERVICE from './API_SERVICE.js';

const AVAILABLE_CONFIG_KEY = {
  [API_SERVICE.delivery]: {
    pickup_duration_in_minutes: 'pickup_duration_in_minutes',
    pickup_same_day_grace_period_in_hours: 'pickup_same_day_grace_period_in_hours',
  },
  [API_SERVICE.ordering]: {
    naira_to_dollar_exchange_rate: 'naira_to_dollar_exchange_rate',
    post_address_limit: 'post_address_limit',
    post_split_pay_limit: 'post_split_pay_limit',
    post_referral_bonus: 'post_referral_bonus',
    storage_cost_per_day: 'storage_cost_per_day',
  },
  [API_SERVICE.shipping]: {
    next_ocean_shipping_date: 'next_ocean_shipping_date',
  },
};

export default AVAILABLE_CONFIG_KEY;
