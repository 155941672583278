
import { mapGetters, mapActions } from 'vuex';
import ACCOUNT_STATUS from '~/assets/constants/ACCOUNT_STATUS.js';
import OfflineNotice from '~/components/layout/OfflineNotice.vue';
import RefreshNotice from '~/components/layout/RefreshNotice.vue';
import FreshChatScript from '~/components/chat/FreshChatScript.vue';
import DashboardHeader from '~/components/layout/DashboardHeader.vue';
import DashboardSidebar from '~/components/layout/DashboardSidebar.vue';
import SurveyModal from '~/components/onboarding/SurveyModal.vue';

export default {
  components: {
    DashboardHeader,
    DashboardSidebar,
    FreshChatScript,
    OfflineNotice,
    RefreshNotice,
    SurveyModal,
  },
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    showSidebar: {
      type: Boolean,
      default: true,
    },
    isPublicPage: {
      type: Boolean,
      default: false,
    },
    shipment: {
      type: Object,
      default: () => ({}),
    },
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      ACCOUNT_STATUS,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'auth/loggedInUser',
      pageTitle: 'page/pageTitle',
      showPageSidebar: 'page/showPageSidebar',
      refresh: 'auth/refresh',
      hasSkippedSurveyModal: 'auth/hasSkippedSurveyModal',
    }),
    pendingSurveyQuestions () {
      const surveyOptions = this.loggedInUser.survey || {};
      return Object.entries(surveyOptions).filter(([key, value]) => value === 'PENDING');
    },
  },
  created () {
    if (!this.isPublicPage) {
      this.gotoOnboardingIfNotOnboard();
    }
  },
  mounted () {
    this.setFreshChatUserProperties();
  },
  methods: {
    gotoOnboardingIfNotOnboard () {
      if (this.loggedInUser.status === this.ACCOUNT_STATUS.ONBOARDING) {
        return this.$router.push('/onboarding');
      }
    },
    ...mapActions({
      togglePageSidebar: 'page/togglePageSidebar',
      closePageSidebar: 'page/closePageSidebar',
    }),
  },
};
