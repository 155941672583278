
import { cartMixin } from '~/mixins/index.js';
import CartShipment from '~/components/shipments/CartShipment.vue';
import Drawer from '~/components/ui/Drawer.vue';
import EmptyState from '~/components/ui/EmptyState.vue';

export default {
  name: 'CartDrawer',
  components: {
    CartShipment,
    Drawer,
    EmptyState,
  },
  mixins: [cartMixin],
  data () {
    return {
      icons: {
        emptyCart: '/svg/shipments/icon-empty-box.svg',
      },
    };
  },
  computed: {
    shippingFee () {
      return this.shippingFeeAmountInDollarWithSymbol;
    },
  },
  mounted () {
    this.$segment.pages.cart('Dashboard');
  },
  methods: {
    closeCartDrawer () {
      this.$emit('close');
    },
    async clearCartAndCloseDrawer () {
      this.clearCart();
      await this.$segment.tracks.clearCart();
      this.closeCartDrawer();
    },
  },
};
