import Vue from 'vue';

import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';

Vue.prototype.$eventBus = new Vue();

Vue.prototype.$monthLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
Vue.prototype.$monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

// usage/registration
Vue.use(Vuelidate);
Vue.component('VSelect', vSelect);
