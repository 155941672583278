import { mapGetters, mapActions } from 'vuex';

const addressMixin = {
  data () {
    return {
      addressId: '',
      fetchingAddress: false,
      address: {},
    };
  },
  computed: {
    ...mapGetters({
      activeAddress: 'addressbook/activeAddress',
    }),
  },
  created () {
    if (this.activeAddress) {
      this.address = this.activeAddress;
    }
  },
  methods: {
    ...mapActions({
      setActiveAddress: 'addressbook/setActiveAddress',
    }),
    async fetchAddress (addressId) {
      if (!this.activeAddress) {
        this.fetchingAddress = true;
      }
      try {
        const { data } = await this.$api.delivery.addressBook.get({ id: addressId });
        this.address = data?.payload.address || {};
      } catch (error) {
        this.handleServerError(error);
        this.$router.push('/address-book');
      } finally {
        this.fetchingAddress = false;
      }
    },
  },
};

export default addressMixin;
