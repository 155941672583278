import { mapActions, mapGetters } from 'vuex';
import { HERO_TOUR } from '~/assets/constants/HERO_TOUR.js';

const heroTourMixin = {
  data () {
    return {
      tourSection: '',
    };
  },
  computed: {
    ...mapGetters({
      heroTour: 'promotion/heroTour',
    }),
    heroTourSection () {
      return (this.tourSection && { [this.tourSection]: HERO_TOUR[this.tourSection] }) || {};
    },
  },
  mounted () {
    if (this.heroTour[this.tourSection]) {
      this.$refs.heroTourModal.toggleVisibility();
    }
  },
  methods: {
    ...mapActions({
      setPromotion: 'promotion/setPromotion',
    }),
    async closeHeroTour () {
      await this.setPromotion({
        promotion: { key: 'heroTour', value: { ...this.heroTour, [this.tourSection]: false } },
      });
    },
  },
};

export default heroTourMixin;
