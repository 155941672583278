export const state = () => ({});

export const mutations = {};

export const getters = {};

export const actions = {
  async nuxtServerInit ({ commit, dispatch }, { req, redirect, res }) {
    // Add X-Frame-Options to page response headers
    res.setHeader('X-Frame-Options', 'SAMEORIGIN');

    if (req.headers.cookie) {
      const parsed = this.$cookies.getAll();
      const {
        post_token: token,
        locale,
        acceptCookies,
        post_appName: appName,
        preferredCurrency,
        preferredTimezone,
        darkMode,
        allowBetaFeatures,
        currentTheme,
        promotions,
        commitHash,
        hasSkippedSurveyModal,
      } = parsed;

      if (token) {
        try {
          commit('auth/setToken', token);
          await dispatch('auth/fetchUserProfile');
          dispatch('shipment/fetchCustomShippingLabel');
        } catch (error) {
          dispatch('auth/logout');
          redirect('/auth/signin');
        }
      }
      dispatch('auth/validateAppVersion', commitHash);

      commit('auth/setVersion', this.$config.DeploymentVersion);

      if (locale) {
        commit('auth/setLocale', locale);
      }

      if (appName) {
        commit('auth/setAppName', appName);
      }

      if (acceptCookies) {
        commit('auth/setAcceptCookies', acceptCookies);
      }

      if (preferredCurrency) {
        commit('page/setPreferredCurrency', preferredCurrency);
      }

      if (preferredTimezone) {
        commit('page/setPreferredTimezone', preferredTimezone);
      }

      if (allowBetaFeatures) {
        commit('page/setAllowBetaFeatures', allowBetaFeatures);
      }

      if (darkMode) {
        commit('page/setDarkMode', darkMode);
      }

      // if (openHowItWorksMobileList) {
      //   commit('promotion/setOpenHowItWorksMobileList', openHowItWorksMobileList);
      // }

      if (currentTheme) {
        commit('page/setCurrentTheme', currentTheme);
      }

      if (promotions) {
        commit('promotion/setPromotions', promotions);
      }

      if (hasSkippedSurveyModal) {
        commit('auth/setHasSkippedSurveyModal', hasSkippedSurveyModal);
      }
    }
  },
};
