import AVAILABLE_CONFIG_KEY from './AVAILABLE_CONFIG_KEY.js';

const POST_VS_PRO_CONFIG_KEY = {
  POST: {
    REFERRAL_BONUS: AVAILABLE_CONFIG_KEY.ordering.post_referral_bonus,
  },
  PRO: {
    REFERRAL_BONUS: '$5',
  },
};

export default POST_VS_PRO_CONFIG_KEY;
