
import { mapGetters, mapActions } from 'vuex';
import Alert from '~/components/ui/Alert.vue';
import Toast from '~/components/ui/Toast.vue';
import ToastContainer from '~/components/ui/ToastContainer.vue';
import { pageMixin } from '~/mixins/index.js';

export default {
  components: {
    Alert,
    Toast,
    ToastContainer,
  },
  mixins: [pageMixin],
  data () {
    return {
      WebUrl: this.$config.WebUrl,
      showPage: false,
    };
  },
  computed: {
    ...mapGetters({
      notification: 'notification/notification',
    }),
  },
  created () {
    const configEntriesInStoreIsEmpty = this.configEntries.length === 0;
    if (configEntriesInStoreIsEmpty) {
      this.fetchConfigEntries();
    }
  },
  async mounted () {
    await this.$ldClient.waitForInitialization();

    // delay page render to avoid flickering
    setTimeout(() => {
      this.showPage = true;
    }, 1);
  },
  methods: {
    ...mapActions({
      clearNotification: 'notification/clearNotification',
      fetchConfigEntries: 'config/fetchConfigEntries',
    }),
  },
};
