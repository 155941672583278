export const state = () => ({
  activeSettingsTab: 'PersonalInformation',
});

export const mutations = {
  setActiveSettingsTab (state, tab) {
    state.activeSettingsTab = tab;
  },
};

export const getters = {
  activeSettingsTab: (state) => {
    return state.activeSettingsTab;
  },
};

export const actions = {
  setActiveSettingsTab ({ commit }, tab) {
    commit('setActiveSettingsTab', tab);
  },
};
