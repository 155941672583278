export const promotionsMixin = {
  data () {
    return {
      fetchingVouchers: false,
      vouchers: [],
      paginationData: {},
    };
  },
  watch: {
    '$route.query' (query) {
      this.setFilters(query);
      const queryParams = this.getQueryParams(query);
      this.fetchShipments(queryParams);
    },
  },
  methods: {
    getQueryParams (query = {}) {
      return {
        query: query.s || undefined,
        status: query.status || undefined,
        page: +query.page > 1 ? +query.page : undefined,
        per_page: query.per_page ? +query.per_page : this.PER_PAGE,
      };
    },
    async fetchVouchers (queryParams = {}) {
      this.fetchingVouchers = true;
      try {
        const { data } = await this.$api.payment.vouchers.list({ params: queryParams });
        this.vouchers = data?.payload?.vouchers || [];
        this.paginationData = data?.payload?.meta || {};
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.fetchingVouchers = false;
      }
    },
  },
};
