import Vue from 'vue';
import pluralize from 'pluralize';
import BigNumber from 'bignumber.js';
import { capitalize, humanize, titleize } from 'underscore.string';
import { mapGetters, mapActions } from 'vuex';
import { validationMessage } from 'vuelidate-messages';
import { countries as countriesData } from 'country-data-list';
import jwtDecode from 'jwt-decode';
import { formValidationMessages } from '~/plugins/form-validation/validation-messages.js';
import PAGINATION from '~/assets/constants/PAGINATION.js';
import DELIVERY_CHOICE from '~/assets/constants/DELIVERY_CHOICE.js';
import SHIPMENT_STATUS from '~/assets/constants/SHIPMENT_STATUS.js';
import SHIPMENT_PAYMENT_STATUS from '~/assets/constants/SHIPMENT_PAYMENT_STATUS.js';
import ORDER_STATUS from '~/assets/constants/ORDER_STATUS.js';
import SHIPPING_METHODS from '~/assets/constants/SHIPPING_METHODS.js';
import PAYMENT_STATUS from '~/assets/constants/PAYMENT_STATUS.js';
import PAYMENT_TAG from '~/assets/constants/PAYMENT_TAG.js';
import PAYMENT_PROCESSOR from '~/assets/constants/PAYMENT_PROCESSOR.js';
import PAYMENT_METHOD from '~/assets/constants/PAYMENT_METHOD.js';
import PICKUP_STATUS from '~/assets/constants/PICKUP_STATUS.js';
import VOUCHER_TYPE from '~/assets/constants/VOUCHER_TYPE.js';
import FEATURE_FLAG from '~/assets/constants/FEATURE_FLAG.js';
import AVAILABLE_CONFIG_KEY from '~/assets/constants/AVAILABLE_CONFIG_KEY.js';
import API_SETTINGS_CONFIG_KEY from '~/assets/constants/API_SETTINGS_CONFIG_KEY.js';
import POST_VS_PRO_CONFIG_KEY from '~/assets/constants/POST_VS_PRO_CONFIG_KEY.js';
import SHORTENED_EMAIL_QUERIES from '~/assets/constants/SHORTENED_EMAIL_QUERIES.js';
import CURRENCY from '~/assets/constants/CURRENCY.js';
import supportedDestinationCountries from '~/assets/data/supportedDestinationCountries.js';

Vue.mixin({
  data () {
    return {
      FEATURE_FLAG, // feature flags
      PER_PAGE: PAGINATION.PER_PAGE.DEFAULT,
      shippingMethodsOptions: SHIPPING_METHODS,
      DELIVERY_CHOICE_GROUP_ORDER: DELIVERY_CHOICE.GROUP_ORDER.value,
      DELIVERY_CHOICE_PICKUP: DELIVERY_CHOICE.PICKUP.value,
      DELIVERY_CHOICE_HOME: DELIVERY_CHOICE.HOME.value,
      DELIVERY_CHOICE_HOME_LAGOS: DELIVERY_CHOICE.HOME.subtypes.HOME_LAGOS,
      DELIVERY_CHOICE_HOME_NON_LAGOS:
        DELIVERY_CHOICE.HOME.subtypes.HOME_NON_LAGOS,
      SHIPMENT_STATUS_EXPECTED: SHIPMENT_STATUS.EXPECTED,
      SHIPMENT_STATUS_RECEIVED: SHIPMENT_STATUS.RECEIVED,
      SHIPMENT_STATUS_PROCESSED: SHIPMENT_STATUS.PROCESSED,
      SHIPMENT_STATUS_PACKED: SHIPMENT_STATUS.PACKED,
      SHIPMENT_STATUS_DROPPED_OFF: SHIPMENT_STATUS['DROPPED-OFF'],
      SHIPMENT_STATUS_SHIPPED: SHIPMENT_STATUS.SHIPPED,
      SHIPMENT_STATUS_CLEARANCE: SHIPMENT_STATUS.CLEARANCE,
      SHIPMENT_STATUS_SORTED: SHIPMENT_STATUS.SORTED,
      SHIPMENT_STATUS_PICKUP: SHIPMENT_STATUS.PICKUP,
      SHIPMENT_STATUS_PICKED_UP: SHIPMENT_STATUS['PICKED-UP'],
      SHIPMENT_STATUS_DISPATCHED: SHIPMENT_STATUS.DISPATCHED,
      SHIPMENT_STATUS_DELIVERED: SHIPMENT_STATUS.DELIVERED,
      SHIPMENT_PAYMENT_STATUS_UNPAID: SHIPMENT_PAYMENT_STATUS.UNPAID,
      SHIPMENT_PAYMENT_STATUS_PAID: SHIPMENT_PAYMENT_STATUS.PAID,
      ORDER_STATUS_UNPAID: ORDER_STATUS.UNPAID,
      ORDER_STATUS_PAID: ORDER_STATUS.PAID,
      PAYMENT_STATUS_SUCCESSFUL: PAYMENT_STATUS.SUCCESSFUL,
      PAYMENT_STATUS_REFUNDED: PAYMENT_STATUS.REFUNDED,
      PAYMENT_STATUS_FAILED: PAYMENT_STATUS.FAILED,
      PAYMENT_TAG_SUBSCRIPTION: PAYMENT_TAG.SUBSCRIPTION,
      PAYMENT_TAG_FUND_WALLET: PAYMENT_TAG.FUND_WALLET,
      PAYMENT_TAG_ORDER: PAYMENT_TAG.ORDER,
      PAYMENT_PROCESSOR_WALLET: PAYMENT_PROCESSOR.WALLET,
      PAYMENT_PROCESSOR_PAYSTACK: PAYMENT_PROCESSOR.PAYSTACK,
      PAYMENT_PROCESSOR_STRIPE: PAYMENT_PROCESSOR.STRIPE,
      PAYMENT_PROCESSOR_PAYPAL: PAYMENT_PROCESSOR.PAYPAL,
      PAYMENT_PROCESSOR_VOUCHER: PAYMENT_PROCESSOR.VOUCHER,
      PAYMENT_METHOD_SPLIT_PAY: PAYMENT_METHOD.SPLIT_PAY,
      PAYMENT_METHOD_PAY_LATER: PAYMENT_METHOD.PAY_LATER,
      CONTACT_EMAIL: 'support@heroshe.com',
      WEIGHT_CLASS_URL:
        'https://blog.heroshe.com/what-is-volumetric-weight-and-how-can-you-calculate-it',
      ABOUT_US_URL: 'https://heroshe.com/about',
      OCEAN_SHIPPING_BLOG_URL:
      'https://blog.heroshe.com/how-ocean-shipping-saves-you-money/',
      CHINA_RESTRICTION_BLOG_URL:
      'https://blog.heroshe.com/ship-from-china-with-heroshe/',
      MARKETING_EVENT_URL:
      'https://form.typeform.com/to/CbFIeRWI',
      LEGAL_TERMS_URL: 'https://heroshe.com/legal/terms',
      LEGAL_PRIVACY_POLICY_URL: 'https://heroshe.com/legal/privacy-policy',
      VIDEO_CHANNEL_URL: 'https://vimeo.com/showcase/8774408',
      HELP_CENTER_URL: 'https://heroshe.com/help-center',
      PICKUP_TIME_SLOT_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
      PICKUP_STATUS_OPEN: PICKUP_STATUS.OPEN,
      PICKUP_STATUS_BOOKED: PICKUP_STATUS.BOOKED,
      PICKUP_STATUS_COMPLETED: PICKUP_STATUS.COMPLETED,
      VOUCHER_TYPE_COUPON: VOUCHER_TYPE.COUPON,
      VOUCHER_TYPE_DISCOUNT: VOUCHER_TYPE.DISCOUNT,
      SHORTENED_EMAIL_QUERIES,
      ACTION_TYPE: {
        AND: 'AND',
        OR: 'OR',
      },
    };
  },
  computed: {
    LD_FF_STATE () {
      return this.$ldClient.allFlags();
    },
    CURRENCY () {
      return CURRENCY;
    },
    countriesOptions () {
      return countriesData.all.map(({ name, alpha2, emoji, flagIconUrl }) => ({
        label: `${emoji ? emoji + ' ' : ''}${name}`,
        value: alpha2,
        icon: flagIconUrl,
      }));
    },
    featureFlaggedDestinationCountries () {
      const countries = [];
      if (!this.LD_FF_STATE[FEATURE_FLAG.GHANA_OPERATIONS]) {
        countries.push(supportedDestinationCountries.GH.alpha2);
      }
      return countries;
    },
    IMAGEKIT_URL () {
      return this.$config.ImagekitUrl;
    },
    SHIPMENT_FLAGS () {
      const {
        DISPUTE,
        UPLOAD_POP,
        OCEAN_ONLY,
        RETURN_TO_SELLER,
        TRASH_SHIPMENT,
        SHIP_AS_IS,
        SHIP_VIA_OCEAN,
        CRATE_SHIPMENT,
        ADD_SOFT_PROTECTION,
      } = this.SHIPMENT_FLAG_ACTIONS;
      const possibleActions = [
        UPLOAD_POP.KEY,
        OCEAN_ONLY.KEY,
        CRATE_SHIPMENT.KEY,
        SHIP_AS_IS.KEY,
        RETURN_TO_SELLER.KEY,
        TRASH_SHIPMENT.KEY,
        DISPUTE.KEY,
        SHIP_VIA_OCEAN.KEY
      ];
      return {
        REQUIRES_POP: {
          KEY: 'REQUIRES_POP',
          NAME: 'Requires Proof of Purchase',
          ACTIONS: [UPLOAD_POP.KEY, RETURN_TO_SELLER.KEY],
          ALERTS: {},
        },
        OCEAN_ONLY: {
          KEY: 'OCEAN_ONLY',
          NAME: 'Ocean Shipping only',
          ACTIONS: [SHIP_VIA_OCEAN.KEY, RETURN_TO_SELLER.KEY, TRASH_SHIPMENT.KEY],
          ALERTS: {},
        },
        EMPTY_BOX: {
          KEY: 'EMPTY_BOX',
          NAME: 'Empty Shipment',
          ACTIONS: [SHIP_AS_IS.KEY, DISPUTE.KEY, TRASH_SHIPMENT.KEY],
          ALERTS: {
            [SHIP_AS_IS.KEY]:
              "By clicking 'Ship as is', you acknowledge and understand that the shipment will only contain an empty box with no other items enclosed.",
          },
        },
        DANGEROUS: {
          KEY: 'DANGEROUS',
          NAME: 'Dangerous Shipment',
          ACTIONS: [RETURN_TO_SELLER.KEY, TRASH_SHIPMENT.KEY],
          ALERTS: {},
        },
        DAMAGED: {
          KEY: 'DAMAGED',
          NAME: 'Damaged Shipment',
          ACTIONS: possibleActions,
          ALERTS: {},
        },
        FRAGILE_OR_BULKY: {
          KEY: 'FRAGILE_OR_BULKY',
          NAME: 'Bulky or Fragile Shipment',
          ACTIONS: [
            CRATE_SHIPMENT.KEY,
            ADD_SOFT_PROTECTION.KEY,
            SHIP_AS_IS.KEY,
          ],
          ALERTS: {
            [SHIP_AS_IS.KEY]:
              'Are you sure you want to ship this shipment as is? We will not be held liable for any damage!',
          },
        },
      };
    },
    SHIPMENT_FLAG_ACTIONS () {
      return {
        UPLOAD_POP: {
          KEY: 'UPLOAD_POP',
          NAME: 'Proof of purchase',
          DESCRIPTION:
            'Your shipment requires a proof of purchase for US Customs',
          ALERT: '',
          TYPE: this.ACTION_TYPE.AND,
        },
        OCEAN_ONLY: {
          KEY: 'OCEAN_ONLY',
          NAME: 'Ocean Shipping only',
          DESCRIPTION:
                   'Your shipment can only be shipped using ocean shipping',
          ALERT: '',
          TYPE: this.ACTION_TYPE.AND,
        },
        CRATE_SHIPMENT: {
          KEY: 'CRATE_SHIPMENT',
          NAME: 'Crate before shipment (Recommended)',
          DESCRIPTION:
            'Your shipment will be secured in a wooden crate before shipping. This is recommended for bulky or fragile shipments',
          ALERT: '',
          TYPE: this.ACTION_TYPE.OR,
          classKey: 'crateSizeClass',
        },
        SHIP_VIA_OCEAN: {
          KEY: 'SHIP_AS_IS',
          NAME: 'Ship via Ocean',
          DESCRIPTION: 'Your shipment will be shipped via Ocean Shipping',
          ALERT: '',
          TYPE: this.ACTION_TYPE.OR,
        },
        SHIP_AS_IS: {
          KEY: 'SHIP_AS_IS',
          NAME: 'Ship as is',
          DESCRIPTION: 'Your shipment will be shipped as is',
          ALERT:
            'Are you sure you want to ship this shipment as is? We will not be held liable for any damage!',
          TYPE: this.ACTION_TYPE.OR,
        },
        ADD_SOFT_PROTECTION: {
          KEY: 'ADD_SOFT_PROTECTION',
          NAME: 'Add soft protection',
          DESCRIPTION:
            "Adding soft protection doesn't guarantee that there will be no damage. We won’t be liable for any damages during shipping.",
          ALERT:
            "Adding soft protection doesn't guarantee that there will be no damage. We won’t be liable for any damages during shipping.",
          TYPE: this.ACTION_TYPE.OR,
          classKey: 'softProtectionSizeClass',
        },
        RETURN_TO_SELLER: {
          KEY: 'RETURN_TO_SELLER',
          NAME: 'Return label',
          DESCRIPTION: 'We will return the shipment to the seller for you',
          ALERT: '',
          TYPE: this.ACTION_TYPE.OR,
        },
        TRASH_SHIPMENT: {
          KEY: 'TRASH_SHIPMENT',
          NAME: 'Trash Shipment',
          DESCRIPTION: 'We will trash and discard the shipment for you',
          ALERT:
            'Are you sure you want to trash this shipment? This action can not be undone!',
          TYPE: this.ACTION_TYPE.OR,
        },
        DISPUTE: {
          KEY: 'DISPUTE',
          NAME: 'Dispute',
          DESCRIPTION: 'You can dispute this shipment',
          ALERT: '',
          TYPE: this.ACTION_TYPE.OR,
        },
      };
    },
    FILTERABLE_SHIPMENT_STATUS_OPTIONS () {
      return [
        // {
        //   label: 'All Statuses',
        //   value: '',
        // },
        ...this.shipmentStatusesInStore.map(status => ({
          label: capitalize(humanize(status)),
          value: status.toLowerCase(),
        })),
      ];
    },
    usAddress () {
      return this.usWarehouseAddressData[0] || {};
    },
    usContactNumber () {
      return this.usContactNumbersData[0]
        ? this.usContactNumbersData[0].phone_number
        : '';
    },
    gbAddress () {
      return this.gbWarehouseAddressData[0] || {};
    },
    gbContactNumber () {
      return this.gbContactNumbersData[0]
        ? this.gbContactNumbersData[0].phone_number
        : '';
    },
    cnAddress () {
      return this.cnWarehouseAddressData[0] || {};
    },
    cnContactNumber () {
      return this.cnContactNumbersData[0]
        ? this.cnContactNumbersData[0].phone_number
        : '';
    },
    PICKUP_DURATION_IN_MINUTES () {
      return (
        Number(
          this.configKeyToValueMap[
            AVAILABLE_CONFIG_KEY.delivery.pickup_duration_in_minutes
          ]
        ) || 5
      );
    },
    PICKUP_SAME_DAY_GRACE_PERIOD_IN_HOURS () {
      return (
        Number(
          this.configKeyToValueMap[
            AVAILABLE_CONFIG_KEY.delivery.pickup_same_day_grace_period_in_hours
          ]
        ) || 3
      );
    },
    API_SETTINGS () {
      const exchangeRate =
        this.configKeyToValueMap[API_SETTINGS_CONFIG_KEY.exchangeRate];

      return {
        exchangeRate: Number(exchangeRate) || 0,
      };
    },
    POST_REFERRAL_BONUS () {
      return (
        this.configKeyToValueMap[POST_VS_PRO_CONFIG_KEY.POST.REFERRAL_BONUS] ||
        ''
      );
    },
    STORAGE_COST_PER_DAY () {
      try {
        const configKey = AVAILABLE_CONFIG_KEY.ordering.storage_cost_per_day;
        const amountInUnit = Number(this.configKeyToValueMap[configKey]);
        return this.createAmountObj(amountInUnit);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error formatting storage cost per day', error);
        return this.createAmountObj(100);
      }
    },
    EXCHANGE_RATE () {
      return this.API_SETTINGS.exchangeRate;
    },
    TIME_FILTER_OPTIONS () {
      return [
        {
          label: 'All time',
          value: '',
        },
        {
          label: 'Last 15 days',
          value: 'last-15-days',
        },
        {
          label: 'Last 30 days',
          value: 'last-30-days',
        },
        {
          label: 'Last quarter',
          value: 'last-3-months',
        },
        {
          label: 'Last year',
          value: 'last-12-months',
        },
      ];
    },
    getDeviceTimezone () {
      const now = new Date().toString();
      const timeZone = now.replace(/.*[(](.*)[)].*/, '$1');
      return timeZone;
    },
    PICKUP_TIMEZONE () {
      return 'Africa/Lagos';
    },
    enableNgnWallet () {
      return (
        this.loggedInUser &&
        this.loggedInUser.country === supportedDestinationCountries.NG.alpha2
      );
    },
    // some customerMixins
    userKycStatus () {
      return this.loggedInUser?.customerProfile?.identityVerificationStatus;
    },
    ...mapGetters({
      isPaidSubscriber: 'auth/isPaidSubscriber',
      loggedInUser: 'auth/loggedInUser',
      notifications: 'toast/notifications',
      showPageSidebar: 'page/showPageSidebar',
      preferredCurrency: 'page/preferredCurrency',
      preferredTimezone: 'page/preferredTimezone',
      darkMode: 'page/darkMode',
      usWarehouseAddressData: 'common/usWarehouseAddressData',
      usContactNumbersData: 'common/usContactNumbersData',
      ngWarehouseAddressData: 'common/ngWarehouseAddressData',
      ngContactNumbersData: 'common/ngContactNumbersData',
      gbWarehouseAddressData: 'common/gbWarehouseAddressData',
      gbContactNumbersData: 'common/gbContactNumbersData',
      cnWarehouseAddressData: 'common/cnWarehouseAddressData',
      cnContactNumbersData: 'common/cnContactNumbersData',
      timezonesData: 'common/timezonesData',
      pickupAddresses: 'addressbook/pickupAddresses',
      cartOrder: 'cart/cartOrder',
      shipmentStatusesInStore: 'shipment/shipmentStatuses',
      configEntries: 'config/configEntries',
      configKeyToValueMap: 'config/configKeyToValueMap',
    }),
  },
  mounted () {
    if (window.Stripe) {
      try {
        this.$stripe = window.Stripe(this.$config.StripePublishableKey);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error', error);
      }
    }
  },
  methods: {
    /**
     * Translate shortened Query Params to Full Query
     * @param query
     * @returns {*}
     */
    translateQueryParams (query) {
      const queries = {
        ...query,
      };
      Object.entries(this.SHORTENED_EMAIL_QUERIES).forEach(([key, value]) => {
        if (query[value]) {
          queries[key] = query[value];
          delete queries[value];
        }
      });
      return queries;
    },
    getShipmentInfoObj (shipmentInfoString) {
      const str = shipmentInfoString || '';
      return str.split(/\s\| /).reduce((obj, item) => {
        const [key, value] = item.split(': ');
        obj[key] = value;
        return obj;
      }, {});
    },
    getShipmentRequiresAction (shipment) {
      const { flags } = shipment || {};
      return Object.values(flags || {}).some((flag) => {
        return flag.resolved === false;
      });
    },
    mapSelectOption (x) {
      return { label: x, value: x };
    },
    isServerError (status) {
      return [500, 501, 502, 503].includes(status);
    },
    isEnvironment (environment) {
      return this.$config.AppEnv === environment;
    },
    pluralize (...args) {
      return pluralize(...args);
    },
    capitalize (...args) {
      return capitalize(...args);
    },
    humanize (...args) {
      return humanize(...args);
    },
    titleize (...args) {
      return titleize(...args);
    },
    toFixed (num, fixed) {
      const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
      return num.toString().match(re)[0];
    },
    toTitleCase (str) {
      if (!str) {
        return '';
      }
      return str
        .toLowerCase()
        .split(' ')
        .map((word) => {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(' ');
    },
    handleStoreServerError (data, store) {
      const fallbackMsg = store._vm.isServerError(data.status)
        ? 'An error occurred on our systems. Retry after sometime.'
        : 'An error occurred. Retry after sometime.';

      store.dispatch('notification/setNotification', {
        type: 'error',
        message: data.msg || fallbackMsg,
        dismissible: true,
      });
    },
    handleServerError (error) {
      const { data = {}, status } = error.response || error || {};
      const { msg } = data;
      if (msg && status) {
        this.processServerError({ msg, status }, true);
      }
    },
    processServerError (data, noEmit = false) {
      if (this.isServerError(data.status)) {
        noEmit
          ? this.setNotification({
            type: 'error',
            message:
                'An error occurred on our systems. Retry after sometime.',
            dismissible: true,
          })
          : this.setNotification({
            type: 'error',
            message:
                'An error occurred on our systems. Retry after sometime.',
            dismissible: true,
          });
      } else {
        noEmit
          ? this.setNotification({
            type: 'error',
            message: data.msg || 'An error occurred. Retry after sometime.',
            dismissible: true,
          })
          : this.setNotification({
            type: 'error',
            message: data.msg || 'An error occurred. Retry after sometime.',
            dismissible: true,
          });
      }
    },
    async copyToClipboard (
      text,
      successMsg = 'copied',
      errorMsg = 'failed to copy'
    ) {
      try {
        await this.$copyText(text);
        this.setNotification({
          type: 'success',
          message: successMsg,
          dismissible: true,
        });
      } catch (error) {
        this.setNotification({
          type: 'error',
          message: `${errorMsg}: ${error}`,
          dismissible: true,
        });
      }
    },
    decodeToken (token) {
      const decodedToken = jwtDecode(token);
      const { exp } = decodedToken;
      const now = Math.floor(Date.now() / 1000);
      const timeToExpire = exp - now;
      return { decodedToken, timeToExpire };
    },
    parseStaticUrl (path = '/') {
      if (this.$config.staticPath) {
        return `${this.$config.staticPath}${path}`;
      }
      return path;
    },
    formatCurrency (amount, currencyCode = '', stripOffZeros = false) {
      let formattedAmount = '';
      if (currencyCode) {
        const NumberFormat = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currencyCode,
        });
        formattedAmount = NumberFormat.format(amount || 0);
      } else {
        formattedAmount = new Intl.NumberFormat().format(amount || 0);
      }
      if (stripOffZeros) {
        // strip of . and many zeros after it
        formattedAmount = formattedAmount.replace(/\.?0+$/, '');
      }
      return formattedAmount;
    },
    getFormattedAddress (address) {
      if (address && address.code) {
        return {
          ...address,
          name: address.name,
          phone: address.phone,
          street: address.street,
          city: address.city,
          state: address.state,
          country: address.country,
          additional_information: address.additionalInformation,
        };
      }
      if (address && address.name) {
        return {
          name: address.name,
          phone: address.phone,
          street: address.street,
          city: address.city,
          state: address.state,
          country: address.country,
          additional_information: address.additionalInformation,
        };
      }
      return null;
    },
    toPreferredTimezone (date) {
      if (!date) {
        return date;
      }
      const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // based on current location
      const tzid = this.preferredTimezone || defaultTimezone;
      return this.$dayjs(date).tz(tzid);
    },
    getFormattedDateTimeWithSeconds (datetime) {
      if (!datetime) {
        return datetime;
      }
      const formattedDate =
        this.toPreferredTimezone(datetime).format('ll') || '';
      const formattedTime =
        this.toPreferredTimezone(datetime).format('LTS') || '';
      return `${formattedDate} ${formattedTime}`;
    },
    getFormattedDateTime (datetime, withTime = true) {
      if (!datetime) {
        return datetime;
      }
      const dateFormat = withTime ? 'lll' : 'll';
      return this.toPreferredTimezone(datetime).format(dateFormat) || '';
    },
    getCreatedAtDate (timestamp) {
      return this.getFormattedDateTime(timestamp, false);
    },
    getFormattedPickupDate (datetime, withTime = true) {
      if (!datetime) {
        return datetime;
      }
      const dateFormat = withTime ? 'lll' : 'll';
      return this.$dayjs(datetime).format(dateFormat) || '';
    },
    getFormattedTimeSlotTime (time) {
      if (time) {
        time = time.replace(':00Z', '');
        return this.$dayjs(time).format('hh:mma');
      }
      return '';
    },
    togglePageSidebar () {
      this.setShowPageSidebar(!this.showPageSidebar);
      this.activeTabName = '';
    },
    openPageSidebar () {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1800 && screenWidth >= 1440) {
        this.setShowPageSidebar(true);
        return;
      }

      this.setShowPageSidebar(true);
    },
    getAmountInPreferredCurrency ({
      amountObj,
      withSymbol = true,
      exchangeRate,
    }) {
      const currency = this.preferredCurrency || CURRENCY.DEFAULT.value;
      return this.getAmountInCurrency({
        amountObj,
        currency,
        withSymbol,
        exchangeRate,
      });
    },
    getExchangeRate (exchangeRateObj) {
      exchangeRateObj = exchangeRateObj || this.createAmountObj();
      return Number(exchangeRateObj?.charge / 100);
    },
    /**
     * Format amount into currency format
     * @param amountObj
     * @param currency
     * @param withSymbol
     * @param exchangeRate
     * @param options
     * @returns {*|string}
     */
    getAmountInCurrency ({
      amountObj,
      currency,
      withSymbol = true,
      exchangeRate,
      options = {},
    }) {
      amountObj = amountObj || this.createAmountObj();
      exchangeRate = exchangeRate || this.EXCHANGE_RATE;
      let formattedAmount = this.getDisplayTotal(amountObj.charge, options);
      if (
        amountObj.currency === CURRENCY.USD.value &&
        currency === CURRENCY.NGN.value
      ) {
        formattedAmount = this.getDisplayTotal(amountObj.charge * exchangeRate);
      }
      if (
        amountObj.currency === CURRENCY.NGN.value &&
        currency === CURRENCY.USD.value
      ) {
        formattedAmount = this.getDisplayTotal(amountObj.charge / exchangeRate);
      }
      if (withSymbol && currency === CURRENCY.USD.value) {
        return `${CURRENCY.USD.symbol}${formattedAmount}`;
      }
      if (withSymbol && currency === CURRENCY.NGN.value) {
        return `${CURRENCY.NGN.symbol}${formattedAmount}`;
      }
      return formattedAmount;
    },
    getInputClassName (inputClassName = 'form-input', input) {
      // Appends is-invalid class to form input class if it is invalid
      if (!this.$v[input]) {
        return inputClassName;
      }
      return inputClassName + (this.$v[input].$error ? ' is-invalid' : '');
    },
    validationMsg: validationMessage(formValidationMessages),
    validateFormOnSubmit (input = null, parentQuerySelector = null) {
      if (input) {
        this.$v[input].$reset();
        this.$v[input].$touch();
      } else {
        this.$v.$reset();
        this.$v.$touch();
      }

      // Scroll to 1st invalid form input field & focus on it
      this.$nextTick(() => {
        const parentEl = parentQuerySelector
          ? document.querySelector(parentQuerySelector)
          : document;
        if (!parentEl) {
          return;
        }
        const el = parentEl.querySelector('.is-invalid');
        if (el) {
          const domRect = el.getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
          el.focus();
        }
      });
    },
    openHelp () {
      if (window.fcWidget) {
        window.fcWidget.open();
        window.fcWidget.show();
      }
    },
    getUtmParamAsString (utmParam) {
      if (Array.isArray(utmParam) && utmParam.length() > 0) {
        return JSON.stringify(utmParam);
      } else if (utmParam !== '') {
        return utmParam;
      }

      return null;
    },
    getCharge (amount) {
      return Number(String(amount).replaceAll(',', ''));
    },
    getChargeTotal (amount) {
      return new BigNumber(amount).times(100).toNumber();
    },
    getDisplayTotal (amountInUnits = 0, formatOptions = {}) {
      const amount = Number(amountInUnits / 100).toFixed(2);
      return new Intl.NumberFormat('en', formatOptions).format(amount);
    },
    createAmountObj (amountInUnits = 0, currency) {
      return {
        charge: amountInUnits,
        currency: currency || CURRENCY.DEFAULT.value,
        display: this.getDisplayTotal(amountInUnits),
      };
    },
    excludeFlaggedCountries (countries, flaggedCountries = []) {
      const filtered = Object.entries(countries).filter(
        ([_, country]) => !flaggedCountries.includes(country.alpha2)
      );
      return Object.fromEntries(filtered.map(country => country));
    },
    gotoUrl (url, target) {
      if (url.startsWith('http') || target) {
        return window.open(url, target || '_blank');
      }
      this.$router.push(url);
    },
    /**
     * Handle bulk action response
     * @param response
     * @param resourceName
     * @param action
     */
    handleBulkUpdate ({ response, resourceName, action }) {
      const { data } = response;
      const { payload = {} } = data || {};
      const { failed = {}, successful = {} } = payload || {};

      const failedUpdateIds = Object.keys(failed);
      const successfulUpdates = Object.entries(successful).map((x) => {
        const resource = x[1];
        return resource || null;
      }, []);
      const failedCount = failedUpdateIds.length;
      const successfulCount = successfulUpdates.length;
      const totalCount = failedUpdateIds.length + successfulUpdates.length;
      if (successfulCount === totalCount) {
        this.setNotification({
          type: 'success',
          message: `${successfulCount} ${pluralize(
            resourceName,
            successfulCount
          )} ${action} successful.`,
        });
      } else if (failedCount === totalCount) {
        this.setNotification({
          type: 'error',
          message: `${failedCount} ${pluralize(
            resourceName,
            failedCount
          )} failed to ${action}.`,
        });
        throw failed;
      } else {
        this.setNotification({
          type: 'info',
          message: `${successfulCount} ${pluralize(
            resourceName,
            successfulCount
          )} ${action} successful, And ${failedCount} ${pluralize(
            resourceName,
            failedCount
          )} failed to ${action}.`,
        });
      }
    },
    setFreshChatUserProperties () {
      if (this.loggedInUser.email && window.fcWidget) {
        const planName =
          this.loggedInUser?.customerProfile?.subscriptionInfo?.planName;
        window.fcWidget?.user?.setProperties({
          CustomerID: this.loggedInUser.id,
          firstName: this.loggedInUser.firstName,
          lastName: this.loggedInUser.lastName,
          email: this.loggedInUser.email,
          phone: this.loggedInUser.phone,
          status: this.loggedInUser.status,
          accountCountry: this.loggedInUser.country,
          planName,
        });
      }
    },
    debounce (fn, wait = 1000) {
      let timer;
      return function (...args) {
        if (timer) {
          clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
          fn.apply(context, args); // call the function if time expires
        }, wait);
      };
    },
    ...mapActions({
      addToastNotification: 'toast/addToastNotification',
      dismissToastNotification: 'toast/dismissToastNotification',
      clearToastNotification: 'toast/clearToastNotification',
      pauseToastNotification: 'toast/pauseToastNotification',
      resumeToastNotification: 'toast/resumeToastNotification',
      setNotification: 'notification/setNotification',
      clearNotification: 'notification/clearNotification',
      fetchBusinessLocations: 'common/fetchBusinessLocations',
      fetchContactNumbers: 'common/fetchContactNumbers',
      fetchFaqPage: 'common/fetchFaqPage',
    }),
  },
});
