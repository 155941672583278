export const state = () => ({
  activePickup: null,
});

export const mutations = {
  setActivePickup (state, pickup) {
    state.activePickup = pickup || null;
  },
};

export const getters = {
  activePickup: (state) => {
    return state.activePickup;
  },
};

export const actions = {
  setActivePickup ({ commit }, pickup) {
    commit('setActivePickup', pickup);
  },
};
