import CURRENCY from '~/assets/constants/CURRENCY.js';

const getServiceBasePath = version => `/payment/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  wallet: {
    fund: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/wallet/fund`;
      return $axios.post(urlPath, data, { headers });
    },
    getBalance: ({ currencyCode = CURRENCY.NGN.value, headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/wallet/${currencyCode}/balance`;
      return $axios.get(urlPath, { headers, params });
    },
    listTransactions: ({ currencyCode = CURRENCY.NGN.value, headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/wallet/${currencyCode}/transactions`;
      return $axios.get(urlPath, { headers, params });
    },
    withdraw: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/wallet/withdraw`;
      return $axios.post(urlPath, data, { headers });
    },
  },

  vouchers: {
    list: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/vouchers`;
      return $axios.get(urlPath, { headers, params });
    },
    validate: ({ voucherIdOrCode }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/vouchers/${voucherIdOrCode}/validate`;
      return $axios.get(urlPath);
    },
  },

  payments: {
    list: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/payments`;
      return $axios.get(urlPath, { headers, params });
    },
    get: ({ idOrReference, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/payments/${idOrReference}`;
      return $axios.get(urlPath, { headers });
    },
    verify: ({ idOrReference, headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/payments/${idOrReference}/verify`;
      return $axios.post(urlPath, data, { headers });
    },
    generateStripePaymentIntent: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/public/payments/stripe/intent`;
      return $axios.post(urlPath, data, { headers });
    },
  },
});
