
export default {
  name: 'EmptyState',
  props: {
    icon: {
      type: String,
      default: '/svg/shipments/icon-empty-box.svg',
    },
    title: {
      type: String,
      default: 'Nothing to show for now',
    },
    message: {
      type: String,
      default: '',
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
    withBgColor: {
      type: Boolean,
      default: false,
    },
  },
};
