export const AddressesAddressCard = () => import('../../components/addresses/AddressCard.vue' /* webpackChunkName: "components/addresses-address-card" */).then(c => wrapFunctional(c.default || c))
export const AddressesAddressForm = () => import('../../components/addresses/AddressForm.vue' /* webpackChunkName: "components/addresses-address-form" */).then(c => wrapFunctional(c.default || c))
export const AddressesAddressForm2 = () => import('../../components/addresses/AddressForm2.vue' /* webpackChunkName: "components/addresses-address-form2" */).then(c => wrapFunctional(c.default || c))
export const AddressesAddressForm3 = () => import('../../components/addresses/AddressForm3.vue' /* webpackChunkName: "components/addresses-address-form3" */).then(c => wrapFunctional(c.default || c))
export const AuthResetPasswordForm = () => import('../../components/auth/ResetPasswordForm.vue' /* webpackChunkName: "components/auth-reset-password-form" */).then(c => wrapFunctional(c.default || c))
export const AuthSigninForm = () => import('../../components/auth/SigninForm.vue' /* webpackChunkName: "components/auth-signin-form" */).then(c => wrapFunctional(c.default || c))
export const AuthSignupForm = () => import('../../components/auth/SignupForm.vue' /* webpackChunkName: "components/auth-signup-form" */).then(c => wrapFunctional(c.default || c))
export const CartDrawer = () => import('../../components/cart/CartDrawer.vue' /* webpackChunkName: "components/cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const ChatFreshChatScript = () => import('../../components/chat/FreshChatScript.vue' /* webpackChunkName: "components/chat-fresh-chat-script" */).then(c => wrapFunctional(c.default || c))
export const ChatFreshDeskScript = () => import('../../components/chat/FreshDeskScript.vue' /* webpackChunkName: "components/chat-fresh-desk-script" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddressShipmentsGroup = () => import('../../components/checkout/AddressShipmentsGroup.vue' /* webpackChunkName: "components/checkout-address-shipments-group" */).then(c => wrapFunctional(c.default || c))
export const CheckoutLayout = () => import('../../components/checkout/CheckoutLayout.vue' /* webpackChunkName: "components/checkout-layout" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentCheckout = () => import('../../components/checkout/PaymentCheckout.vue' /* webpackChunkName: "components/checkout-payment-checkout" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentSummary = () => import('../../components/checkout/PaymentSummary.vue' /* webpackChunkName: "components/checkout-payment-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSelectAddressModal = () => import('../../components/checkout/SelectAddressModal.vue' /* webpackChunkName: "components/checkout-select-address-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSelectAddressesModal = () => import('../../components/checkout/SelectAddressesModal.vue' /* webpackChunkName: "components/checkout-select-addresses-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSelectMethodModal = () => import('../../components/checkout/SelectMethodModal.vue' /* webpackChunkName: "components/checkout-select-method-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSelectShipmentsDrawer = () => import('../../components/checkout/SelectShipmentsDrawer.vue' /* webpackChunkName: "components/checkout-select-shipments-drawer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShipmentsAddressesGroup = () => import('../../components/checkout/ShipmentsAddressesGroup.vue' /* webpackChunkName: "components/checkout-shipments-addresses-group" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShipmentsGroup = () => import('../../components/checkout/ShipmentsGroup.vue' /* webpackChunkName: "components/checkout-shipments-group" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingAddresses = () => import('../../components/checkout/ShippingAddresses.vue' /* webpackChunkName: "components/checkout-shipping-addresses" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingAddressesSummary = () => import('../../components/checkout/ShippingAddressesSummary.vue' /* webpackChunkName: "components/checkout-shipping-addresses-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingCart = () => import('../../components/checkout/ShippingCart.vue' /* webpackChunkName: "components/checkout-shipping-cart" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingCartSummary = () => import('../../components/checkout/ShippingCartSummary.vue' /* webpackChunkName: "components/checkout-shipping-cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingDeliveryOptions = () => import('../../components/checkout/ShippingDeliveryOptions.vue' /* webpackChunkName: "components/checkout-shipping-delivery-options" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingMethodOptions = () => import('../../components/checkout/ShippingMethodOptions.vue' /* webpackChunkName: "components/checkout-shipping-method-options" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingMethods = () => import('../../components/checkout/ShippingMethods.vue' /* webpackChunkName: "components/checkout-shipping-methods" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingMethodsSummary = () => import('../../components/checkout/ShippingMethodsSummary.vue' /* webpackChunkName: "components/checkout-shipping-methods-summary" */).then(c => wrapFunctional(c.default || c))
export const CommonCountDownTimer = () => import('../../components/common/CountDownTimer.vue' /* webpackChunkName: "components/common-count-down-timer" */).then(c => wrapFunctional(c.default || c))
export const CommonExtraLargeShipmentModal = () => import('../../components/common/ExtraLargeShipmentModal.vue' /* webpackChunkName: "components/common-extra-large-shipment-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonFeatureFlag = () => import('../../components/common/FeatureFlag.vue' /* webpackChunkName: "components/common-feature-flag" */).then(c => wrapFunctional(c.default || c))
export const ComponentsData = () => import('../../components/components/Data.vue' /* webpackChunkName: "components/components-data" */).then(c => wrapFunctional(c.default || c))
export const ComponentsInputs = () => import('../../components/components/Inputs.vue' /* webpackChunkName: "components/components-inputs" */).then(c => wrapFunctional(c.default || c))
export const ComponentsOrders = () => import('../../components/components/Orders.vue' /* webpackChunkName: "components/components-orders" */).then(c => wrapFunctional(c.default || c))
export const ComponentsPayments = () => import('../../components/components/Payments.vue' /* webpackChunkName: "components/components-payments" */).then(c => wrapFunctional(c.default || c))
export const ComponentsShipments = () => import('../../components/components/Shipments.vue' /* webpackChunkName: "components/components-shipments" */).then(c => wrapFunctional(c.default || c))
export const ComponentsUi = () => import('../../components/components/Ui.vue' /* webpackChunkName: "components/components-ui" */).then(c => wrapFunctional(c.default || c))
export const DashboardAboutUKRestrictions = () => import('../../components/dashboard/AboutUKRestrictions.vue' /* webpackChunkName: "components/dashboard-about-u-k-restrictions" */).then(c => wrapFunctional(c.default || c))
export const DashboardAddressTab = () => import('../../components/dashboard/AddressTab.vue' /* webpackChunkName: "components/dashboard-address-tab" */).then(c => wrapFunctional(c.default || c))
export const DashboardBlackFridayModal = () => import('../../components/dashboard/BlackFridayModal.vue' /* webpackChunkName: "components/dashboard-black-friday-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardBuyForMeBanner = () => import('../../components/dashboard/BuyForMeBanner.vue' /* webpackChunkName: "components/dashboard-buy-for-me-banner" */).then(c => wrapFunctional(c.default || c))
export const DashboardHelpfulResources = () => import('../../components/dashboard/DashboardHelpfulResources.vue' /* webpackChunkName: "components/dashboard-helpful-resources" */).then(c => wrapFunctional(c.default || c))
export const DashboardHelpfulResourcesMobile = () => import('../../components/dashboard/DashboardHelpfulResourcesMobile.vue' /* webpackChunkName: "components/dashboard-helpful-resources-mobile" */).then(c => wrapFunctional(c.default || c))
export const DashboardOverview = () => import('../../components/dashboard/DashboardOverview.vue' /* webpackChunkName: "components/dashboard-overview" */).then(c => wrapFunctional(c.default || c))
export const DashboardOverviewMobile = () => import('../../components/dashboard/DashboardOverviewMobile.vue' /* webpackChunkName: "components/dashboard-overview-mobile" */).then(c => wrapFunctional(c.default || c))
export const DashboardTabs = () => import('../../components/dashboard/DashboardTabs.vue' /* webpackChunkName: "components/dashboard-tabs" */).then(c => wrapFunctional(c.default || c))
export const DashboardTabsDoc = () => import('../../components/dashboard/DashboardTabsDoc.vue' /* webpackChunkName: "components/dashboard-tabs-doc" */).then(c => wrapFunctional(c.default || c))
export const DashboardGhanaPromotionModal = () => import('../../components/dashboard/GhanaPromotionModal.vue' /* webpackChunkName: "components/dashboard-ghana-promotion-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeroTour = () => import('../../components/dashboard/HeroTour.vue' /* webpackChunkName: "components/dashboard-hero-tour" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeroTourModal = () => import('../../components/dashboard/HeroTourModal.vue' /* webpackChunkName: "components/dashboard-hero-tour-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeroTourVideo = () => import('../../components/dashboard/HeroTourVideo.vue' /* webpackChunkName: "components/dashboard-hero-tour-video" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeroTourWelcomeModal = () => import('../../components/dashboard/HeroTourWelcomeModal.vue' /* webpackChunkName: "components/dashboard-hero-tour-welcome-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardMarketingEventModal = () => import('../../components/dashboard/MarketingEventModal.vue' /* webpackChunkName: "components/dashboard-marketing-event-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardMarketingTwitterEventModal = () => import('../../components/dashboard/MarketingTwitterEventModal.vue' /* webpackChunkName: "components/dashboard-marketing-twitter-event-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardMoreShippingDaysModal = () => import('../../components/dashboard/MoreShippingDaysModal.vue' /* webpackChunkName: "components/dashboard-more-shipping-days-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardOceanShippingModal = () => import('../../components/dashboard/OceanShippingModal.vue' /* webpackChunkName: "components/dashboard-ocean-shipping-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardShippingAddressChangedModal = () => import('../../components/dashboard/ShippingAddressChangedModal.vue' /* webpackChunkName: "components/dashboard-shipping-address-changed-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardShippingAddressChinaModal = () => import('../../components/dashboard/ShippingAddressChinaModal.vue' /* webpackChunkName: "components/dashboard-shipping-address-china-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardShippingAddressNotice = () => import('../../components/dashboard/ShippingAddressNotice.vue' /* webpackChunkName: "components/dashboard-shipping-address-notice" */).then(c => wrapFunctional(c.default || c))
export const DashboardShippingAddressesTab = () => import('../../components/dashboard/ShippingAddressesTab.vue' /* webpackChunkName: "components/dashboard-shipping-addresses-tab" */).then(c => wrapFunctional(c.default || c))
export const DashboardShippingCalculatorTab = () => import('../../components/dashboard/ShippingCalculatorTab.vue' /* webpackChunkName: "components/dashboard-shipping-calculator-tab" */).then(c => wrapFunctional(c.default || c))
export const DashboardSubscriptionPromoModal = () => import('../../components/dashboard/SubscriptionPromoModal.vue' /* webpackChunkName: "components/dashboard-subscription-promo-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardTrackingTab = () => import('../../components/dashboard/TrackingTab.vue' /* webpackChunkName: "components/dashboard-tracking-tab" */).then(c => wrapFunctional(c.default || c))
export const DataCopyButton = () => import('../../components/data/CopyButton.vue' /* webpackChunkName: "components/data-copy-button" */).then(c => wrapFunctional(c.default || c))
export const DataCopyButtonDoc = () => import('../../components/data/CopyButtonDoc.vue' /* webpackChunkName: "components/data-copy-button-doc" */).then(c => wrapFunctional(c.default || c))
export const DataFlex = () => import('../../components/data/DataFlex.vue' /* webpackChunkName: "components/data-flex" */).then(c => wrapFunctional(c.default || c))
export const DataFlexDoc = () => import('../../components/data/DataFlexDoc.vue' /* webpackChunkName: "components/data-flex-doc" */).then(c => wrapFunctional(c.default || c))
export const DataTable = () => import('../../components/data/DataTable.vue' /* webpackChunkName: "components/data-table" */).then(c => wrapFunctional(c.default || c))
export const DataTableDoc = () => import('../../components/data/DataTableDoc.vue' /* webpackChunkName: "components/data-table-doc" */).then(c => wrapFunctional(c.default || c))
export const DataPagination = () => import('../../components/data/Pagination.vue' /* webpackChunkName: "components/data-pagination" */).then(c => wrapFunctional(c.default || c))
export const DataPaginationDoc = () => import('../../components/data/PaginationDoc.vue' /* webpackChunkName: "components/data-pagination-doc" */).then(c => wrapFunctional(c.default || c))
export const DeliveryDataCard = () => import('../../components/delivery/DeliveryDataCard.vue' /* webpackChunkName: "components/delivery-data-card" */).then(c => wrapFunctional(c.default || c))
export const DeliveryDataTable = () => import('../../components/delivery/DeliveryDataTable.vue' /* webpackChunkName: "components/delivery-data-table" */).then(c => wrapFunctional(c.default || c))
export const DeliveryTabs = () => import('../../components/delivery/DeliveryTabs.vue' /* webpackChunkName: "components/delivery-tabs" */).then(c => wrapFunctional(c.default || c))
export const InputsBaseSelect = () => import('../../components/inputs/BaseSelect.vue' /* webpackChunkName: "components/inputs-base-select" */).then(c => wrapFunctional(c.default || c))
export const InputsInputCheckboxMultiple = () => import('../../components/inputs/InputCheckboxMultiple.vue' /* webpackChunkName: "components/inputs-input-checkbox-multiple" */).then(c => wrapFunctional(c.default || c))
export const InputsInputCheckboxMultipleDoc = () => import('../../components/inputs/InputCheckboxMultipleDoc.vue' /* webpackChunkName: "components/inputs-input-checkbox-multiple-doc" */).then(c => wrapFunctional(c.default || c))
export const InputsInputCheckboxSingle = () => import('../../components/inputs/InputCheckboxSingle.vue' /* webpackChunkName: "components/inputs-input-checkbox-single" */).then(c => wrapFunctional(c.default || c))
export const InputsInputCheckboxSingleDoc = () => import('../../components/inputs/InputCheckboxSingleDoc.vue' /* webpackChunkName: "components/inputs-input-checkbox-single-doc" */).then(c => wrapFunctional(c.default || c))
export const InputsInputFile = () => import('../../components/inputs/InputFile.vue' /* webpackChunkName: "components/inputs-input-file" */).then(c => wrapFunctional(c.default || c))
export const InputsInputFileDoc = () => import('../../components/inputs/InputFileDoc.vue' /* webpackChunkName: "components/inputs-input-file-doc" */).then(c => wrapFunctional(c.default || c))
export const InputsInputPhoneNumber = () => import('../../components/inputs/InputPhoneNumber.vue' /* webpackChunkName: "components/inputs-input-phone-number" */).then(c => wrapFunctional(c.default || c))
export const InputsInputPhoneNumberDoc = () => import('../../components/inputs/InputPhoneNumberDoc.vue' /* webpackChunkName: "components/inputs-input-phone-number-doc" */).then(c => wrapFunctional(c.default || c))
export const InputsInputRadio = () => import('../../components/inputs/InputRadio.vue' /* webpackChunkName: "components/inputs-input-radio" */).then(c => wrapFunctional(c.default || c))
export const InputsInputRadioDoc = () => import('../../components/inputs/InputRadioDoc.vue' /* webpackChunkName: "components/inputs-input-radio-doc" */).then(c => wrapFunctional(c.default || c))
export const InputsInputSearch = () => import('../../components/inputs/InputSearch.vue' /* webpackChunkName: "components/inputs-input-search" */).then(c => wrapFunctional(c.default || c))
export const InputsInputSearchDoc = () => import('../../components/inputs/InputSearchDoc.vue' /* webpackChunkName: "components/inputs-input-search-doc" */).then(c => wrapFunctional(c.default || c))
export const InputsInputSelectDropdown = () => import('../../components/inputs/InputSelectDropdown.vue' /* webpackChunkName: "components/inputs-input-select-dropdown" */).then(c => wrapFunctional(c.default || c))
export const InputsInputSelectDropdownDoc = () => import('../../components/inputs/InputSelectDropdownDoc.vue' /* webpackChunkName: "components/inputs-input-select-dropdown-doc" */).then(c => wrapFunctional(c.default || c))
export const InputsInputText = () => import('../../components/inputs/InputText.vue' /* webpackChunkName: "components/inputs-input-text" */).then(c => wrapFunctional(c.default || c))
export const InputsInputTextarea = () => import('../../components/inputs/InputTextarea.vue' /* webpackChunkName: "components/inputs-input-textarea" */).then(c => wrapFunctional(c.default || c))
export const InputsOtpInput = () => import('../../components/inputs/OtpInput.vue' /* webpackChunkName: "components/inputs-otp-input" */).then(c => wrapFunctional(c.default || c))
export const InputsOtpInputDoc = () => import('../../components/inputs/OtpInputDoc.vue' /* webpackChunkName: "components/inputs-otp-input-doc" */).then(c => wrapFunctional(c.default || c))
export const InputsToggle = () => import('../../components/inputs/Toggle.vue' /* webpackChunkName: "components/inputs-toggle" */).then(c => wrapFunctional(c.default || c))
export const InputsToggleDoc = () => import('../../components/inputs/ToggleDoc.vue' /* webpackChunkName: "components/inputs-toggle-doc" */).then(c => wrapFunctional(c.default || c))
export const LayoutAuthHeader = () => import('../../components/layout/AuthHeader.vue' /* webpackChunkName: "components/layout-auth-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutAuthLayout = () => import('../../components/layout/AuthLayout.vue' /* webpackChunkName: "components/layout-auth-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutComponentsLayout = () => import('../../components/layout/ComponentsLayout.vue' /* webpackChunkName: "components/layout-components-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutComponentsSidebar = () => import('../../components/layout/ComponentsSidebar.vue' /* webpackChunkName: "components/layout-components-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LayoutDashboardHeader = () => import('../../components/layout/DashboardHeader.vue' /* webpackChunkName: "components/layout-dashboard-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutDashboardLayout = () => import('../../components/layout/DashboardLayout.vue' /* webpackChunkName: "components/layout-dashboard-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutDashboardSidebar = () => import('../../components/layout/DashboardSidebar.vue' /* webpackChunkName: "components/layout-dashboard-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LayoutLoginSideContent = () => import('../../components/layout/LoginSideContent.vue' /* webpackChunkName: "components/layout-login-side-content" */).then(c => wrapFunctional(c.default || c))
export const LayoutOfflineNotice = () => import('../../components/layout/OfflineNotice.vue' /* webpackChunkName: "components/layout-offline-notice" */).then(c => wrapFunctional(c.default || c))
export const LayoutOnboardingLayout = () => import('../../components/layout/OnboardingLayout.vue' /* webpackChunkName: "components/layout-onboarding-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutRefreshNotice = () => import('../../components/layout/RefreshNotice.vue' /* webpackChunkName: "components/layout-refresh-notice" */).then(c => wrapFunctional(c.default || c))
export const LayoutSignupSideContent = () => import('../../components/layout/SignupSideContent.vue' /* webpackChunkName: "components/layout-signup-side-content" */).then(c => wrapFunctional(c.default || c))
export const NotificationsAllNotificationsTab = () => import('../../components/notifications/AllNotificationsTab.vue' /* webpackChunkName: "components/notifications-all-notifications-tab" */).then(c => wrapFunctional(c.default || c))
export const NotificationsAnnouncementsNotificationsTab = () => import('../../components/notifications/AnnouncementsNotificationsTab.vue' /* webpackChunkName: "components/notifications-announcements-notifications-tab" */).then(c => wrapFunctional(c.default || c))
export const NotificationsKycNotificationsTab = () => import('../../components/notifications/KycNotificationsTab.vue' /* webpackChunkName: "components/notifications-kyc-notifications-tab" */).then(c => wrapFunctional(c.default || c))
export const NotificationsNotification = () => import('../../components/notifications/Notification.vue' /* webpackChunkName: "components/notifications-notification" */).then(c => wrapFunctional(c.default || c))
export const NotificationsNotificationTabs = () => import('../../components/notifications/NotificationTabs.vue' /* webpackChunkName: "components/notifications-notification-tabs" */).then(c => wrapFunctional(c.default || c))
export const NotificationsDrawer = () => import('../../components/notifications/NotificationsDrawer.vue' /* webpackChunkName: "components/notifications-drawer" */).then(c => wrapFunctional(c.default || c))
export const NotificationsPickupsNotificationsTab = () => import('../../components/notifications/PickupsNotificationsTab.vue' /* webpackChunkName: "components/notifications-pickups-notifications-tab" */).then(c => wrapFunctional(c.default || c))
export const NotificationsShipmentsNotificationsTab = () => import('../../components/notifications/ShipmentsNotificationsTab.vue' /* webpackChunkName: "components/notifications-shipments-notifications-tab" */).then(c => wrapFunctional(c.default || c))
export const NotificationsWalletNotificationsTab = () => import('../../components/notifications/WalletNotificationsTab.vue' /* webpackChunkName: "components/notifications-wallet-notifications-tab" */).then(c => wrapFunctional(c.default || c))
export const OnboardingSteps = () => import('../../components/onboarding/OnboardingSteps.vue' /* webpackChunkName: "components/onboarding-steps" */).then(c => wrapFunctional(c.default || c))
export const OnboardingSurveyModal = () => import('../../components/onboarding/SurveyModal.vue' /* webpackChunkName: "components/onboarding-survey-modal" */).then(c => wrapFunctional(c.default || c))
export const OrdersAddressShipmentsGroup = () => import('../../components/orders/AddressShipmentsGroup.vue' /* webpackChunkName: "components/orders-address-shipments-group" */).then(c => wrapFunctional(c.default || c))
export const OrdersOrderDataCard = () => import('../../components/orders/OrderDataCard.vue' /* webpackChunkName: "components/orders-order-data-card" */).then(c => wrapFunctional(c.default || c))
export const OrdersOrderShipmentsDataTable = () => import('../../components/orders/OrderShipmentsDataTable.vue' /* webpackChunkName: "components/orders-order-shipments-data-table" */).then(c => wrapFunctional(c.default || c))
export const OrdersOrderShipmentsMethodsGroup = () => import('../../components/orders/OrderShipmentsMethodsGroup.vue' /* webpackChunkName: "components/orders-order-shipments-methods-group" */).then(c => wrapFunctional(c.default || c))
export const OrdersOrderStatusLabel = () => import('../../components/orders/OrderStatusLabel.vue' /* webpackChunkName: "components/orders-order-status-label" */).then(c => wrapFunctional(c.default || c))
export const OrdersOrderStatusLabelDoc = () => import('../../components/orders/OrderStatusLabelDoc.vue' /* webpackChunkName: "components/orders-order-status-label-doc" */).then(c => wrapFunctional(c.default || c))
export const OrdersDataTable = () => import('../../components/orders/OrdersDataTable.vue' /* webpackChunkName: "components/orders-data-table" */).then(c => wrapFunctional(c.default || c))
export const OrdersReceiptTable = () => import('../../components/orders/ReceiptTable.vue' /* webpackChunkName: "components/orders-receipt-table" */).then(c => wrapFunctional(c.default || c))
export const OrdersSplitPayContributorsDataCard = () => import('../../components/orders/SplitPayContributorsDataCard.vue' /* webpackChunkName: "components/orders-split-pay-contributors-data-card" */).then(c => wrapFunctional(c.default || c))
export const OrdersSplitPayContributorsDataTable = () => import('../../components/orders/SplitPayContributorsDataTable.vue' /* webpackChunkName: "components/orders-split-pay-contributors-data-table" */).then(c => wrapFunctional(c.default || c))
export const OrdersSplitPaySummary = () => import('../../components/orders/SplitPaySummary.vue' /* webpackChunkName: "components/orders-split-pay-summary" */).then(c => wrapFunctional(c.default || c))
export const OrdersSplitStatusLabel = () => import('../../components/orders/SplitStatusLabel.vue' /* webpackChunkName: "components/orders-split-status-label" */).then(c => wrapFunctional(c.default || c))
export const PanelsNotificationBanner = () => import('../../components/panels/NotificationBanner.vue' /* webpackChunkName: "components/panels-notification-banner" */).then(c => wrapFunctional(c.default || c))
export const PaymentsCurrencySwitcher = () => import('../../components/payments/CurrencySwitcher.vue' /* webpackChunkName: "components/payments-currency-switcher" */).then(c => wrapFunctional(c.default || c))
export const PaymentsCurrencySwitcherDoc = () => import('../../components/payments/CurrencySwitcherDoc.vue' /* webpackChunkName: "components/payments-currency-switcher-doc" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentDataCard = () => import('../../components/payments/PaymentDataCard.vue' /* webpackChunkName: "components/payments-payment-data-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentOption = () => import('../../components/payments/PaymentOption.vue' /* webpackChunkName: "components/payments-payment-option" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentOptionDoc = () => import('../../components/payments/PaymentOptionDoc.vue' /* webpackChunkName: "components/payments-payment-option-doc" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentReceiptTable = () => import('../../components/payments/PaymentReceiptTable.vue' /* webpackChunkName: "components/payments-payment-receipt-table" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentStatusLabel = () => import('../../components/payments/PaymentStatusLabel.vue' /* webpackChunkName: "components/payments-payment-status-label" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaymentTag = () => import('../../components/payments/PaymentTag.vue' /* webpackChunkName: "components/payments-payment-tag" */).then(c => wrapFunctional(c.default || c))
export const PaymentsDataTable = () => import('../../components/payments/PaymentsDataTable.vue' /* webpackChunkName: "components/payments-data-table" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaypalButton = () => import('../../components/payments/PaypalButton.vue' /* webpackChunkName: "components/payments-paypal-button" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaystackButton = () => import('../../components/payments/PaystackButton.vue' /* webpackChunkName: "components/payments-paystack-button" */).then(c => wrapFunctional(c.default || c))
export const PaymentsSplitPay = () => import('../../components/payments/SplitPay.vue' /* webpackChunkName: "components/payments-split-pay" */).then(c => wrapFunctional(c.default || c))
export const PaymentsSplitPayAddContributorModal = () => import('../../components/payments/SplitPayAddContributorModal.vue' /* webpackChunkName: "components/payments-split-pay-add-contributor-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentsSplitPaySharePaymentLinkModal = () => import('../../components/payments/SplitPaySharePaymentLinkModal.vue' /* webpackChunkName: "components/payments-split-pay-share-payment-link-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentsStripePaymentForm = () => import('../../components/payments/StripePaymentForm.vue' /* webpackChunkName: "components/payments-stripe-payment-form" */).then(c => wrapFunctional(c.default || c))
export const PaymentsStripePaymentModal = () => import('../../components/payments/StripePaymentModal.vue' /* webpackChunkName: "components/payments-stripe-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentsStripePricingTable = () => import('../../components/payments/StripePricingTable.vue' /* webpackChunkName: "components/payments-stripe-pricing-table" */).then(c => wrapFunctional(c.default || c))
export const PaymentsWalletSwitcher = () => import('../../components/payments/WalletSwitcher.vue' /* webpackChunkName: "components/payments-wallet-switcher" */).then(c => wrapFunctional(c.default || c))
export const PaymentsWalletSwitcherDoc = () => import('../../components/payments/WalletSwitcherDoc.vue' /* webpackChunkName: "components/payments-wallet-switcher-doc" */).then(c => wrapFunctional(c.default || c))
export const PickupsPickUpStorageFeeBox = () => import('../../components/pickups/PickUpStorageFeeBox.vue' /* webpackChunkName: "components/pickups-pick-up-storage-fee-box" */).then(c => wrapFunctional(c.default || c))
export const PickupsPickUpStoragePaymentModal = () => import('../../components/pickups/PickUpStoragePaymentModal.vue' /* webpackChunkName: "components/pickups-pick-up-storage-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const PickupsPickupConfirmShipmentsDataTable = () => import('../../components/pickups/PickupConfirmShipmentsDataTable.vue' /* webpackChunkName: "components/pickups-pickup-confirm-shipments-data-table" */).then(c => wrapFunctional(c.default || c))
export const PickupsPickupReadyShipmentsDataCard = () => import('../../components/pickups/PickupReadyShipmentsDataCard.vue' /* webpackChunkName: "components/pickups-pickup-ready-shipments-data-card" */).then(c => wrapFunctional(c.default || c))
export const PickupsPickupReadyShipmentsDataTable = () => import('../../components/pickups/PickupReadyShipmentsDataTable.vue' /* webpackChunkName: "components/pickups-pickup-ready-shipments-data-table" */).then(c => wrapFunctional(c.default || c))
export const PickupsPickupStatusLabel = () => import('../../components/pickups/PickupStatusLabel.vue' /* webpackChunkName: "components/pickups-pickup-status-label" */).then(c => wrapFunctional(c.default || c))
export const PickupsDataCard = () => import('../../components/pickups/PickupsDataCard.vue' /* webpackChunkName: "components/pickups-data-card" */).then(c => wrapFunctional(c.default || c))
export const PickupsDataTable = () => import('../../components/pickups/PickupsDataTable.vue' /* webpackChunkName: "components/pickups-data-table" */).then(c => wrapFunctional(c.default || c))
export const PickupsShipmentsDataTable = () => import('../../components/pickups/PickupsShipmentsDataTable.vue' /* webpackChunkName: "components/pickups-shipments-data-table" */).then(c => wrapFunctional(c.default || c))
export const PickupsTabs = () => import('../../components/pickups/PickupsTabs.vue' /* webpackChunkName: "components/pickups-tabs" */).then(c => wrapFunctional(c.default || c))
export const PromotionsCashBack = () => import('../../components/promotions/CashBack.vue' /* webpackChunkName: "components/promotions-cash-back" */).then(c => wrapFunctional(c.default || c))
export const PromotionsCashBackDataTable = () => import('../../components/promotions/CashBackDataTable.vue' /* webpackChunkName: "components/promotions-cash-back-data-table" */).then(c => wrapFunctional(c.default || c))
export const PromotionsHowCashBackWorks = () => import('../../components/promotions/HowCashBackWorks.vue' /* webpackChunkName: "components/promotions-how-cash-back-works" */).then(c => wrapFunctional(c.default || c))
export const PromotionsHowReferralsWorksSummary = () => import('../../components/promotions/HowReferralsWorksSummary.vue' /* webpackChunkName: "components/promotions-how-referrals-works-summary" */).then(c => wrapFunctional(c.default || c))
export const PromotionsReferrals = () => import('../../components/promotions/Referrals.vue' /* webpackChunkName: "components/promotions-referrals" */).then(c => wrapFunctional(c.default || c))
export const PromotionsVoucherDataCard = () => import('../../components/promotions/VoucherDataCard.vue' /* webpackChunkName: "components/promotions-voucher-data-card" */).then(c => wrapFunctional(c.default || c))
export const PromotionsVouchersDataTable = () => import('../../components/promotions/VouchersDataTable.vue' /* webpackChunkName: "components/promotions-vouchers-data-table" */).then(c => wrapFunctional(c.default || c))
export const SearchInboundConfirmationModal = () => import('../../components/search/InboundConfirmationModal.vue' /* webpackChunkName: "components/search-inbound-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const SearchReceivedShipmentModal = () => import('../../components/search/ReceivedShipmentModal.vue' /* webpackChunkName: "components/search-received-shipment-modal" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/search/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SettingsAboutAutoPay = () => import('../../components/settings/AboutAutoPay.vue' /* webpackChunkName: "components/settings-about-auto-pay" */).then(c => wrapFunctional(c.default || c))
export const SettingsAccountDeletion = () => import('../../components/settings/AccountDeletion.vue' /* webpackChunkName: "components/settings-account-deletion" */).then(c => wrapFunctional(c.default || c))
export const SettingsAutoPayActive = () => import('../../components/settings/AutoPayActive.vue' /* webpackChunkName: "components/settings-auto-pay-active" */).then(c => wrapFunctional(c.default || c))
export const SettingsAutoPayDeliveryMethod = () => import('../../components/settings/AutoPayDeliveryMethod.vue' /* webpackChunkName: "components/settings-auto-pay-delivery-method" */).then(c => wrapFunctional(c.default || c))
export const SettingsAutoPayTab = () => import('../../components/settings/AutoPayTab.vue' /* webpackChunkName: "components/settings-auto-pay-tab" */).then(c => wrapFunctional(c.default || c))
export const SettingsBrandingTab = () => import('../../components/settings/BrandingTab.vue' /* webpackChunkName: "components/settings-branding-tab" */).then(c => wrapFunctional(c.default || c))
export const SettingsBusinessProfile = () => import('../../components/settings/BusinessProfile.vue' /* webpackChunkName: "components/settings-business-profile" */).then(c => wrapFunctional(c.default || c))
export const SettingsChangeEmailForm = () => import('../../components/settings/ChangeEmailForm.vue' /* webpackChunkName: "components/settings-change-email-form" */).then(c => wrapFunctional(c.default || c))
export const SettingsChangePasswordForm = () => import('../../components/settings/ChangePasswordForm.vue' /* webpackChunkName: "components/settings-change-password-form" */).then(c => wrapFunctional(c.default || c))
export const SettingsCustomBranding = () => import('../../components/settings/CustomBranding.vue' /* webpackChunkName: "components/settings-custom-branding" */).then(c => wrapFunctional(c.default || c))
export const SettingsDeleteAccountCodeConfirmationModal = () => import('../../components/settings/DeleteAccountCodeConfirmationModal.vue' /* webpackChunkName: "components/settings-delete-account-code-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const SettingsDeleteAccountSuccessModal = () => import('../../components/settings/DeleteAccountSuccessModal.vue' /* webpackChunkName: "components/settings-delete-account-success-modal" */).then(c => wrapFunctional(c.default || c))
export const SettingsDeleteAccountWithReasonModal = () => import('../../components/settings/DeleteAccountWithReasonModal.vue' /* webpackChunkName: "components/settings-delete-account-with-reason-modal" */).then(c => wrapFunctional(c.default || c))
export const SettingsGeneralPreferences = () => import('../../components/settings/GeneralPreferences.vue' /* webpackChunkName: "components/settings-general-preferences" */).then(c => wrapFunctional(c.default || c))
export const SettingsHelpAndSupport = () => import('../../components/settings/HelpAndSupport.vue' /* webpackChunkName: "components/settings-help-and-support" */).then(c => wrapFunctional(c.default || c))
export const SettingsHeroTour = () => import('../../components/settings/HeroTour.vue' /* webpackChunkName: "components/settings-hero-tour" */).then(c => wrapFunctional(c.default || c))
export const SettingsKybVerification = () => import('../../components/settings/KybVerification.vue' /* webpackChunkName: "components/settings-kyb-verification" */).then(c => wrapFunctional(c.default || c))
export const SettingsKybVerificationModal = () => import('../../components/settings/KybVerificationModal.vue' /* webpackChunkName: "components/settings-kyb-verification-modal" */).then(c => wrapFunctional(c.default || c))
export const SettingsKycVerificationModalCombo = () => import('../../components/settings/KycVerificationModalCombo.vue' /* webpackChunkName: "components/settings-kyc-verification-modal-combo" */).then(c => wrapFunctional(c.default || c))
export const SettingsLoginAndSecurity = () => import('../../components/settings/LoginAndSecurity.vue' /* webpackChunkName: "components/settings-login-and-security" */).then(c => wrapFunctional(c.default || c))
export const SettingsPersonalInformation = () => import('../../components/settings/PersonalInformation.vue' /* webpackChunkName: "components/settings-personal-information" */).then(c => wrapFunctional(c.default || c))
export const SettingsPersonalInformationForm = () => import('../../components/settings/PersonalInformationForm.vue' /* webpackChunkName: "components/settings-personal-information-form" */).then(c => wrapFunctional(c.default || c))
export const SettingsRequestNameChangeModal = () => import('../../components/settings/RequestNameChangeModal.vue' /* webpackChunkName: "components/settings-request-name-change-modal" */).then(c => wrapFunctional(c.default || c))
export const SettingsLayout = () => import('../../components/settings/SettingsLayout.vue' /* webpackChunkName: "components/settings-layout" */).then(c => wrapFunctional(c.default || c))
export const SettingsSubscriptionTab = () => import('../../components/settings/SubscriptionTab.vue' /* webpackChunkName: "components/settings-subscription-tab" */).then(c => wrapFunctional(c.default || c))
export const SettingsUpgradeToProBanner = () => import('../../components/settings/UpgradeToProBanner.vue' /* webpackChunkName: "components/settings-upgrade-to-pro-banner" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsAboutStorageFees = () => import('../../components/shipments/AboutStorageFees.vue' /* webpackChunkName: "components/shipments-about-storage-fees" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsCartShipment = () => import('../../components/shipments/CartShipment.vue' /* webpackChunkName: "components/shipments-cart-shipment" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsCartShipmentDoc = () => import('../../components/shipments/CartShipmentDoc.vue' /* webpackChunkName: "components/shipments-cart-shipment-doc" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsChangeDeliveryModal = () => import('../../components/shipments/ChangeDeliveryModal.vue' /* webpackChunkName: "components/shipments-change-delivery-modal" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsChangeShippingMethod = () => import('../../components/shipments/ChangeShippingMethod.vue' /* webpackChunkName: "components/shipments-change-shipping-method" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsCheckoutCartShipment = () => import('../../components/shipments/CheckoutCartShipment.vue' /* webpackChunkName: "components/shipments-checkout-cart-shipment" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsCheckoutCartShipmentDoc = () => import('../../components/shipments/CheckoutCartShipmentDoc.vue' /* webpackChunkName: "components/shipments-checkout-cart-shipment-doc" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsDeliveryTimeline = () => import('../../components/shipments/DeliveryTimeline.vue' /* webpackChunkName: "components/shipments-delivery-timeline" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsDeliveryTimelineDoc = () => import('../../components/shipments/DeliveryTimelineDoc.vue' /* webpackChunkName: "components/shipments-delivery-timeline-doc" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsImagePreviewModal = () => import('../../components/shipments/ImagePreviewModal.vue' /* webpackChunkName: "components/shipments-image-preview-modal" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsRealtimeTracking = () => import('../../components/shipments/RealtimeTracking.vue' /* webpackChunkName: "components/shipments-realtime-tracking" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsRequiresActionLabel = () => import('../../components/shipments/RequiresActionLabel.vue' /* webpackChunkName: "components/shipments-requires-action-label" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsShipmentDataCard = () => import('../../components/shipments/ShipmentDataCard.vue' /* webpackChunkName: "components/shipments-shipment-data-card" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsShipmentImages = () => import('../../components/shipments/ShipmentImages.vue' /* webpackChunkName: "components/shipments-shipment-images" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsShipmentImagesDoc = () => import('../../components/shipments/ShipmentImagesDoc.vue' /* webpackChunkName: "components/shipments-shipment-images-doc" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsShipmentStatusLabel = () => import('../../components/shipments/ShipmentStatusLabel.vue' /* webpackChunkName: "components/shipments-shipment-status-label" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsShipmentStatusLabelDoc = () => import('../../components/shipments/ShipmentStatusLabelDoc.vue' /* webpackChunkName: "components/shipments-shipment-status-label-doc" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsDataTable = () => import('../../components/shipments/ShipmentsDataTable.vue' /* webpackChunkName: "components/shipments-data-table" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsStorageFeeSection = () => import('../../components/shipments/StorageFeeSection.vue' /* webpackChunkName: "components/shipments-storage-fee-section" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsStorageWaiverNotice = () => import('../../components/shipments/StorageWaiverNotice.vue' /* webpackChunkName: "components/shipments-storage-waiver-notice" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsWeightClassModal = () => import('../../components/shipments/WeightClassModal.vue' /* webpackChunkName: "components/shipments-weight-class-modal" */).then(c => wrapFunctional(c.default || c))
export const StartHereEmailVerificationModal = () => import('../../components/start-here/EmailVerificationModal.vue' /* webpackChunkName: "components/start-here-email-verification-modal" */).then(c => wrapFunctional(c.default || c))
export const StartHereMyUsAddress = () => import('../../components/start-here/MyUsAddress.vue' /* webpackChunkName: "components/start-here-my-us-address" */).then(c => wrapFunctional(c.default || c))
export const StartHereFaq = () => import('../../components/start-here/StartHereFaq.vue' /* webpackChunkName: "components/start-here-faq" */).then(c => wrapFunctional(c.default || c))
export const StartHereFinishOnboarding = () => import('../../components/start-here/StartHereFinishOnboarding.vue' /* webpackChunkName: "components/start-here-finish-onboarding" */).then(c => wrapFunctional(c.default || c))
export const StartHereGuide = () => import('../../components/start-here/StartHereGuide.vue' /* webpackChunkName: "components/start-here-guide" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsBenefitsOfProPlan = () => import('../../components/subscriptions/BenefitsOfProPlan.vue' /* webpackChunkName: "components/subscriptions-benefits-of-pro-plan" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsPlanDetails = () => import('../../components/subscriptions/PlanDetails.vue' /* webpackChunkName: "components/subscriptions-plan-details" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsReceiptTable = () => import('../../components/subscriptions/ReceiptTable.vue' /* webpackChunkName: "components/subscriptions-receipt-table" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsSpecialDealsBanner = () => import('../../components/subscriptions/SpecialDealsBanner.vue' /* webpackChunkName: "components/subscriptions-special-deals-banner" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsSpecialOffers = () => import('../../components/subscriptions/SpecialOffers.vue' /* webpackChunkName: "components/subscriptions-special-offers" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsSubscriptionInvoiceDataCard = () => import('../../components/subscriptions/SubscriptionInvoiceDataCard.vue' /* webpackChunkName: "components/subscriptions-subscription-invoice-data-card" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsSubscriptionInvoiceTable = () => import('../../components/subscriptions/SubscriptionInvoiceTable.vue' /* webpackChunkName: "components/subscriptions-subscription-invoice-table" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsSubscriptionPlanCard = () => import('../../components/subscriptions/SubscriptionPlanCard.vue' /* webpackChunkName: "components/subscriptions-subscription-plan-card" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsSubscriptionSummaryModal = () => import('../../components/subscriptions/SubscriptionSummaryModal.vue' /* webpackChunkName: "components/subscriptions-subscription-summary-modal" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsUpgradeCallToAction = () => import('../../components/subscriptions/UpgradeCallToAction.vue' /* webpackChunkName: "components/subscriptions-upgrade-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionsUpgradeTab = () => import('../../components/subscriptions/UpgradeTab.vue' /* webpackChunkName: "components/subscriptions-upgrade-tab" */).then(c => wrapFunctional(c.default || c))
export const ThirdPartyVueHtml2pdf = () => import('../../components/third-party/VueHtml2pdf.vue' /* webpackChunkName: "components/third-party-vue-html2pdf" */).then(c => wrapFunctional(c.default || c))
export const TransitionsFadeTransition = () => import('../../components/transitions/FadeTransition.vue' /* webpackChunkName: "components/transitions-fade-transition" */).then(c => wrapFunctional(c.default || c))
export const TransitionsSlideFadeInTransition = () => import('../../components/transitions/SlideFadeInTransition.vue' /* webpackChunkName: "components/transitions-slide-fade-in-transition" */).then(c => wrapFunctional(c.default || c))
export const TransitionsSlideTransition = () => import('../../components/transitions/SlideTransition.vue' /* webpackChunkName: "components/transitions-slide-transition" */).then(c => wrapFunctional(c.default || c))
export const UiAlert = () => import('../../components/ui/Alert.vue' /* webpackChunkName: "components/ui-alert" */).then(c => wrapFunctional(c.default || c))
export const UiAlertDoc = () => import('../../components/ui/AlertDoc.vue' /* webpackChunkName: "components/ui-alert-doc" */).then(c => wrapFunctional(c.default || c))
export const UiBreadcrumbs = () => import('../../components/ui/Breadcrumbs.vue' /* webpackChunkName: "components/ui-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const UiBreadcrumbsDoc = () => import('../../components/ui/BreadcrumbsDoc.vue' /* webpackChunkName: "components/ui-breadcrumbs-doc" */).then(c => wrapFunctional(c.default || c))
export const UiCallToAction = () => import('../../components/ui/CallToAction.vue' /* webpackChunkName: "components/ui-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const UiCallToActionDoc = () => import('../../components/ui/CallToActionDoc.vue' /* webpackChunkName: "components/ui-call-to-action-doc" */).then(c => wrapFunctional(c.default || c))
export const UiConfirmationModal = () => import('../../components/ui/ConfirmationModal.vue' /* webpackChunkName: "components/ui-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const UiConfirmationModalDoc = () => import('../../components/ui/ConfirmationModalDoc.vue' /* webpackChunkName: "components/ui-confirmation-modal-doc" */).then(c => wrapFunctional(c.default || c))
export const UiDivider = () => import('../../components/ui/Divider.vue' /* webpackChunkName: "components/ui-divider" */).then(c => wrapFunctional(c.default || c))
export const UiDividerDoc = () => import('../../components/ui/DividerDoc.vue' /* webpackChunkName: "components/ui-divider-doc" */).then(c => wrapFunctional(c.default || c))
export const UiDrawer = () => import('../../components/ui/Drawer.vue' /* webpackChunkName: "components/ui-drawer" */).then(c => wrapFunctional(c.default || c))
export const UiDrawerDoc = () => import('../../components/ui/DrawerDoc.vue' /* webpackChunkName: "components/ui-drawer-doc" */).then(c => wrapFunctional(c.default || c))
export const UiDropdown = () => import('../../components/ui/Dropdown.vue' /* webpackChunkName: "components/ui-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UiDropdownDoc = () => import('../../components/ui/DropdownDoc.vue' /* webpackChunkName: "components/ui-dropdown-doc" */).then(c => wrapFunctional(c.default || c))
export const UiEmptyState = () => import('../../components/ui/EmptyState.vue' /* webpackChunkName: "components/ui-empty-state" */).then(c => wrapFunctional(c.default || c))
export const UiEmptyStateDoc = () => import('../../components/ui/EmptyStateDoc.vue' /* webpackChunkName: "components/ui-empty-state-doc" */).then(c => wrapFunctional(c.default || c))
export const UiFaqs = () => import('../../components/ui/Faqs.vue' /* webpackChunkName: "components/ui-faqs" */).then(c => wrapFunctional(c.default || c))
export const UiFaqsDoc = () => import('../../components/ui/FaqsDoc.vue' /* webpackChunkName: "components/ui-faqs-doc" */).then(c => wrapFunctional(c.default || c))
export const UiIcon = () => import('../../components/ui/Icon.vue' /* webpackChunkName: "components/ui-icon" */).then(c => wrapFunctional(c.default || c))
export const UiIconDoc = () => import('../../components/ui/IconDoc.vue' /* webpackChunkName: "components/ui-icon-doc" */).then(c => wrapFunctional(c.default || c))
export const UiLoader = () => import('../../components/ui/Loader.vue' /* webpackChunkName: "components/ui-loader" */).then(c => wrapFunctional(c.default || c))
export const UiLoaderDoc = () => import('../../components/ui/LoaderDoc.vue' /* webpackChunkName: "components/ui-loader-doc" */).then(c => wrapFunctional(c.default || c))
export const UiModal = () => import('../../components/ui/Modal.vue' /* webpackChunkName: "components/ui-modal" */).then(c => wrapFunctional(c.default || c))
export const UiNewModal = () => import('../../components/ui/NewModal.vue' /* webpackChunkName: "components/ui-new-modal" */).then(c => wrapFunctional(c.default || c))
export const UiNewModalDoc = () => import('../../components/ui/NewModalDoc.vue' /* webpackChunkName: "components/ui-new-modal-doc" */).then(c => wrapFunctional(c.default || c))
export const UiNotificationModal = () => import('../../components/ui/NotificationModal.vue' /* webpackChunkName: "components/ui-notification-modal" */).then(c => wrapFunctional(c.default || c))
export const UiNotificationModalDoc = () => import('../../components/ui/NotificationModalDoc.vue' /* webpackChunkName: "components/ui-notification-modal-doc" */).then(c => wrapFunctional(c.default || c))
export const UiOptionButton = () => import('../../components/ui/OptionButton.vue' /* webpackChunkName: "components/ui-option-button" */).then(c => wrapFunctional(c.default || c))
export const UiOptionButtonDoc = () => import('../../components/ui/OptionButtonDoc.vue' /* webpackChunkName: "components/ui-option-button-doc" */).then(c => wrapFunctional(c.default || c))
export const UiOutlineAlert = () => import('../../components/ui/OutlineAlert.vue' /* webpackChunkName: "components/ui-outline-alert" */).then(c => wrapFunctional(c.default || c))
export const UiOutlineAlertDoc = () => import('../../components/ui/OutlineAlertDoc.vue' /* webpackChunkName: "components/ui-outline-alert-doc" */).then(c => wrapFunctional(c.default || c))
export const UiPasswordStrength = () => import('../../components/ui/PasswordStrength.vue' /* webpackChunkName: "components/ui-password-strength" */).then(c => wrapFunctional(c.default || c))
export const UiPasswordStrengthDoc = () => import('../../components/ui/PasswordStrengthDoc.vue' /* webpackChunkName: "components/ui-password-strength-doc" */).then(c => wrapFunctional(c.default || c))
export const UiPaymentButton = () => import('../../components/ui/PaymentButton.vue' /* webpackChunkName: "components/ui-payment-button" */).then(c => wrapFunctional(c.default || c))
export const UiPaymentMethods = () => import('../../components/ui/PaymentMethods.vue' /* webpackChunkName: "components/ui-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const UiProgressBar = () => import('../../components/ui/ProgressBar.vue' /* webpackChunkName: "components/ui-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const UiProgressBarDoc = () => import('../../components/ui/ProgressBarDoc.vue' /* webpackChunkName: "components/ui-progress-bar-doc" */).then(c => wrapFunctional(c.default || c))
export const UiQuickNote = () => import('../../components/ui/QuickNote.vue' /* webpackChunkName: "components/ui-quick-note" */).then(c => wrapFunctional(c.default || c))
export const UiQuickNoteDoc = () => import('../../components/ui/QuickNoteDoc.vue' /* webpackChunkName: "components/ui-quick-note-doc" */).then(c => wrapFunctional(c.default || c))
export const UiSimpleTooltip = () => import('../../components/ui/SimpleTooltip.vue' /* webpackChunkName: "components/ui-simple-tooltip" */).then(c => wrapFunctional(c.default || c))
export const UiSimpleTooltipDoc = () => import('../../components/ui/SimpleTooltipDoc.vue' /* webpackChunkName: "components/ui-simple-tooltip-doc" */).then(c => wrapFunctional(c.default || c))
export const UiStatusLabel = () => import('../../components/ui/StatusLabel.vue' /* webpackChunkName: "components/ui-status-label" */).then(c => wrapFunctional(c.default || c))
export const UiStatusLabelDoc = () => import('../../components/ui/StatusLabelDoc.vue' /* webpackChunkName: "components/ui-status-label-doc" */).then(c => wrapFunctional(c.default || c))
export const UiStepperIndicators = () => import('../../components/ui/StepperIndicators.vue' /* webpackChunkName: "components/ui-stepper-indicators" */).then(c => wrapFunctional(c.default || c))
export const UiSubscriptionPaymentButton = () => import('../../components/ui/SubscriptionPaymentButton.vue' /* webpackChunkName: "components/ui-subscription-payment-button" */).then(c => wrapFunctional(c.default || c))
export const UiTab = () => import('../../components/ui/Tab.vue' /* webpackChunkName: "components/ui-tab" */).then(c => wrapFunctional(c.default || c))
export const UiTabs = () => import('../../components/ui/Tabs.vue' /* webpackChunkName: "components/ui-tabs" */).then(c => wrapFunctional(c.default || c))
export const UiTabsDoc = () => import('../../components/ui/TabsDoc.vue' /* webpackChunkName: "components/ui-tabs-doc" */).then(c => wrapFunctional(c.default || c))
export const UiTagNew = () => import('../../components/ui/TagNew.vue' /* webpackChunkName: "components/ui-tag-new" */).then(c => wrapFunctional(c.default || c))
export const UiToast = () => import('../../components/ui/Toast.vue' /* webpackChunkName: "components/ui-toast" */).then(c => wrapFunctional(c.default || c))
export const UiToastContainer = () => import('../../components/ui/ToastContainer.vue' /* webpackChunkName: "components/ui-toast-container" */).then(c => wrapFunctional(c.default || c))
export const UiToastDoc = () => import('../../components/ui/ToastDoc.vue' /* webpackChunkName: "components/ui-toast-doc" */).then(c => wrapFunctional(c.default || c))
export const WalletFundWalletDrawer = () => import('../../components/wallet/FundWalletDrawer.vue' /* webpackChunkName: "components/wallet-fund-wallet-drawer" */).then(c => wrapFunctional(c.default || c))
export const WalletTransactionsDataCard = () => import('../../components/wallet/TransactionsDataCard.vue' /* webpackChunkName: "components/wallet-transactions-data-card" */).then(c => wrapFunctional(c.default || c))
export const WalletTransactionsDataTable = () => import('../../components/wallet/TransactionsDataTable.vue' /* webpackChunkName: "components/wallet-transactions-data-table" */).then(c => wrapFunctional(c.default || c))
export const WalletData = () => import('../../components/wallet/WalletData.vue' /* webpackChunkName: "components/wallet-data" */).then(c => wrapFunctional(c.default || c))
export const ShipmentsRequiresActionBulkyFlagPaymentModal = () => import('../../components/shipments/requires-action/BulkyFlagPaymentModal.vue' /* webpackChunkName: "components/shipments-requires-action-bulky-flag-payment-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
