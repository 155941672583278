
import CartShipment from '~/components/shipments/CartShipment.vue';

export default {
  name: 'Toast',
  components: {
    CartShipment,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    context: {
      type: String,
      default: '',
    },
    content: {
      type: Array,
      default: () => [],
    },
    isDismissible: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      icons: {
        close: '/svg/icon-close.svg',
      },
    };
  },
  methods: {
    dismissToast () {
      this.$emit('dismiss');
    },
  },
};
