import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3bb6e39e = () => interopDefault(import('../pages/address-book/index.vue' /* webpackChunkName: "pages/address-book/index" */))
const _d002a41a = () => interopDefault(import('../pages/autopay/index.vue' /* webpackChunkName: "pages/autopay/index" */))
const _28863dd2 = () => interopDefault(import('../pages/buyforme/index.vue' /* webpackChunkName: "pages/buyforme/index" */))
const _4e59918f = () => interopDefault(import('../pages/components.vue' /* webpackChunkName: "pages/components" */))
const _527ab2d2 = () => interopDefault(import('../pages/conversion/index.vue' /* webpackChunkName: "pages/conversion/index" */))
const _04361324 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5c332d44 = () => interopDefault(import('../pages/labelling/index.vue' /* webpackChunkName: "pages/labelling/index" */))
const _7cc3d042 = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _18b1e197 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _13fd5b3e = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _8f89a27c = () => interopDefault(import('../pages/pay/index.vue' /* webpackChunkName: "pages/pay/index" */))
const _bdcdbf2e = () => interopDefault(import('../pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _42a2955e = () => interopDefault(import('../pages/pickups/index.vue' /* webpackChunkName: "pages/pickups/index" */))
const _d43b2528 = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _620e91df = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _3ffeebb3 = () => interopDefault(import('../pages/shipments/index.vue' /* webpackChunkName: "pages/shipments/index" */))
const _02896412 = () => interopDefault(import('../pages/start-here/index.vue' /* webpackChunkName: "pages/start-here/index" */))
const _69cda5d9 = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _0fbf491a = () => interopDefault(import('../pages/upgrade.vue' /* webpackChunkName: "pages/upgrade" */))
const _5f3e0b16 = () => interopDefault(import('../pages/wallet/index.vue' /* webpackChunkName: "pages/wallet/index" */))
const _1bfb0a6c = () => interopDefault(import('../pages/address-book/new.vue' /* webpackChunkName: "pages/address-book/new" */))
const _1777e140 = () => interopDefault(import('../pages/auth/signin.vue' /* webpackChunkName: "pages/auth/signin" */))
const _2c0e37b6 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _4f28728e = () => interopDefault(import('../pages/auth/verification.vue' /* webpackChunkName: "pages/auth/verification" */))
const _34ae86a1 = () => interopDefault(import('../pages/onboarding/upgrade.vue' /* webpackChunkName: "pages/onboarding/upgrade" */))
const _880b39f0 = () => interopDefault(import('../pages/pickups/delivery/index.vue' /* webpackChunkName: "pages/pickups/delivery/index" */))
const _1c9abb5a = () => interopDefault(import('../pages/pickups/history.vue' /* webpackChunkName: "pages/pickups/history" */))
const _91babe4a = () => interopDefault(import('../pages/pickups/reschedule.vue' /* webpackChunkName: "pages/pickups/reschedule" */))
const _017a5168 = () => interopDefault(import('../pages/pickups/schedule.vue' /* webpackChunkName: "pages/pickups/schedule" */))
const _645ad71a = () => interopDefault(import('../pages/promotions/cashback/index.vue' /* webpackChunkName: "pages/promotions/cashback/index" */))
const _7df3290f = () => interopDefault(import('../pages/promotions/voucher-history.vue' /* webpackChunkName: "pages/promotions/voucher-history" */))
const _138fb982 = () => interopDefault(import('../pages/settings/kyc.vue' /* webpackChunkName: "pages/settings/kyc" */))
const _edbb2490 = () => interopDefault(import('../pages/shipments/checkout/index.vue' /* webpackChunkName: "pages/shipments/checkout/index" */))
const _483ae308 = () => interopDefault(import('../pages/start-here/faqs.vue' /* webpackChunkName: "pages/start-here/faqs" */))
const _9b348eaa = () => interopDefault(import('../pages/start-here/my-us-address.vue' /* webpackChunkName: "pages/start-here/my-us-address" */))
const _28b20d6e = () => interopDefault(import('../pages/start-here/set-address.vue' /* webpackChunkName: "pages/start-here/set-address" */))
const _4188ffce = () => interopDefault(import('../pages/auth/Password/reset.vue' /* webpackChunkName: "pages/auth/Password/reset" */))
const _aa6c836c = () => interopDefault(import('../pages/pickups/delivery/history.vue' /* webpackChunkName: "pages/pickups/delivery/history" */))
const _6706e45a = () => interopDefault(import('../pages/shipments/checkout/address.vue' /* webpackChunkName: "pages/shipments/checkout/address" */))
const _fa2cf5d0 = () => interopDefault(import('../pages/shipments/checkout/methods.vue' /* webpackChunkName: "pages/shipments/checkout/methods" */))
const _dc527a2e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7007d917 = () => interopDefault(import('../pages/shipments/checkout/payment/_id.vue' /* webpackChunkName: "pages/shipments/checkout/payment/_id" */))
const _11380ace = () => interopDefault(import('../pages/address-book/edit/_id.vue' /* webpackChunkName: "pages/address-book/edit/_id" */))
const _74cf09be = () => interopDefault(import('../pages/orders/confirmation/_id.vue' /* webpackChunkName: "pages/orders/confirmation/_id" */))
const _3728db00 = () => interopDefault(import('../pages/orders/payment/_id.vue' /* webpackChunkName: "pages/orders/payment/_id" */))
const _1ca66d9c = () => interopDefault(import('../pages/orders/receipt/_id.vue' /* webpackChunkName: "pages/orders/receipt/_id" */))
const _6ba41baf = () => interopDefault(import('../pages/orders/split-pay/_id.vue' /* webpackChunkName: "pages/orders/split-pay/_id" */))
const _64cabaac = () => interopDefault(import('../pages/subscription/receipt/_id.vue' /* webpackChunkName: "pages/subscription/receipt/_id" */))
const _9e5b0dee = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _ca2c0cac = () => interopDefault(import('../pages/pay/_id.vue' /* webpackChunkName: "pages/pay/_id" */))
const _c3d3adde = () => interopDefault(import('../pages/payments/_id.vue' /* webpackChunkName: "pages/payments/_id" */))
const _5348500e = () => interopDefault(import('../pages/pickups/_id.vue' /* webpackChunkName: "pages/pickups/_id" */))
const _5f0eaec6 = () => interopDefault(import('../pages/r/_code.vue' /* webpackChunkName: "pages/r/_code" */))
const _5cab824a = () => interopDefault(import('../pages/shipments/_id.vue' /* webpackChunkName: "pages/shipments/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/address-book",
    component: _3bb6e39e,
    name: "address-book"
  }, {
    path: "/autopay",
    component: _d002a41a,
    name: "autopay"
  }, {
    path: "/buyforme",
    component: _28863dd2,
    name: "buyforme"
  }, {
    path: "/components",
    component: _4e59918f,
    name: "components"
  }, {
    path: "/conversion",
    component: _527ab2d2,
    name: "conversion"
  }, {
    path: "/dashboard",
    component: _04361324,
    name: "dashboard"
  }, {
    path: "/labelling",
    component: _5c332d44,
    name: "labelling"
  }, {
    path: "/notifications",
    component: _7cc3d042,
    name: "notifications"
  }, {
    path: "/onboarding",
    component: _18b1e197,
    name: "onboarding"
  }, {
    path: "/orders",
    component: _13fd5b3e,
    name: "orders"
  }, {
    path: "/pay",
    component: _8f89a27c,
    name: "pay"
  }, {
    path: "/payments",
    component: _bdcdbf2e,
    name: "payments"
  }, {
    path: "/pickups",
    component: _42a2955e,
    name: "pickups"
  }, {
    path: "/promotions",
    component: _d43b2528,
    name: "promotions"
  }, {
    path: "/settings",
    component: _620e91df,
    name: "settings"
  }, {
    path: "/shipments",
    component: _3ffeebb3,
    name: "shipments"
  }, {
    path: "/start-here",
    component: _02896412,
    name: "start-here"
  }, {
    path: "/subscription",
    component: _69cda5d9,
    name: "subscription"
  }, {
    path: "/upgrade",
    component: _0fbf491a,
    name: "upgrade"
  }, {
    path: "/wallet",
    component: _5f3e0b16,
    name: "wallet"
  }, {
    path: "/address-book/new",
    component: _1bfb0a6c,
    name: "address-book-new"
  }, {
    path: "/auth/signin",
    component: _1777e140,
    name: "auth-signin"
  }, {
    path: "/auth/signup",
    component: _2c0e37b6,
    name: "auth-signup"
  }, {
    path: "/auth/verification",
    component: _4f28728e,
    name: "auth-verification"
  }, {
    path: "/onboarding/upgrade",
    component: _34ae86a1,
    name: "onboarding-upgrade"
  }, {
    path: "/pickups/delivery",
    component: _880b39f0,
    name: "pickups-delivery"
  }, {
    path: "/pickups/history",
    component: _1c9abb5a,
    name: "pickups-history"
  }, {
    path: "/pickups/reschedule",
    component: _91babe4a,
    name: "pickups-reschedule"
  }, {
    path: "/pickups/schedule",
    component: _017a5168,
    name: "pickups-schedule"
  }, {
    path: "/promotions/cashback",
    component: _645ad71a,
    name: "promotions-cashback"
  }, {
    path: "/promotions/voucher-history",
    component: _7df3290f,
    name: "promotions-voucher-history"
  }, {
    path: "/settings/kyc",
    component: _138fb982,
    name: "settings-kyc"
  }, {
    path: "/shipments/checkout",
    component: _edbb2490,
    name: "shipments-checkout"
  }, {
    path: "/start-here/faqs",
    component: _483ae308,
    name: "start-here-faqs"
  }, {
    path: "/start-here/my-us-address",
    component: _9b348eaa,
    name: "start-here-my-us-address"
  }, {
    path: "/start-here/set-address",
    component: _28b20d6e,
    name: "start-here-set-address"
  }, {
    path: "/auth/Password/reset",
    component: _4188ffce,
    name: "auth-Password-reset"
  }, {
    path: "/pickups/delivery/history",
    component: _aa6c836c,
    name: "pickups-delivery-history"
  }, {
    path: "/shipments/checkout/address",
    component: _6706e45a,
    name: "shipments-checkout-address"
  }, {
    path: "/shipments/checkout/methods",
    component: _fa2cf5d0,
    name: "shipments-checkout-methods"
  }, {
    path: "/",
    component: _dc527a2e,
    name: "index"
  }, {
    path: "/shipments/checkout/payment/:id?",
    component: _7007d917,
    name: "shipments-checkout-payment-id"
  }, {
    path: "/address-book/edit/:id",
    component: _11380ace,
    name: "address-book-edit-id"
  }, {
    path: "/orders/confirmation/:id?",
    component: _74cf09be,
    name: "orders-confirmation-id"
  }, {
    path: "/orders/payment/:id?",
    component: _3728db00,
    name: "orders-payment-id"
  }, {
    path: "/orders/receipt/:id?",
    component: _1ca66d9c,
    name: "orders-receipt-id"
  }, {
    path: "/orders/split-pay/:id?",
    component: _6ba41baf,
    name: "orders-split-pay-id"
  }, {
    path: "/subscription/receipt/:id?",
    component: _64cabaac,
    name: "subscription-receipt-id"
  }, {
    path: "/orders/:id",
    component: _9e5b0dee,
    name: "orders-id"
  }, {
    path: "/pay/:id",
    component: _ca2c0cac,
    name: "pay-id"
  }, {
    path: "/payments/:id",
    component: _c3d3adde,
    name: "payments-id"
  }, {
    path: "/pickups/:id",
    component: _5348500e,
    name: "pickups-id"
  }, {
    path: "/r/:code?",
    component: _5f0eaec6,
    name: "r-code"
  }, {
    path: "/shipments/:id",
    component: _5cab824a,
    name: "shipments-id"
  }, {
    path: "/auth/login",
    component: _1777e140,
    name: "auth-login"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
