import { mapGetters, mapActions } from 'vuex';
import PAYMENT_STATUS from '~/assets/constants/PAYMENT_STATUS.js';
import PAYMENT_TAG from '~/assets/constants/PAYMENT_TAG.js';

const walletMixin = {
  computed: {
    ...mapGetters({
      walletBalance: 'wallet/walletBalance',
      walletBalanceIsLoading: 'wallet/walletBalanceIsLoading',
      preferredCurrency: 'page/preferredCurrency',
    }),
    currentWalletBalance () {
      return this.getAmountInCurrency({
        amountObj: this.walletBalance,
        currency: this.walletBalance.currency,
        withSymbol: false,
      });
    },
    currentWalletBalanceWithSymbol () {
      return this.getAmountInCurrency({
        amountObj: this.walletBalance,
        currency: this.walletBalance.currency,
        options: { minimumFractionDigits: 2 },
      });
    },
  },
  data () {
    return {
      fetchingFailedWalletPayments: false,
      failedWalletPayments: [],
    };
  },
  watch: {
    preferredCurrency (newCurrency) {
      this.fetchWalletBalance(newCurrency);
    },
  },
  created () {
    this.refreshWalletBalance();
    this.fetchFailedWalletPayments();
  },
  methods: {
    ...mapActions({
      fetchWalletBalance: 'wallet/fetchWalletBalance',
    }),
    refreshWalletBalance () {
      this.fetchWalletBalance(this.preferredCurrency);
    },
    async fetchFailedWalletPayments () {
      const query = { status: PAYMENT_STATUS.FAILED, tag: PAYMENT_TAG.FUND_WALLET };
      this.fetchingFailedWalletPayments = true;
      try {
        const { data } = await this.$api.payment.payments.list({ params: query });
        this.failedWalletPayments = data?.payload?.payments || [];
      } finally {
        this.fetchingFailedWalletPayments = false;
      }
    },
  },
};

export default walletMixin;
