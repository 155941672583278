import { mapGetters, mapActions } from 'vuex';
import SHIPMENT_PAYMENT_STATUS from '~/assets/constants/SHIPMENT_PAYMENT_STATUS.js';

const shipmentMixin = {
  data () {
    return {
      idOrTrackingNumber: '',
      fetchingShipment: false,
      shipment: {},
      shipmentOrder: null,
      icons: {
        requiresPop: '/svg/shipments/icon-requires-pop-infor.svg',
      },
    };
  },
  computed: {
    ...mapGetters({
      activeShipment: 'shipment/activeShipment',
    }),
  },
  created () {
    if (this.activeShipment) {
      this.shipment = this.activeShipment;
      this.checkToAutoResolveOrderId(this.shipment);
    }
  },
  methods: {
    ...mapActions({
      setActiveShipment: 'shipment/setActiveShipment',
    }),
    async fetchShipment (idOrTrackingNumber) {
      if (!this.activeShipment) {
        this.fetchingShipment = true;
      }
      try {
        const { data } = await this.$api.shipping.shipments.get({ idOrTrackingNumber });
        this.shipment = data?.payload?.shipment || {};
        if (!this.activeShipment) {
          this.checkToAutoResolveOrderId(this.shipment);
        }
      } catch (error) {
        this.handleServerError(error);
        this.$router.push('/shipments');
      } finally {
        this.fetchingShipment = false;
      }
    },
    checkToAutoResolveOrderId (shipment) {
      if (shipment && shipment.orderId && shipment.paymentStatus === SHIPMENT_PAYMENT_STATUS.UNPAID) {
        this.fetchShipmentOrder(shipment.orderId);
      }
    },
    async fetchShipmentOrder (idOrReference) {
      try {
        const { data } = await this.$api.ordering.orders.get({ idOrReference });
        this.shipmentOrder = data?.payload?.order || null;
      } catch (error) {
        const errorMsg = error?.response?.data?.msg;
        // auto resolve order id if order not found
        if (error?.response?.status === 404 || errorMsg?.toLowerCase()?.includes('not found')) {
          this.autoResolveOrderId(idOrReference);
        }
      }
    },
    autoResolveOrderId (idOrReference) {
      // eslint-disable-next-line no-console
      console.log('autoResolveOrderId', idOrReference);
    },
  },
};

export default shipmentMixin;
