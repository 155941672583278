const PAYMENT_METHOD = {
  WALLET: 'WALLET',
  PAYSTACK: 'PAYSTACK',
  STRIPE: 'STRIPE',
  PAYPAL: 'PAYPAL',
  VOUCHER: 'VOUCHER',
  SPLIT_PAY: 'SPLIT_PAY',
  PAY_LATER: 'PAY_LATER',
};

export default PAYMENT_METHOD;
