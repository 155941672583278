import { mapGetters, mapActions } from 'vuex';
const DarkReader = process.client ? require('darkreader') : {};

const pageMixin = {
  data () {
    return {
      themeConfig: {
        brightness: 95,
        contrast: 90,
        sepia: 0,
      },
    };
  },
  computed: {
    themeClass () {
      return {
        'dark-mode-toggle': true,
        [this.currentTheme]: true,
      };
    },
    currentTheme: {
      get () {
        return this.$store.state.page.currentTheme;
      },
      set (value) {
        this.setCurrentTheme(value);
      },
    },
    allowBetaFeatures () {
      return this.loggedInUser?.customerProfile?.accountPreferences?.betaTester;
    },
    isDarkMode () {
      return this.darkMode;
    },
    ...mapGetters({
      darkMode: 'page/darkMode',
      loggedInUser: 'auth/loggedInUser',
    }),
  },
  watch: {
    currentTheme (newTheme) {
      this.updateCurrentTheme(newTheme);
    },
  },
  mounted () {
    DarkReader.setFetchMethod(window.fetch);
    this.updateCurrentTheme(this.currentTheme);

    // add event to auto update theme when system theme changes
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      if (this.currentTheme === 'system') {
        DarkReader.auto();
        this.setDarkMode(DarkReader.isEnabled());
      }
    });
  },
  methods: {
    toggleDarkMode () {
      const currentMode = this.currentTheme;
      if (currentMode === 'light') {
        this.currentTheme = 'dark';
        this.setDarkMode(true);
      } else if (currentMode === 'dark') {
        this.currentTheme = 'system';
        this.setDarkMode(DarkReader.isEnabled());
      } else {
        this.currentTheme = 'light';
        this.setDarkMode(false);
      }
    },
    updateCurrentTheme (mode) {
      switch (mode) {
        case 'light':
          DarkReader.disable();
          this.setDarkMode(false);
          break;

        case 'dark':
          DarkReader.enable(this.themeConfig);
          this.setDarkMode(true);
          break;

        case 'system':
        default:
          DarkReader.auto(this.themeConfig);
          this.setDarkMode(DarkReader.isEnabled());
          break;
      }
    },
    ...mapActions({
      setCurrentTheme: 'page/setCurrentTheme',
      setDarkMode: 'page/setDarkMode',
    }),
  },
};

export default pageMixin;
