
import { mapActions } from 'vuex';

export default {
  name: 'CartShipment',
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
    showRemoveButton: {
      type: Boolean,
      default: false,
    },
    minimalBorder: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      icons: {
        defaultShipmentImage: '/svg/shipments/icon-empty-box.svg',
        removeFromCart: '/svg/shipments/icon-remove-from-cart-2.svg',
      },
    };
  },
  computed: {
    shipmentImages () {
      return Object.values(this.shipment?.images || {});
    },
    firstShipmentImage () {
      return this.shipmentImages?.[0] || '';
    },
    shipmentWeight () {
      return this.shipment?.weight || 0;
    },
    shipmentWeightUnit () {
      return this.shipment?.weightUnit || 'kg';
    },
    shipmentInfoObj () {
      return this.getShipmentInfoObj(this.shipment?.info || '');
    },
    shipmentInfoText () {
      const shipmentItems = this.shipmentInfoObj?.['Item(s)'] || '';
      return shipmentItems || this.shipment?.info || 'N/A';
    },
  },
  methods: {
    ...mapActions({
      setActiveShipment: 'shipment/setActiveShipment',
    }),
  },
};
