const SHIPMENT_STATUS = {
  EXPECTED: 'EXPECTED',
  RECEIVED: 'RECEIVED',
  PROCESSED: 'PROCESSED',
  PACKED: 'PACKED',
  'DROPPED-OFF': 'DROPPED-OFF',
  SHIPPED: 'SHIPPED',
  CLEARANCE: 'CLEARANCE',
  SORTED: 'SORTED',
  PICKUP: 'PICKUP',
  'PICKED-UP': 'PICKED-UP',
  DISPATCHED: 'DISPATCHED',
  DELIVERED: 'DELIVERED',
};

export default SHIPMENT_STATUS;
