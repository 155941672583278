import { mapActions, mapGetters } from 'vuex';
import CURRENCY from '~/assets/constants/CURRENCY.js';
import { generatePaymentReferencePart } from '~/mixins/payStackMixin.js';
import { facebookPixelMixin } from '~/mixins/index.js';
import SHIPPING_METHODS from '~/assets/constants/SHIPPING_METHODS.js';

const orderMixin = {
  mixins: [facebookPixelMixin],
  data () {
    return {
      SHIPPING_METHODS,
      removeLimit: -1,
      defaultLimit: 3,
      limit: 3,
      showStripePaymentModal: false,
      showPayNowButton: true,
      applyingPayment: false,
      payingWith: '',
      paymentChoice: '',
      // coupon: '',
      idOrReference: '',
      fetchingOrder: false,
      order: {},
      fetchingOrderShipments: false,
      orderShipments: [],
      orderShipmentsPaginationData: {},
      fetchingOrderPayments: false,
      orderPayments: [],
      orderPaymentsPaginationData: {},
      cancelingOrder: false,
      icons: {
        mastercard: '/svg/payments/icon-mastercard.svg',
        paypal: '/svg/payments/icon-paypal.png',
        paystack: '/svg/payments/icon-paystack.svg',
        securedPayment: '/svg/payments/icon-secured-payment.svg',
        splitPay: '/svg/payments/icon-split-pay.svg',
        stripe: '/svg/payments/icon-stripe.svg',
        visa: '/svg/payments/icon-visa.svg',
        wallet: '/svg/payments/icon-wallet.svg',
      },
      paymentProcessorIcons: {
        STRIPE: '/svg/payments/icon-debit-card.svg',
        PAYSTACK: '/svg/payments/icon-debit-card.svg',
        VOUCHER: '/svg/payments/icon-discount-voucher.svg',
        WALLET: '/svg/payments/icon-wallet.svg',
        PAYPAL: '/svg/payments/icon-paypal.png',
      },
      defaultLabelHeader: 'HEROSHE',
    };
  },
  computed: {
    ...mapGetters({
      activeOrder: 'order/activeOrder',
      preferredCurrency: 'page/preferredCurrency',
    }),
    balanceAmounts () {
      const { balanceAmounts } = this.order;
      return balanceAmounts;
    },
    totalAmounts () {
      const { totalAmounts } = this.order;
      return totalAmounts;
    },
    deliveryFeeAmounts () {
      const { deliveryFeeAmounts } = this.order;
      return deliveryFeeAmounts;
    },
    shippingFeeAmounts () {
      const { shippingFeeAmounts } = this.order;
      return shippingFeeAmounts;
    },
    paidAmounts () {
      const { paidAmounts } = this.order;
      return paidAmounts;
    },
    storageFeeAmounts () {
      const { storageFeeAmounts } = this.order;
      return storageFeeAmounts;
    },
    limitOrderShipments () {
      const items = [...this.orderShipments];
      if (this.limit > this.removeLimit) {
        return items.slice(0, this.limit);
      }
      return items;
    },
    orderOwnerName () {
      return this.order?.accountInfo?.fullName || '';
    },
    orderOwnerEmail () {
      return this.order?.accountInfo?.email || '';
    },
    orderUniqueShippingMethods () {
      const methods = {
        [SHIPPING_METHODS.AIR_FREIGHT.key]: [],
        [SHIPPING_METHODS.OCEAN_FREIGHT.key]: []
      };
      this.orderShipments.forEach((shipment) => {
        const { freightMode } = shipment;
        if (methods[freightMode]) {
          methods[freightMode].push(shipment);
        }
        if (freightMode === '') {
          methods[SHIPPING_METHODS.AIR_FREIGHT.key].push(shipment);
        }
      });
      return methods;
    },
    orderUniqueAddresses () {
      return this.orderShipments.reduce((uniqueAddresses, shipment) => {
        const addressExists = uniqueAddresses.some((address) => {
          const { deliveryAddress } = address;
          if (!shipment.deliveryAddress) {
            return false;
          }
          return (
            JSON.stringify(deliveryAddress) ===
            JSON.stringify(shipment.deliveryAddress)
          );
        });
        if (!addressExists) {
          uniqueAddresses.push({
            deliveryAddress: shipment.deliveryAddress,
            deliveryChoice: shipment.deliveryChoice,
            destinationCountry: shipment.destinationCountry,
          });
        }
        return uniqueAddresses;
      }, []);
    },
    orderExchangeRate () {
      const exchangeRateAmountObj =
        this.order?.exchangeRate || this.createAmountObj(0, CURRENCY.NGN.value);
      return this.getExchangeRate(exchangeRateAmountObj);
    },
    totalDisplayWithSymbol () {
      return `${CURRENCY[this.preferredCurrency].symbol} ${this.totalAmount?.display}`;
    },
    totalAmount () {
      return this.totalAmounts?.[this.preferredCurrency] || this.totalAmounts?.[CURRENCY.NGN.value];
    },
    totalDisplayInDollarWithSymbol () {
      return `${CURRENCY.USD.symbol} ${this.totalAmounts?.[CURRENCY.USD.value]?.display}`;
    },
    totalChargeInDollar () {
      return this.totalAmounts?.[CURRENCY.USD.value]?.charge;
    },
    totalDisplayInNairaWithSymbol () {
      return `${CURRENCY.NGN.symbol} ${this.totalAmounts?.[CURRENCY.NGN.value]?.display}`;
    },
    totalChargeInNaira () {
      return this.totalAmounts?.[CURRENCY.NGN.value]?.charge;
    },
    storageFeeAmount () {
      return this.storageFeeAmounts?.[this.preferredCurrency] || this.storageFeeAmounts?.[CURRENCY.NGN.value];
    },
    storageFeeDisplayInDollarWithSymbol () {
      return `${CURRENCY.USD.symbol} ${this.storageFeeAmounts?.[CURRENCY.USD.value]?.display}`;
    },
    storageFeeDisplayWithSymbol () {
      return `${CURRENCY[this.preferredCurrency].symbol} ${this.storageFeeAmount?.display}`;
    },

    storageFeeChargeInDollar () {
      return this.storageFeeAmounts?.[CURRENCY.USD.value]?.charge;
    },
    showStorageFeeAmount () {
      return this.storageFeeChargeInDollar > 0;
    },
    deliveryFeeAmount () {
      return this.deliveryFeeAmounts?.[this.preferredCurrency] || this.deliveryFeeAmounts?.[CURRENCY.NGN.value];
    },
    deliveryFeeDisplayWithSymbol () {
      return `${CURRENCY[this.preferredCurrency].symbol} ${this.deliveryFeeAmount?.display}`;
    },

    deliveryFeeDisplayInDollarWithSymbol () {
      return `${CURRENCY.USD.symbol} ${this.deliveryFeeAmounts?.[CURRENCY.USD.value]?.display}`;
    },
    deliveryFeeChargeInDollar () {
      return this.deliveryFeeAmounts?.[CURRENCY.USD.value]?.charge;
    },
    showDeliveryFee () {
      return this.deliveryFeeChargeInDollar > 0;
    },
    balanceAmount () {
      return this.balanceAmounts?.[this.preferredCurrency] || this.balanceAmounts?.[CURRENCY.NGN.value];
    },
    balanceDisplayWithSymbol () {
      return `${CURRENCY[this.preferredCurrency].symbol} ${this.balanceAmount?.display}`;
    },
    balanceDisplayInDollarWithSymbol () {
      return `${CURRENCY.USD.symbol} ${this.balanceAmounts?.[CURRENCY.USD.value]?.display}`;
    },
    balanceChargeInDollar () {
      return this.balanceAmounts?.[CURRENCY.USD.value]?.charge;
    },
    balanceDisplayInNairaWithSymbol () {
      return `${CURRENCY.NGN.symbol} ${this.balanceAmounts?.[CURRENCY.NGN.value]?.display}`;
    },
    balanceChargeInNaira () {
      return this.balanceAmounts?.[CURRENCY.NGN.value]?.charge;
    },
    shippingFeeAmount () {
      return this.shippingFeeAmounts?.[this.preferredCurrency] || this.shippingFeeAmounts?.[CURRENCY.NGN.value];
    },
    shippingFeeDisplayWithSymbol () {
      return `${CURRENCY[this.preferredCurrency].symbol} ${this.shippingFeeAmount?.display}`;
    },
    shippingFeeDisplayInDollarWithSymbol () {
      return `${CURRENCY.USD.symbol} ${this.shippingFeeAmounts?.[CURRENCY.USD.value]?.display}`;
    },
    shippingFeeAirChargeInDollar () {
      return this.shippingFeeAmounts?.[CURRENCY.USD.value]?.charge;
    },
    shippingFeeOceanAmountInDollar () {
      const { shippingFeeOceanAmount } = this.order;
      return this.getAmountInCurrency({
        amountObj: shippingFeeOceanAmount,
        currency: CURRENCY.USD.value,
        withSymbol: false,
      });
    },
    shippingFeeAirAmountInDollarWithSymbol () {
      const { shippingFeeAirAmount } = this.order;
      return this.getAmountInCurrency({
        amountObj: shippingFeeAirAmount,
        currency: CURRENCY.USD.value,
      });
    },
    shippingFeeOceanAmountInDollarWithSymbol () {
      const { shippingFeeOceanAmount } = this.order;
      return this.getAmountInCurrency({
        amountObj: shippingFeeOceanAmount,
        currency: CURRENCY.USD.value,
      });
    },
    paidAmount () {
      return this.paidAmounts?.[this.preferredCurrency] || this.paidAmounts?.[CURRENCY.NGN.value];
    },
    paidDisplayWithSymbol () {
      return `${CURRENCY[this.preferredCurrency].symbol} ${this.paidAmount?.display}`;
    },

    paidDisplayInDollarWithSymbol () {
      return `${CURRENCY.USD.symbol} ${this.paidAmounts[CURRENCY.USD.value]?.display}`;
    },
    paidChargeInDollar () {
      return this.paidAmounts?.[CURRENCY.USD.value]?.charge;
    },
    getPaystackAmount () {
      return this.balanceChargeInNaira;
    },
    getStripeAmount () {
      return this.balanceAmount;
    },
    getPaypalAmount () {
      return this.balanceAmount?.display?.replaceAll(',', '');
    },
    splits () {
      return this?.order?.splits || [];
    },
    paidSplits () {
      return this.splits.filter(
        ({ status }) => status === this.ORDER_STATUS_PAID
      );
    },
    splitsTotalAmount () {
      const amountInUnits = this.splits.reduce((sum, split) => {
        const chargeAmount = split?.split?.charge || 0;
        return sum + chargeAmount;
      }, 0);
      return this.getDisplayTotal(amountInUnits);
    },
    paidSplitsAmount () {
      const amountInUnits = this.paidSplits.reduce((sum, split) => {
        const chargeAmount = split?.split?.charge || 0;
        return sum + chargeAmount;
      }, 0);
      return this.getDisplayTotal(amountInUnits);
    },
    splitPayProgress () {
      if (this.splits.length > 0) {
        return Math.round((this.paidSplits.length / this.splits.length) * 100);
      }
      return 0;
    },
    splitsTotalAmountInUnits () {
      return this.splits.reduce((sum, split) => {
        const chargeAmount = split?.split?.charge || 0;
        return sum + chargeAmount;
      }, 0);
    },
    splittableAmount () {
      const amountInUnits =
        this.balanceChargeInDollar - this.splitsTotalAmountInUnits;
      return this.getDisplayTotal(amountInUnits);
    },
    hasCustomLabel () {
      return this.orderShipments.some(
        shipment =>
          shipment.labelHeader &&
          shipment.labelHeader !== this.defaultLabelHeader
      );
    },
  },
  watch: {
    paymentChoice (newValue) {
      this.showPayNowButton = newValue !== this.PAYMENT_PROCESSOR_PAYPAL;
      if (newValue !== this.PAYMENT_PROCESSOR_PAYPAL) {
        this.$eventBus.$emit('hide-paypal-button');
      }
      if (newValue === this.PAYMENT_PROCESSOR_PAYPAL) {
        this.$eventBus.$emit('show-paypal-button');
      }
    },
    activeOrder (val) {
      this.order = val || this.order;
    }
  },
  created () {
    if (this.activeOrder) {
      this.order = this.activeOrder;
    }
  },
  methods: {
    ...mapActions({
      setActiveOrder: 'order/setActiveOrder',
    }),
    getOrderPaymentAmount (amountObj) {
      return this.getAmountInCurrency({
        amountObj,
        currency: amountObj?.currency,
        exchangeRate: this.orderExchangeRate,
      });
    },
    changeShipmentLimit (value) {
      this.limit = value;
    },
    setOrder (order) {
      this.order = { ...order } || {};
      this.setActiveOrder(order);
    },
    async fetchOrder (idOrReference) {
      if (!this.activeOrder) {
        this.fetchingOrder = true;
      }
      try {
        const { data } = await this.$api.ordering.orders.get({ idOrReference });
        this.setActiveOrder(data?.payload?.order);
        // NOTE: moved here to ensure that we fetch the exact number of shipments for the order
        this.fetchOrderShipments(idOrReference);
      } catch (error) {
        this.handleServerError(error);
        this.$router.push('/orders');
      } finally {
        this.fetchingOrder = false;
      }
    },
    async fetchOrderShipments (idOrReference) {
      const shipmentsCount = this.order?.shipments?.length;
      const query = { order_id: idOrReference, per_page: shipmentsCount };
      this.fetchingOrderShipments = true;
      try {
        const { data } = await this.$api.shipping.shipments.list({
          params: query,
        });
        this.orderShipments = data?.payload?.shipments || [];
        this.orderShipmentsPaginationData = data?.payload?.meta || {};
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.fetchingOrderShipments = false;
      }
    },
    async fetchOrderPayments (idOrReference) {
      const query = { order_id: idOrReference };
      this.fetchingOrderPayments = true;
      try {
        const { data } = await this.$api.payment.payments.list({
          params: query,
        });
        this.orderPayments = data?.payload?.payments || [];
        this.orderPaymentsPaginationData = data?.payload?.meta || {};
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.fetchingOrderPayments = false;
      }
    },
    async cancelOrder () {
      await this.clearNotification();
      this.cancelingOrder = true;

      try {
        const { data } = await this.$api.ordering.orders.cancel({
          idOrReference: this.$route.params.id,
        });
        setTimeout(() => {
          this.$segment.tracks.cancelShipmentOrder();
        }, 1000);
        await this.$router.replace('/shipments');
        await this.setActiveOrder(null);
        await this.setNotification({
          type: 'success',
          message: data.message || 'Order Cancelled',
          dismissible: true,
        });
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.cancelingOrder = false;
      }
    },
    onPaymentCancel () {
      this.setNotification({
        type: 'error',
        message: 'Payment canceled, No charges made.',
        dismissible: true,
      });
    },
    onPaymentError (error) {
      this.setNotification({
        type: 'error',
        message: error?.message || 'Payment unsuccessful. Please try again.',
        dismissible: true,
      });
    },
    openStripePaymentModal () {
      this.showStripePaymentModal = true;
    },
    closeStripePaymentModal () {
      this.showStripePaymentModal = false;
    },
    payNow () {
      if (this.voucherCode && this.$refs.voucherForm) {
        const voucherInput = this.$refs.voucherForm.elements[0];
        return voucherInput.focus();
      }

      // pay via selected paymentChoice
      if (this.paymentChoice === this.PAYMENT_PROCESSOR_PAYSTACK) {
        return this.$eventBus.$emit('click-paystack-button');
      }
      if (this.paymentChoice === this.PAYMENT_PROCESSOR_STRIPE) {
        return this.openStripePaymentModal();
      }
      if (this.paymentChoice === this.PAYMENT_PROCESSOR_WALLET) {
        return this.payViaWallet();
      }
    },
    payViaPaystack (response) {
      this.applyPayment({
        processor: this.PAYMENT_PROCESSOR_PAYSTACK,
        paymentRef: response.reference,
        currency: this.CURRENCY.NGN.value,
        amount: this.balanceAmount?.charge,
        paymentMeta: response?.paymentMeta,
      });
    },
    payViaStripe (response) {
      this.applyPayment({
        processor: this.PAYMENT_PROCESSOR_STRIPE,
        paymentRef: response.reference,
        currency: this.CURRENCY.USD.value,
        amount: this.balanceAmount?.charge,
        paymentMeta: response?.paymentMeta,
      });
    },
    payViaPaypal (response) {
      this.applyPayment({
        processor: this.PAYMENT_PROCESSOR_PAYPAL,
        paymentRef: response.reference,
        currency: this.CURRENCY.USD.value,
        amount: this.balanceChargeInDollar,
        paymentMeta: response?.paymentMeta,
      });
    },
    // TODO: move generateWalletReference into a wallet helper module / file
    generateWalletReference () {
      return `WLT_${generatePaymentReferencePart()}`;
    },
    async payViaWallet () {
      this.applyingPayment = true;

      const requestData = {
        currency: this.preferredCurrency,
        amount: this.balanceAmount?.charge,
        transaction_reference: this.generateWalletReference(),
        transaction_narration: `Payment for Order: ${this.order.reference}`,
      };

      try {
        const { data } = await this.$api.payment.wallet.withdraw({
          data: requestData,
        });
        this.applyPayment({
          processor: this.PAYMENT_PROCESSOR_WALLET,
          paymentRef: data?.payload?.transaction?.transactionReference,
          currency: this.preferredCurrency,
          amount: this.balanceAmount?.charge,
        });
      } catch (error) {
        this.handleServerError(error);
        this.applyingPayment = false;
      }
    },
    setPayingWith (paymentChoice) {
      this.payingWith = paymentChoice || '';
    },
    async applyPayment ({
      channel = 'WEB',
      processor,
      paymentRef,
      currency,
      amount,
      paymentMeta,
    }) {
      this.clearNotification();
      this.applyingPayment = true;
      this.setPayingWith(this.paymentChoice);
      const requestData = {
        channel,
        processor,
        payment_reference: paymentRef,
        currency,
        amount,
        payment_meta: paymentMeta,
      };

      try {
        const { data } = await this.$api.ordering.orders.applyPayment({
          idOrReference: this.idOrReference,
          data: requestData,
        });
        const order = data?.payload?.order || {};

        this.$segment.tracks.shipmentOrderPaid({
          payment_method: processor,
          total_shipments: (order.shipments && order.shipments.length) || 0,
          currency,
          revenue: amount,
          coupon: order?.voucherCode,
          shipment_fee: order?.shippingFeeAmount?.charge,
          custom_label: this.hasCustomLabel,
        });
        if (this.showStorageFeeAmount) {
          this.$segment.tracks.shipmentOrderStorageFees({
            payment_method: processor,
            amount,
            currency,
          });
        }

        setTimeout(() => {
          this.order = order;
          this.setActiveOrder(this.order);
        }, 1500);

        this.setNotification({
          type: 'success',
          message: data.message || 'Payment Successful',
          dismissible: true,
        });
        this.fbq.track.orderPayment({
          value: Number(amount || 0) / 100,
          currency,
        });
      } catch (error) {
        this.handleServerError(error);
      } finally {
        this.applyingPayment = false;
        this.setPayingWith();
      }
    },
    getAddressShipments (address) {
      return this.orderShipments.filter((o) => {
        if (!o.deliveryAddress) {
          return false;
        }
        return JSON.stringify(o.deliveryAddress) === JSON.stringify(address);
      });
    },
    canChangeDeliveryChoice (uniqueAddress) {
      if (!uniqueAddress) {
        return false;
      }
      const { deliveryAddress } = uniqueAddress;
      const addressShipments = this.getAddressShipments(deliveryAddress);
      const changeableStatuses = [
        this.SHIPMENT_STATUS_PROCESSED,
        this.SHIPMENT_STATUS_PACKED,
        this.SHIPMENT_STATUS_DROPPED_OFF,
        this.SHIPMENT_STATUS_SHIPPED,
        this.SHIPMENT_STATUS_PICKUP,
      ];
      const changeableStatus = addressShipments.every((shipment) => {
        const { status, dispatchWaybill } = shipment;
        const canChangeSorted =
          status === this.SHIPMENT_STATUS_SORTED && !dispatchWaybill;
        return changeableStatuses.includes(status) || canChangeSorted;
      });
      const shipmentsMoreThanZero = addressShipments.length > 0;

      const orderIsPaid = this.order.status === this.ORDER_STATUS_PAID;
      return orderIsPaid && changeableStatus && shipmentsMoreThanZero;
    },
  },
};

export default orderMixin;
