export const state = () => ({
  notification: null,
});

export const mutations = {
  setNotification (state, notification) {
    const { type, message, dismissible } = notification;
    state.notification = {
      type,
      message,
      dismissible,
    };
  },
  unSetNotification (state) {
    state.notification = null;
  },
};

export const getters = {
  notification: (state) => {
    return state.notification;
  },
};

export const actions = {
  setNotification ({ commit }, notification) {
    commit('setNotification', notification);

    setTimeout(() => {
      commit('unSetNotification');
    }, 10000);
  },
  clearNotification ({ commit }) {
    commit('unSetNotification');
  },
};
