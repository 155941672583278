import Vue from 'vue';

import paystack from 'vue-paystack';
import VueSplide from '@splidejs/vue-splide';
import GAuth from 'vue-google-oauth2';
import { LMap, LTileLayer, LMarker, LPolyline, LPopup } from 'vue2-leaflet';

// import splidejs blue theme style
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

// import vue-phone-number-input lib css
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// import leaflet css
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.component('Paystack', paystack);
Vue.use(VueSplide);
const gauthOption = {
  clientId: '535946939421-20ikdcn9v8r5148apr1t0qkfcn5mgdgb.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
};
Vue.use(GAuth, gauthOption);
Vue.component('LMap', LMap);
Vue.component('LTileLayer', LTileLayer);
Vue.component('LMarker', LMarker);
Vue.component('LPolyline', LPolyline);
Vue.component('LPopup', LPopup);
