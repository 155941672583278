
import { mapGetters, mapActions } from 'vuex';
import Alert from '~/components/ui/Alert.vue';
import Toast from '~/components/ui/Toast.vue';
import ToastContainer from '~/components/ui/ToastContainer.vue';
import { pageMixin } from '~/mixins/index.js';

export default {
  components: {
    Alert,
    Toast,
    ToastContainer,
  },
  mixins: [pageMixin],
  middleware: ['auth', 'fetch-common-data'],
  data () {
    return {
      WebUrl: this.$config.WebUrl,
      showPage: false,
    };
  },
  computed: {
    ...mapGetters({
      notification: 'notification/notification',
    }),
  },
  created () {
    this.fetchConfigEntries();
    const shipmentStatusesInStoreIsEmpty = this.shipmentStatusesInStore.length === 0;
    if (shipmentStatusesInStoreIsEmpty) {
      this.fetchShipmentStatuses();
    }
    const pickupAddressesInStoreIsEmpty = this.pickupAddresses.length === 0;
    if (pickupAddressesInStoreIsEmpty) {
      this.fetchPickupAddresses();
    }
    this.fetchUserProfile();
  },
  async mounted () {
    await this.$ldClient.waitForInitialization();

    // delay page render to avoid flickering
    setTimeout(() => {
      this.showPage = true;
    }, 1);

    this.restoreCartFromStorage();
    this.$eventBus.$on('remove-shipments-from-cart', (shipments = []) => {
      return this.removeShipmentsFromCart(shipments);
    });
    this.$eventBus.$on('add-shipments-to-cart', (shipments = []) => {
      return this.addShipmentsToCart(shipments);
    });
  },
  beforeDestroy () {
    this.$eventBus.$off('remove-shipments-from-cart');
    this.$eventBus.$off('add-shipments-to-cart');
  },
  methods: {
    ...mapActions({
      clearNotification: 'notification/clearNotification',
      addShipmentsToCart: 'cart/addShipmentsToCart',
      removeShipmentsFromCart: 'cart/removeShipmentsFromCart',
      restoreCartFromStorage: 'cart/restoreCartFromStorage',
      fetchShipmentStatuses: 'shipment/fetchShipmentStatuses',
      fetchConfigEntries: 'config/fetchConfigEntries',
      fetchPickupAddresses: 'addressbook/fetchPickupAddresses',
      fetchUserProfile: 'auth/fetchUserProfile',
    }),
  },
};
