export const state = () => ({
  openHowItWorksMobileList: false,
  shippingAddressChangedModal: false,
  marketingEventPopUpModal: true,
  marketingPopUpModal: true,
  shippingAddressChinaModal: false,
  oceanShippingCountDownModal: true,
  moreShippingDaysModal: false,
  subscriptionDeal: false,
  blackFriday: false,
  heroTourReturningUser: false,
  heroTour: {
    address: false,
    payment: false,
    shipment: false,
    pickup: false,
  },
});

export const mutations = {
  setOpenHowItWorksMobileList (state, trueOrFalse) {
    state.openHowItWorksMobileList = trueOrFalse;
    this.$cookies.set('openHowItWorksMobileList', trueOrFalse);
  },
  setPromotion (state, { promotion, cache = true }) {
    const { key, value } = promotion;
    state[key] = value;
    if (cache) {
      this.$cookies.set('promotions', { ...state, [key]: value });
    }
  },
  setPromotions (state, promotions) {
    Object.entries(promotions).forEach(([key, value]) => {
      state[key] = value;
    });
  },
};

export const getters = {
  openHowItWorksMobileList: (state) => {
    return state.openHowItWorksMobileList;
  },
  shippingAddressChangedModal: (state) => {
    return state.shippingAddressChangedModal;
  },
  shippingAddressChinaModal: (state) => {
    return state.shippingAddressChinaModal;
  },
  oceanShippingCountDownModal: (state) => {
    return state.oceanShippingCountDownModal;
  },
  marketingEventPopUpModal: (state) => {
    return state.marketingEventPopUpModal;
  },
  marketingPopUpModal: (state) => {
    return state.marketingPopUpModal;
  },
  moreShippingDaysModal: (state) => {
    return state.moreShippingDaysModal;
  },
  subscriptionDeal: (state) => {
    return state.subscriptionDeal;
  },
  blackFriday: (state) => {
    return state.blackFriday;
  },
  heroTour: (state) => {
    return state.heroTour;
  },
  heroTourReturningUser: (state) => {
    return state.heroTourReturningUser;
  }
};

export const actions = {
  /**
   * Toggle How It Works Modal
   * @param commit
   * @param state
   */
  toggleHowItWorksMobileList ({ commit, state }) {
    commit('setOpenHowItWorksMobileList', !state.openHowItWorksMobileList);
  },
  /**
   * Update All Promotions
   * @param commit
   * @param promotions
   */
  setPromotions ({ commit }, promotions) {
    commit('setPromotions', promotions);
  },
  /**
   * Update Single Promotion
   * @param commit
   * @param promotion
   */
  setPromotion ({ commit }, promotion) {
    commit('setPromotion', promotion);
  },
};
