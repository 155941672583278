
import { mapActions, mapGetters } from 'vuex';
import Drawer from '~/components/ui/Drawer.vue';
import Loader from '~/components/ui/Loader.vue';
import Notification from '~/components/notifications/Notification.vue';
import EmptyState from '~/components/ui/EmptyState.vue';

export default {
  name: 'NotificationDrawer',
  components: {
    EmptyState,
    Drawer,
    Loader,
    Notification,
  },
  mixins: [],
  data () {
    return {
      emptyState: {
        message: 'You do not have any notifications for now. New ones will appear here.',
        icon: '/svg/notifications/no-email.svg',
      },
      fetchingNotifications: false,
      broadcasts: [],
      defaultImagePath: '/svg/notifications',
      iconList: {
        ANNOUNCEMENTS: {
          DEFAULT: 'icon-broadcast',
        },
        SHIPPING: {
          DEFAULT: 'icon-megaphone',
        },
        STORAGE: {
          DEFAULT: 'icon-megaphone',
        },
        DELIVERY: {
          DEFAULT: 'icon-timer',
        },
        PICKUP: {
          DEFAULT: 'icon-timer',
        },
        WALLET: {
          DEFAULT: 'icon-wallet',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      recentNotifications: 'broadcast/recentNotifications',
      fetchingRecentNotifications: 'broadcast/fetchingRecentNotifications',
    }),
  },
  created () {
    this.fetchRecentNotifications({ silentLoading: true });
  },
  methods: {
    ...mapActions({ fetchRecentNotifications: 'broadcast/fetchRecentNotifications' }),
    closeNotificationDrawer () {
      this.$emit('close');
    },
  },
};
