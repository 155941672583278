export const state = () => ({
  walletBalance: {},
  walletBalanceIsLoading: false,
});

export const mutations = {
  setWalletBalance (state, balance) {
    state.walletBalance = balance;
  },
  setWalletBalanceIsLoading (state, isLoading) {
    state.walletBalanceIsLoading = isLoading;
  },
};

export const getters = {
  walletBalance: (state) => {
    return state.walletBalance;
  },
  walletBalanceIsLoading: (state) => {
    return state.walletBalanceIsLoading;
  },
};

export const actions = {
  async fetchWalletBalance ({ commit }, currencyCode) {
    commit('setWalletBalanceIsLoading', true);
    try {
      const { data } = await this.$api.payment.wallet.getBalance({
        currencyCode,
      });
      commit('setWalletBalance', data?.payload?.balance || {});
    } catch (error) {
      if (error) {
        const { data = {}, status } = error;
        const { msg } = data;
        if (msg && status && this._vm) {
          this._vm.handleStoreServerError({ msg, status }, this);
        }
      }
    } finally {
      commit('setWalletBalanceIsLoading', false);
    }
  },
};
