export const state = () => ({
  activeOrder: null,
  dryRunOrder: null,
});

export const mutations = {
  setActiveOrder (state, order) {
    state.activeOrder = order || null;
  },
  setDryRunOrder (state, order) {
    state.dryRunOrder = order || null;
  }
};

export const getters = {
  activeOrder: (state) => {
    return state.activeOrder;
  },
  dryRunOrder: (state) => {
    return state.dryRunOrder;
  }
};

export const actions = {
  setActiveOrder ({ commit }, order) {
    commit('setActiveOrder', order);
  },
  setDryRunOrder ({ commit }, order) {
    commit('setDryRunOrder', order);
  }
};
