const getShippingServiceBasePath = version => `/shipping/${version}`; // This is a gradual migration from ordering to shipping service.

export default ($axios, $apiVersion = 'v1') => ({
  orders: {
    list: ({ headers, params }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/orders`;
      return $axios.get(urlPath, { headers, params });
    },
    get: ({ idOrReference, headers }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/orders/${idOrReference}`;
      return $axios.get(urlPath, { headers });
    },
    checkout: ({ headers, data }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/orders/checkout`;
      return $axios.post(urlPath, data, { headers });
    },
    applyPayment: ({ idOrReference, headers, data }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/orders/${idOrReference}/payments/apply`;
      return $axios.post(urlPath, data, { headers });
    },
    cancel: ({ idOrReference, headers }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/orders/${idOrReference}`;
      return $axios.delete(urlPath, { headers });
    },
    addSplitContact: ({ idOrReference, data, headers }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/orders/${idOrReference}/splits`;
      return $axios.put(urlPath, data, { headers });
    },
    removeSplitContact: ({ idOrReference, splitReference, headers }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/orders/${idOrReference}/splits/${splitReference}`;
      return $axios.delete(urlPath, { headers });
    },
    getSplitContact: ({ idOrReference, splitReference, headers }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/public/orders/${idOrReference}/splits/${splitReference}`;
      return $axios.get(urlPath, { headers });
    },
    applySplitPayment: ({ idOrReference, headers, data }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/public/orders/${idOrReference}/payments/apply`;
      return $axios.post(urlPath, data, { headers });
    },
    estimate: ({ headers, data }) => {
      const urlPath = `${getShippingServiceBasePath($apiVersion)}/public/orders/estimate`;
      return $axios.post(urlPath, data, { headers });
    },
  },
});
